import CasesList from "components/cases/CasesList";
import CTA from "components/home/CTA";
import Features from "components/home/Features";
import Header from "components/home/Header";
import Incentives from "components/home/Incentives";
import LogoCloud from "components/home/LogoCloud";
import SliderPeacemaker from "components/home/SliderPeacemaker";
import UseCases from "components/home/UseCases";
import Footer from "components/navigation/Footer";
import Meta from "components/navigation/Meta";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";

function Home() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Meta title="Inicio" />
			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div data-scroll-section className="pt-20">
					<Header />
					<Incentives />
					<SliderPeacemaker />
					<UseCases />
					<LogoCloud />
					<CTA />
					<Features />



				</div>
				<Footer />
			</div>
		</Layout>
	);
}
export default Home;
