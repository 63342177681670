import { CheckIcon } from "@heroicons/react/24/outline";

const features = [
	{ name: "Pacifismo", description: "Como movimiento social, ejercicio de activismo y actitud individual y colectiva. Trabajar por la construcción de modos de vida pacíficos basados en la armonía y en la resolución de cualquier conflicto o desacuerdo a partir del consenso y la no violencia." },
	{ name: "Solidaridad", description: "Ejercicio voluntario, altruista, horizontal y de transformación social. Es un valor que se materializa cuando una persona o grupo deja de lado sus prejuicios e intereses propios para acompañar al otro en la búsqueda del interés común." },
	{ name: "Respeto", description: "Reconocimiento, valoración y aceptación del otro, permite que la sociedad viva en paz. Implica reconocer en sí y en los demás, los valores y las necesidades, los derechos y las obligaciones." },
	{ name: "Independencia", description: "Salvaguarda de fidelidad a nuestra identidad que nos compromete y vincula con la protección y promoción de los derechos humanos." },
	{ name: "Imparcialidad", description: "Implica que el acompañamiento a las personas y grupos debe realizarse en función de sus necesidades, sin discriminación por raza, sexo, ideología o cualquier otro motivo." },
	{ name: "Transparencia", description: "Consiste en comunicar y rendir cuentas de forma proactiva a los diferentes grupos de interés sobre lo qué se hace, como se hace y los resultados obtenidos." },
	{ name: "Cultura", description: "Promoción y difusión de las expresiones artísticas, culturales y populares que contribuyan a la sensibilización, la educación y la movilización social por la paz y la vida." },
	{ name: "Deporte", description: "Fomento y apoyo de las actividades deportivas, recreativas y lúdicas que propicien la salud, el bienestar y la convivencia pacífica." },
	{ name: "Educación", description: "Desarrollo y fortalecimiento de las capacidades, habilidades y conocimientos de las personas y las comunidades para la prevención y la transformación de los conflictos." },
	{ name: "Social", description: "Atención y acompañamiento a las personas y los grupos en situación de vulnerabilidad, exclusión o riesgo social." },
	{ name: "Religiosa", description: "Respeto y valoración de la diversidad religiosa y espiritual, así como el diálogo interreligioso e intercultural como medios para la construcción de la paz y la vida." },
	{ name: "Productiva", description: "Estimulación y asesoramiento de las iniciativas populares, cooperativas y comunitarias que busquen generar ingresos, empleo y desarrollo local." },
	{ name: "Revolucionario", description: "Compromiso con el legado histórico y político del Comandante Hugo Chávez, el pensamiento bolivariano y la construcción del socialismo del siglo XXI." },
	{ name: "Bolivariano", description: "Identificación con los ideales y valores de Simón Bolívar, el Libertador, y su proyecto de integración y emancipación de los pueblos de nuestra América." },
	{ name: "Cristiano", description: "Inspiración en el mensaje y la praxis de Jesús de Nazaret, el Cristo, y su opción preferencial por los pobres, los oprimidos y los marginados." },
	{ name: "Humanista", description: "Concepción del ser humano como centro y fin de la acción social, y defensa de su dignidad, libertad y derechos fundamentales." },
	{ name: "Democrático", description: "Promoción y práctica de la democracia participativa, protagónica y popular, como forma de organización y expresión de la voluntad colectiva." },
	{ name: "Pluralista", description: "Reconocimiento y valoración de la diversidad y la pluralidad de opiniones, ideas y propuestas, dentro de un marco de respeto y tolerancia." },
	{ name: "Cooperativo", description: "Fomento y apoyo de la cooperación, la colaboración y la complementariedad entre las personas, los grupos y las organizaciones." },
	{ name: "Creativo", description: "Estimulación y aprovechamiento de la creatividad, la innovación y la imaginación como recursos para la solución de problemas." },
	{ name: "Ecológico", description: "Protección y cuidado del medio ambiente, la biodiversidad y los recursos naturales, como parte de la vida y el patrimonio de la humanidad." },
	{ name: "Patriótico", description: "Amor y lealtad a la patria, a su historia, a su cultura y a su pueblo, y defensa de su soberanía, su integridad y su autodeterminación." },
	{ name: "Antiimperialista", description: "Rechazo y resistencia al imperialismo, al colonialismo y a cualquier forma de dominación, injerencia o agresión externa." },
	{ name: "Internacionalista", description: "Solidaridad y apoyo a las causas justas de los pueblos del mundo, especialmente los que luchan por su liberación, su desarrollo y su paz." },
	{ name: "Socialista", description: "Aspiración y construcción de un modelo socioeconómico basado en la igualdad, la justicia y la inclusión social, que supere el capitalismo." },
	{ name: "Comunitario", description: "Participación y protagonismo de las comunidades organizadas en la definición y ejecución de las políticas públicas." },
	{ name: "Popular", description: "Vinculación y articulación con los sectores populares, campesinos, indígenas, afrodescendientes, obreros, estudiantes, mujeres, jóvenes, niños y niñas." },
	{ name: "Ético", description: "Actuación conforme a los principios y valores que orientan el movimiento por la paz y la vida." },
	{ name: "Eficiente", description: "Optimización de los recursos humanos, materiales, financieros y técnicos disponibles, para lograr los objetivos propuestos." },
	{ name: "Eficaz", description: "Cumplimiento de los objetivos y metas propuestos, de acuerdo con los criterios de pertinencia, relevancia y utilidad." },
	{ name: "Efectivo", description: "Generación de impactos positivos y sostenibles en la realidad social, a partir de los objetivos y metas propuestos." },
	{ name: "Evaluativo", description: "Aplicación de mecanismos de seguimiento, control y retroalimentación de las acciones y los resultados." },
	{ name: "Comunicativo", description: "Difusión y divulgación de las acciones y los resultados, para generar visibilidad, reconocimiento y apoyo al movimiento." }
];


export default function FeaturesLine({}) {
	return (
    <div className="bg-gray-900">
        <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
            <div className="lg:col-span-3">
                <h2 className="text-xl font-semibold text-yellow-button">
                    CÓDIGO DE ÉTICA DEL MOVIMIENTO POR LA PAZ Y LA VIDA
                </h2>
                <p className="mt-2 text-base font-bold tracking-tight text-gray-200">
                    El código de ética del Movimiento por La Paz y La Vida, sirve para
                    definir la identidad, la coherencia, la legitimidad y la
                    responsabilidad del movimiento, así como para regular las relaciones
                    internas y externas de sus integrantes. Nuestro código de ética se
                    basa en los siguientes principios, valores y normas
                </p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-3"> {/* Contenedor adicional con margen */}
                <dl className="grid grid-cols-3 gap-x-6 gap-y-10">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                            <dt>
                                <CheckIcon
                                    className="absolute h-6 w-6 text-green-500"
                                    aria-hidden="true"
                                />
                                <p className="ml-9 text-lg font-medium leading-6 text-gray-200">
                                    {feature.name}
                                </p>
                            </dt>
                            <dd className="mt-2 ml-9 text-base text-gray-200">
                                {feature.description}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>
);

}
