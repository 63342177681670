import { Typewriter } from "react-simple-typewriter";
import hero_bg from "../../assets/img/hero-bg.png";

function ProfileNotFound() {
	return (
		<main>
			<div
				className="relative px-6 lg:px-8 bg-hero-pattern bg-contain bg-no-repeat bg-center"
				id="seccion-1"
			>
				<div className="mx-auto max-w-full xl:mx-12 xl:pt-10 lg:pt-10 lg:pb-48 pb-10">
					<div className="block lg:flex lg:items-center lg:justify-center">
						<div className="transform-gpu flex justify-center order-1 lg:order-2">
							<img
								src={hero_bg}
								alt="hero background"
								className="w-10/12 md:w-3/12 lg:w-8/12"
							/>
						</div>
						<div className="order-2 lg:order-1">
							<h1 className="text-lg text-gray-200 font-semibold tracking-tight pb-3  sm:text-4xl">
								Usuario no encontrado por favor verifica el numero de documento que ingresaste.
							</h1>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default ProfileNotFound;
