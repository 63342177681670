const municipios = [
    {
      "id_municipio": 1,
      "id_estado": 1,
      "municipio": "MP. BOLIVARIANO LIBERTADOR"
    },
    {
      "id_municipio": 2,
      "id_estado": 2,
      "municipio": "MP. ATURES"
    },
    {
      "id_municipio": 3,
      "id_estado": 2,
      "municipio": "MP. ATABAPO"
    },
    {
      "id_municipio": 4,
      "id_estado": 2,
      "municipio": "MP. MANAPIARE"
    },
    {
      "id_municipio": 5,
      "id_estado": 2,
      "municipio": "MP. RIO NEGRO"
    },
    {
      "id_municipio": 6,
      "id_estado": 2,
      "municipio": "MP. MAROA"
    },
    {
      "id_municipio": 7,
      "id_estado": 2,
      "municipio": "MP. AUTANA"
    },
    {
      "id_municipio": 8,
      "id_estado": 3,
      "municipio": "MP. BOLIVAR"
    },
    {
      "id_municipio": 9,
      "id_estado": 3,
      "municipio": "MP. L DIEGO BAUTISTA"
    },
    {
      "id_municipio": 10,
      "id_estado": 3,
      "municipio": "MP. SIMON RODRIGUEZ"
    },
    {
      "id_municipio": 11,
      "id_estado": 3,
      "municipio": "MP. SOTILLO"
    },
    {
      "id_municipio": 12,
      "id_estado": 3,
      "municipio": "MP. PIRITU"
    },
    {
      "id_municipio": 13,
      "id_estado": 3,
      "municipio": "MP. ANACO"
    },
    {
      "id_municipio": 14,
      "id_estado": 3,
      "municipio": "MP. FREITES"
    },
    {
      "id_municipio": 15,
      "id_estado": 3,
      "municipio": "MP. MIRANDA"
    },
    {
      "id_municipio": 16,
      "id_estado": 3,
      "municipio": "MP. BRUZUAL"
    },
    {
      "id_municipio": 17,
      "id_estado": 3,
      "municipio": "MP. GUANIPA"
    },
    {
      "id_municipio": 18,
      "id_estado": 3,
      "municipio": "MP. S JUAN CAPISTRANO"
    },
    {
      "id_municipio": 19,
      "id_estado": 3,
      "municipio": "MP. INDEPENDENCIA"
    },
    {
      "id_municipio": 20,
      "id_estado": 3,
      "municipio": "MP. LIBERTAD"
    },
    {
      "id_municipio": 21,
      "id_estado": 3,
      "municipio": "MP. PE\u00d1ALVER"
    },
    {
      "id_municipio": 22,
      "id_estado": 3,
      "municipio": "MP. CARVAJAL"
    },
    {
      "id_municipio": 23,
      "id_estado": 3,
      "municipio": "MP. GUANTA"
    },
    {
      "id_municipio": 24,
      "id_estado": 3,
      "municipio": "MP. MONAGAS"
    },
    {
      "id_municipio": 25,
      "id_estado": 3,
      "municipio": "MP. SANTA ANA"
    },
    {
      "id_municipio": 26,
      "id_estado": 3,
      "municipio": "MP. MC GREGOR"
    },
    {
      "id_municipio": 27,
      "id_estado": 3,
      "municipio": "MP. ARAGUA"
    },
    {
      "id_municipio": 28,
      "id_estado": 3,
      "municipio": "MP. CAJIGAL"
    },
    {
      "id_municipio": 29,
      "id_estado": 4,
      "municipio": "MP. SAN FERNANDO"
    },
    {
      "id_municipio": 30,
      "id_estado": 4,
      "municipio": "MP. ROMULO GALLEGOS"
    },
    {
      "id_municipio": 31,
      "id_estado": 4,
      "municipio": "MP. PAEZ"
    },
    {
      "id_municipio": 32,
      "id_estado": 4,
      "municipio": "MP. ACHAGUAS"
    },
    {
      "id_municipio": 33,
      "id_estado": 4,
      "municipio": "MP. MU\u00d1OZ"
    },
    {
      "id_municipio": 34,
      "id_estado": 4,
      "municipio": "MP. PEDRO CAMEJO"
    },
    {
      "id_municipio": 35,
      "id_estado": 4,
      "municipio": "MP. BIRUACA"
    },
    {
      "id_municipio": 36,
      "id_estado": 5,
      "municipio": "MP. GIRARDOT"
    },
    {
      "id_municipio": 37,
      "id_estado": 5,
      "municipio": "MP. MARIO B IRAGORRY"
    },
    {
      "id_municipio": 38,
      "id_estado": 5,
      "municipio": "MP. SANTIAGO MARI\u00d1O"
    },
    {
      "id_municipio": 39,
      "id_estado": 5,
      "municipio": "MP. FRANCISCO LINARES"
    },
    {
      "id_municipio": 40,
      "id_estado": 5,
      "municipio": "MP. JOSE FELIX RIBAS"
    },
    {
      "id_municipio": 41,
      "id_estado": 5,
      "municipio": "MP. LIBERTADOR"
    },
    {
      "id_municipio": 42,
      "id_estado": 5,
      "municipio": "MP. SUCRE"
    },
    {
      "id_municipio": 43,
      "id_estado": 5,
      "municipio": "MP. CAMATAGUA"
    },
    {
      "id_municipio": 44,
      "id_estado": 5,
      "municipio": "MP. JOSE R REVENGA"
    },
    {
      "id_municipio": 45,
      "id_estado": 5,
      "municipio": "MP. ZAMORA"
    },
    {
      "id_municipio": 46,
      "id_estado": 5,
      "municipio": "MP. SANTOS MICHELENA"
    },
    {
      "id_municipio": 47,
      "id_estado": 5,
      "municipio": "MP. JOSE ANGEL LAMAS"
    },
    {
      "id_municipio": 48,
      "id_estado": 5,
      "municipio": "MP. SAN SEBASTIAN"
    },
    {
      "id_municipio": 49,
      "id_estado": 5,
      "municipio": "MP. SAN CASIMIRO"
    },
    {
      "id_municipio": 50,
      "id_estado": 5,
      "municipio": "MP. OCUMARE D L COSTA"
    },
    {
      "id_municipio": 51,
      "id_estado": 5,
      "municipio": "MP. URDANETA"
    },
    {
      "id_municipio": 52,
      "id_estado": 5,
      "municipio": "MP. TOVAR"
    },
    {
      "id_municipio": 53,
      "id_estado": 6,
      "municipio": "MP. A JOSE DE SUCRE"
    },
    {
      "id_municipio": 54,
      "id_estado": 6,
      "municipio": "MP. BARINAS"
    },
    {
      "id_municipio": 55,
      "id_estado": 6,
      "municipio": "MP. EZEQUIEL ZAMORA"
    },
    {
      "id_municipio": 56,
      "id_estado": 6,
      "municipio": "MP. OBISPOS"
    },
    {
      "id_municipio": 57,
      "id_estado": 6,
      "municipio": "MP. PEDRAZA"
    },
    {
      "id_municipio": 58,
      "id_estado": 6,
      "municipio": "MP. ROJAS"
    },
    {
      "id_municipio": 59,
      "id_estado": 6,
      "municipio": "MP. ANDRES E. BLANCO"
    },
    {
      "id_municipio": 60,
      "id_estado": 6,
      "municipio": "MP. CRUZ PAREDES"
    },
    {
      "id_municipio": 61,
      "id_estado": 6,
      "municipio": "MP. ALBERTO ARVELO T"
    },
    {
      "id_municipio": 62,
      "id_estado": 6,
      "municipio": "MP. SOSA"
    },
    {
      "id_municipio": 63,
      "id_estado": 6,
      "municipio": "MP. ARISMENDI"
    },
    {
      "id_municipio": 64,
      "id_estado": 7,
      "municipio": "MP. CARONI"
    },
    {
      "id_municipio": 65,
      "id_estado": 7,
      "municipio": "MP. SIFONTES"
    },
    {
      "id_municipio": 66,
      "id_estado": 7,
      "municipio": "MP. EL CALLAO"
    },
    {
      "id_municipio": 67,
      "id_estado": 7,
      "municipio": "MP. GRAN SABANA"
    },
    {
      "id_municipio": 68,
      "id_estado": 7,
      "municipio": "MP. PIAR"
    },
    {
      "id_municipio": 69,
      "id_estado": 7,
      "municipio": "MP. ANGOSTURA DEL ORINOCO"
    },
    {
      "id_municipio": 70,
      "id_estado": 7,
      "municipio": "MP. BLVNO DE ANGOSTURA"
    },
    {
      "id_municipio": 71,
      "id_estado": 7,
      "municipio": "MP. CEDE\u00d1O"
    },
    {
      "id_municipio": 72,
      "id_estado": 7,
      "municipio": "MP. PADRE PEDRO CHIEN"
    },
    {
      "id_municipio": 73,
      "id_estado": 7,
      "municipio": "MP. ROSCIO"
    },
    {
      "id_municipio": 74,
      "id_estado": 8,
      "municipio": "MP. SAN DIEGO"
    },
    {
      "id_municipio": 75,
      "id_estado": 8,
      "municipio": "MP. NAGUANAGUA"
    },
    {
      "id_municipio": 76,
      "id_estado": 8,
      "municipio": "MP. VALENCIA"
    },
    {
      "id_municipio": 77,
      "id_estado": 8,
      "municipio": "MP. GUACARA"
    },
    {
      "id_municipio": 78,
      "id_estado": 8,
      "municipio": "MP. LOS GUAYOS"
    },
    {
      "id_municipio": 79,
      "id_estado": 8,
      "municipio": "MP. SAN JOAQUIN"
    },
    {
      "id_municipio": 80,
      "id_estado": 8,
      "municipio": "MP. BEJUMA"
    },
    {
      "id_municipio": 81,
      "id_estado": 8,
      "municipio": "MP. CARLOS ARVELO"
    },
    {
      "id_municipio": 82,
      "id_estado": 8,
      "municipio": "MP. DIEGO IBARRA"
    },
    {
      "id_municipio": 83,
      "id_estado": 8,
      "municipio": "MP. PUERTO CABELLO"
    },
    {
      "id_municipio": 84,
      "id_estado": 8,
      "municipio": "MP. MONTALBAN"
    },
    {
      "id_municipio": 85,
      "id_estado": 8,
      "municipio": "MP. JUAN JOSE MORA"
    },
    {
      "id_municipio": 86,
      "id_estado": 9,
      "municipio": "MP. TINACO"
    },
    {
      "id_municipio": 87,
      "id_estado": 9,
      "municipio": "MP. AUTONOMO TINAQUILLO"
    },
    {
      "id_municipio": 88,
      "id_estado": 9,
      "municipio": "MP. ANZOATEGUI"
    },
    {
      "id_municipio": 89,
      "id_estado": 9,
      "municipio": "MP. RICAURTE"
    },
    {
      "id_municipio": 90,
      "id_estado": 9,
      "municipio": "MP. PAO S J BAUTISTA"
    },
    {
      "id_municipio": 91,
      "id_estado": 9,
      "municipio": "MP. LIMA BLANCO"
    },
    {
      "id_municipio": 92,
      "id_estado": 10,
      "municipio": "MP. ANTONIO DIAZ"
    },
    {
      "id_municipio": 93,
      "id_estado": 10,
      "municipio": "MP. CASACOIMA"
    },
    {
      "id_municipio": 94,
      "id_estado": 10,
      "municipio": "MP. TUCUPITA"
    },
    {
      "id_municipio": 95,
      "id_estado": 10,
      "municipio": "MP. PEDERNALES"
    },
    {
      "id_municipio": 96,
      "id_estado": 11,
      "municipio": "MP. CARIRUBANA"
    },
    {
      "id_municipio": 97,
      "id_estado": 11,
      "municipio": "MP. LOS TAQUES"
    },
    {
      "id_municipio": 98,
      "id_estado": 11,
      "municipio": "MP. COLINA"
    },
    {
      "id_municipio": 99,
      "id_estado": 11,
      "municipio": "MP. FALCON"
    },
    {
      "id_municipio": 100,
      "id_estado": 11,
      "municipio": "MP. DABAJURO"
    },
    {
      "id_municipio": 101,
      "id_estado": 11,
      "municipio": "MP. FEDERACION"
    },
    {
      "id_municipio": 102,
      "id_estado": 11,
      "municipio": "MP. BUCHIVACOA"
    },
    {
      "id_municipio": 103,
      "id_estado": 11,
      "municipio": "MP. SILVA"
    },
    {
      "id_municipio": 104,
      "id_estado": 11,
      "municipio": "MP. MAUROA"
    },
    {
      "id_municipio": 105,
      "id_estado": 11,
      "municipio": "MP. PETIT"
    },
    {
      "id_municipio": 106,
      "id_estado": 11,
      "municipio": "MP. CACIQUE MANAURE"
    },
    {
      "id_municipio": 107,
      "id_estado": 11,
      "municipio": "MP. ACOSTA"
    },
    {
      "id_municipio": 108,
      "id_estado": 11,
      "municipio": "MP. URUMACO"
    },
    {
      "id_municipio": 109,
      "id_estado": 11,
      "municipio": "MP. MONS. ITURRIZA"
    },
    {
      "id_municipio": 110,
      "id_estado": 11,
      "municipio": "MP. DEMOCRACIA"
    },
    {
      "id_municipio": 111,
      "id_estado": 11,
      "municipio": "MP. TOCOPERO"
    },
    {
      "id_municipio": 112,
      "id_estado": 11,
      "municipio": "MP. SAN FRANCISCO"
    },
    {
      "id_municipio": 113,
      "id_estado": 11,
      "municipio": "MP. UNION"
    },
    {
      "id_municipio": 114,
      "id_estado": 11,
      "municipio": "MP. JACURA"
    },
    {
      "id_municipio": 115,
      "id_estado": 11,
      "municipio": "MP. PALMASOLA"
    },
    {
      "id_municipio": 116,
      "id_estado": 12,
      "municipio": "MP. INFANTE"
    },
    {
      "id_municipio": 117,
      "id_estado": 12,
      "municipio": "MP. SAN GERONIMO DE G"
    },
    {
      "id_municipio": 118,
      "id_estado": 12,
      "municipio": "MP. ZARAZA"
    },
    {
      "id_municipio": 119,
      "id_estado": 12,
      "municipio": "MP. JUAN GERMAN ROSCIO N."
    },
    {
      "id_municipio": 120,
      "id_estado": 12,
      "municipio": "MP. JUAN JOSE RONDON"
    },
    {
      "id_municipio": 121,
      "id_estado": 12,
      "municipio": "MP. EL SOCORRO"
    },
    {
      "id_municipio": 122,
      "id_estado": 12,
      "municipio": "MP. CAMAGUAN"
    },
    {
      "id_municipio": 123,
      "id_estado": 12,
      "municipio": "MP. MELLADO"
    },
    {
      "id_municipio": 124,
      "id_estado": 12,
      "municipio": "MP. ORTIZ"
    },
    {
      "id_municipio": 125,
      "id_estado": 12,
      "municipio": "MP. CHAGUARAMAS"
    },
    {
      "id_municipio": 126,
      "id_estado": 12,
      "municipio": "MP. S JOSE DE GUARIBE"
    },
    {
      "id_municipio": 127,
      "id_estado": 12,
      "municipio": "MP. RIBAS"
    },
    {
      "id_municipio": 128,
      "id_estado": 12,
      "municipio": "MP. S MARIA DE IPIRE"
    },
    {
      "id_municipio": 129,
      "id_estado": 13,
      "municipio": "MP. VARGAS"
    },
    {
      "id_municipio": 130,
      "id_estado": 14,
      "municipio": "MP. IRIBARREN"
    },
    {
      "id_municipio": 131,
      "id_estado": 14,
      "municipio": "MP. TORRES"
    },
    {
      "id_municipio": 132,
      "id_estado": 14,
      "municipio": "MP. PALAVECINO"
    },
    {
      "id_municipio": 133,
      "id_estado": 14,
      "municipio": "MP. JIMENEZ"
    },
    {
      "id_municipio": 134,
      "id_estado": 14,
      "municipio": "MP. CRESPO"
    },
    {
      "id_municipio": 135,
      "id_estado": 14,
      "municipio": "MP. MORAN"
    },
    {
      "id_municipio": 136,
      "id_estado": 14,
      "municipio": "MP. SIMON PLANAS"
    },
    {
      "id_municipio": 137,
      "id_estado": 14,
      "municipio": "MP. ANDRES E BLANCO"
    },
    {
      "id_municipio": 138,
      "id_estado": 15,
      "municipio": "MP. ALBERTO ADRIANI"
    },
    {
      "id_municipio": 139,
      "id_estado": 15,
      "municipio": "MP. TULIO F CORDERO"
    },
    {
      "id_municipio": 140,
      "id_estado": 15,
      "municipio": "MP. CAMPO ELIAS"
    },
    {
      "id_municipio": 141,
      "id_estado": 15,
      "municipio": "MP. SANTOS MARQUINA"
    },
    {
      "id_municipio": 142,
      "id_estado": 15,
      "municipio": "MP. CARACCIOLO PARRA"
    },
    {
      "id_municipio": 143,
      "id_estado": 15,
      "municipio": "MP. RIVAS DAVILA"
    },
    {
      "id_municipio": 144,
      "id_estado": 15,
      "municipio": "MP. CARDENAL QUINTERO"
    },
    {
      "id_municipio": 145,
      "id_estado": 15,
      "municipio": "MP. PUEBLO LLANO"
    },
    {
      "id_municipio": 146,
      "id_estado": 15,
      "municipio": "MP. ANDRES BELLO"
    },
    {
      "id_municipio": 147,
      "id_estado": 15,
      "municipio": "MP. RANGEL"
    },
    {
      "id_municipio": 148,
      "id_estado": 15,
      "municipio": "MP. OB. RAMOS DE LORA"
    },
    {
      "id_municipio": 149,
      "id_estado": 15,
      "municipio": "MP. ANTONIO PINTO S."
    },
    {
      "id_municipio": 150,
      "id_estado": 15,
      "municipio": "MP. PADRE NOGUERA"
    },
    {
      "id_municipio": 151,
      "id_estado": 15,
      "municipio": "MP. ARICAGUA"
    },
    {
      "id_municipio": 152,
      "id_estado": 15,
      "municipio": "MP. JULIO CESAR SALAS"
    },
    {
      "id_municipio": 153,
      "id_estado": 15,
      "municipio": "MP. JUSTO BRICE\u00d1O"
    },
    {
      "id_municipio": 154,
      "id_estado": 15,
      "municipio": "MP. ARZOBISPO CHACON"
    },
    {
      "id_municipio": 155,
      "id_estado": 15,
      "municipio": "MP. ZEA"
    },
    {
      "id_municipio": 156,
      "id_estado": 15,
      "municipio": "MP. GUARAQUE"
    },
    {
      "id_municipio": 157,
      "id_estado": 16,
      "municipio": "MP. PLAZA"
    },
    {
      "id_municipio": 158,
      "id_estado": 16,
      "municipio": "MP. BARUTA"
    },
    {
      "id_municipio": 159,
      "id_estado": 16,
      "municipio": "MP. EL HATILLO"
    },
    {
      "id_municipio": 160,
      "id_estado": 16,
      "municipio": "MP. CRISTOBAL ROJAS"
    },
    {
      "id_municipio": 161,
      "id_estado": 16,
      "municipio": "MP. CHACAO"
    },
    {
      "id_municipio": 162,
      "id_estado": 16,
      "municipio": "MP. LANDER"
    },
    {
      "id_municipio": 163,
      "id_estado": 16,
      "municipio": "MP. LOS SALIAS"
    },
    {
      "id_municipio": 164,
      "id_estado": 16,
      "municipio": "MP. PAZ CASTILLO"
    },
    {
      "id_municipio": 165,
      "id_estado": 16,
      "municipio": "MP. GUAICAIPURO"
    },
    {
      "id_municipio": 166,
      "id_estado": 16,
      "municipio": "MP. BRION"
    },
    {
      "id_municipio": 167,
      "id_estado": 16,
      "municipio": "MP. BUROZ"
    },
    {
      "id_municipio": 168,
      "id_estado": 16,
      "municipio": "MP. CARRIZAL"
    },
    {
      "id_municipio": 169,
      "id_estado": 16,
      "municipio": "MP. ACEVEDO"
    },
    {
      "id_municipio": 170,
      "id_estado": 16,
      "municipio": "MP. SIMON BOLIVAR"
    },
    {
      "id_municipio": 171,
      "id_estado": 16,
      "municipio": "MP. PEDRO GUAL"
    },
    {
      "id_municipio": 172,
      "id_estado": 17,
      "municipio": "MP. MATURIN"
    },
    {
      "id_municipio": 173,
      "id_estado": 17,
      "municipio": "MP. URACOA"
    },
    {
      "id_municipio": 174,
      "id_estado": 17,
      "municipio": "MP. CARIPE"
    },
    {
      "id_municipio": 175,
      "id_estado": 17,
      "municipio": "MP. PUNCERES"
    },
    {
      "id_municipio": 176,
      "id_estado": 17,
      "municipio": "MP. SANTA BARBARA"
    },
    {
      "id_municipio": 177,
      "id_estado": 17,
      "municipio": "MP. AGUASAY"
    },
    {
      "id_municipio": 178,
      "id_estado": 18,
      "municipio": "MP. MANEIRO"
    },
    {
      "id_municipio": 179,
      "id_estado": 18,
      "municipio": "MP. MARI\u00d1O"
    },
    {
      "id_municipio": 180,
      "id_estado": 18,
      "municipio": "MP. GARCIA"
    },
    {
      "id_municipio": 181,
      "id_estado": 18,
      "municipio": "MP. ANTOLIN DEL CAMPO"
    },
    {
      "id_municipio": 182,
      "id_estado": 18,
      "municipio": "MP. DIAZ"
    },
    {
      "id_municipio": 183,
      "id_estado": 18,
      "municipio": "MP. PENIN. DE MACANAO"
    },
    {
      "id_municipio": 184,
      "id_estado": 18,
      "municipio": "MP. TUBORES"
    },
    {
      "id_municipio": 185,
      "id_estado": 18,
      "municipio": "MP. GOMEZ"
    },
    {
      "id_municipio": 186,
      "id_estado": 18,
      "municipio": "MP. MARCANO"
    },
    {
      "id_municipio": 187,
      "id_estado": 18,
      "municipio": "MP. VILLALBA(I.COCHE)"
    },
    {
      "id_municipio": 188,
      "id_estado": 19,
      "municipio": "MP. ARAURE"
    },
    {
      "id_municipio": 189,
      "id_estado": 19,
      "municipio": "MP. TUREN"
    },
    {
      "id_municipio": 190,
      "id_estado": 19,
      "municipio": "MP. GUANARE"
    },
    {
      "id_municipio": 191,
      "id_estado": 19,
      "municipio": "MP. GENARO BOCONOITO"
    },
    {
      "id_municipio": 192,
      "id_estado": 19,
      "municipio": "MP. ESTELLER"
    },
    {
      "id_municipio": 193,
      "id_estado": 19,
      "municipio": "MP. GUANARITO"
    },
    {
      "id_municipio": 194,
      "id_estado": 19,
      "municipio": "MP. AGUA BLANCA"
    },
    {
      "id_municipio": 195,
      "id_estado": 19,
      "municipio": "MP. M.JOSE V DE UNDA"
    },
    {
      "id_municipio": 196,
      "id_estado": 19,
      "municipio": "MP. OSPINO"
    },
    {
      "id_municipio": 197,
      "id_estado": 19,
      "municipio": "MP. S RAFAEL DE ONOTO"
    },
    {
      "id_municipio": 198,
      "id_estado": 19,
      "municipio": "MP. SANTA ROSALIA"
    },
    {
      "id_municipio": 199,
      "id_estado": 19,
      "municipio": "MP. PAPELON"
    },
    {
      "id_municipio": 200,
      "id_estado": 20,
      "municipio": "MP. MONTES"
    },
    {
      "id_municipio": 201,
      "id_estado": 20,
      "municipio": "MP. BERMUDEZ"
    },
    {
      "id_municipio": 202,
      "id_estado": 20,
      "municipio": "MP. CRUZ S ACOSTA"
    },
    {
      "id_municipio": 203,
      "id_estado": 20,
      "municipio": "MP. VALDEZ"
    },
    {
      "id_municipio": 204,
      "id_estado": 20,
      "municipio": "MP. RIBERO"
    },
    {
      "id_municipio": 205,
      "id_estado": 20,
      "municipio": "MP. MEJIA"
    },
    {
      "id_municipio": 206,
      "id_estado": 20,
      "municipio": "MP. BENITEZ"
    },
    {
      "id_municipio": 207,
      "id_estado": 21,
      "municipio": "MP. CARDENAS"
    },
    {
      "id_municipio": 208,
      "id_estado": 21,
      "municipio": "MP. SAN CRISTOBAL"
    },
    {
      "id_municipio": 209,
      "id_estado": 21,
      "municipio": "MP. PEDRO MARIA URE\u00d1A"
    },
    {
      "id_municipio": 210,
      "id_estado": 21,
      "municipio": "MP. TORBES"
    },
    {
      "id_municipio": 211,
      "id_estado": 21,
      "municipio": "MP. JUNIN"
    },
    {
      "id_municipio": 212,
      "id_estado": 21,
      "municipio": "MP. GARCIA DE HEVIA"
    },
    {
      "id_municipio": 213,
      "id_estado": 21,
      "municipio": "MP. GUASIMOS"
    },
    {
      "id_municipio": 214,
      "id_estado": 21,
      "municipio": "MP. CORDOBA"
    },
    {
      "id_municipio": 215,
      "id_estado": 21,
      "municipio": "MP. MICHELENA"
    },
    {
      "id_municipio": 216,
      "id_estado": 21,
      "municipio": "MP. AYACUCHO"
    },
    {
      "id_municipio": 217,
      "id_estado": 21,
      "municipio": "MP. FERNANDEZ FEO"
    },
    {
      "id_municipio": 218,
      "id_estado": 21,
      "municipio": "MP. JAUREGUI"
    },
    {
      "id_municipio": 219,
      "id_estado": 21,
      "municipio": "MP. PANAMERICANO"
    },
    {
      "id_municipio": 220,
      "id_estado": 21,
      "municipio": "MP. JOSE M. VARGAS"
    },
    {
      "id_municipio": 221,
      "id_estado": 21,
      "municipio": "MP. CAPACHO NUEVO"
    },
    {
      "id_municipio": 222,
      "id_estado": 21,
      "municipio": "MP. CAPACHO VIEJO"
    },
    {
      "id_municipio": 223,
      "id_estado": 21,
      "municipio": "MP. LOBATERA"
    },
    {
      "id_municipio": 224,
      "id_estado": 21,
      "municipio": "MP. SAN JUDAS TADEO"
    },
    {
      "id_municipio": 225,
      "id_estado": 21,
      "municipio": "MP. SEBORUCO"
    },
    {
      "id_municipio": 226,
      "id_estado": 21,
      "municipio": "MP. ANTONIO ROMULO C"
    },
    {
      "id_municipio": 227,
      "id_estado": 21,
      "municipio": "MP. URIBANTE"
    },
    {
      "id_municipio": 228,
      "id_estado": 21,
      "municipio": "MP. FCO DE MIRANDA"
    },
    {
      "id_municipio": 229,
      "id_estado": 21,
      "municipio": "MP. SAMUEL MALDONADO"
    },
    {
      "id_municipio": 230,
      "id_estado": 21,
      "municipio": "MP. RAFAEL URDANETA"
    },
    {
      "id_municipio": 231,
      "id_estado": 22,
      "municipio": "MP. PAMPAN"
    },
    {
      "id_municipio": 232,
      "id_estado": 22,
      "municipio": "MP. TRUJILLO"
    },
    {
      "id_municipio": 233,
      "id_estado": 22,
      "municipio": "MP. VALERA"
    },
    {
      "id_municipio": 234,
      "id_estado": 22,
      "municipio": "MP. BOCONO"
    },
    {
      "id_municipio": 235,
      "id_estado": 22,
      "municipio": "MP. S RAFAEL CARVAJAL"
    },
    {
      "id_municipio": 236,
      "id_estado": 22,
      "municipio": "MP. PAMPANITO"
    },
    {
      "id_municipio": 237,
      "id_estado": 22,
      "municipio": "MP. CANDELARIA"
    },
    {
      "id_municipio": 238,
      "id_estado": 22,
      "municipio": "MP. ESCUQUE"
    },
    {
      "id_municipio": 239,
      "id_estado": 22,
      "municipio": "MP. RAFAEL RANGEL"
    },
    {
      "id_municipio": 240,
      "id_estado": 22,
      "municipio": "MP. MONTE CARMELO"
    },
    {
      "id_municipio": 241,
      "id_estado": 22,
      "municipio": "MP. MOTATAN"
    },
    {
      "id_municipio": 242,
      "id_estado": 22,
      "municipio": "MP. LA CEIBA"
    },
    {
      "id_municipio": 243,
      "id_estado": 22,
      "municipio": "MP. J.V. CAMPO ELIAS"
    },
    {
      "id_municipio": 244,
      "id_estado": 22,
      "municipio": "MP. J.F.MARQUEZ C."
    },
    {
      "id_municipio": 245,
      "id_estado": 22,
      "municipio": "MP. CARACHE"
    },
    {
      "id_municipio": 246,
      "id_estado": 23,
      "municipio": "MP. PE\u00d1A"
    },
    {
      "id_municipio": 247,
      "id_estado": 23,
      "municipio": "MP. NIRGUA"
    },
    {
      "id_municipio": 248,
      "id_estado": 23,
      "municipio": "MP. SAN FELIPE"
    },
    {
      "id_municipio": 249,
      "id_estado": 23,
      "municipio": "MP. MANUEL MONGE"
    },
    {
      "id_municipio": 250,
      "id_estado": 23,
      "municipio": "MP. COCOROTE"
    },
    {
      "id_municipio": 251,
      "id_estado": 23,
      "municipio": "MP. JOSE ANTONIO PAEZ"
    },
    {
      "id_municipio": 252,
      "id_estado": 23,
      "municipio": "MP. URACHICHE"
    },
    {
      "id_municipio": 253,
      "id_estado": 23,
      "municipio": "MP. LA TRINIDAD"
    },
    {
      "id_municipio": 254,
      "id_estado": 23,
      "municipio": "MP. VEROES"
    },
    {
      "id_municipio": 255,
      "id_estado": 23,
      "municipio": "MP. ARISTIDES BASTID"
    },
    {
      "id_municipio": 256,
      "id_estado": 24,
      "municipio": "MP. MARACAIBO"
    },
    {
      "id_municipio": 257,
      "id_estado": 24,
      "municipio": "MP. JESUS E LOSSADA"
    },
    {
      "id_municipio": 258,
      "id_estado": 24,
      "municipio": "MP. LAGUNILLAS"
    },
    {
      "id_municipio": 259,
      "id_estado": 24,
      "municipio": "MP. CATATUMBO"
    },
    {
      "id_municipio": 260,
      "id_estado": 24,
      "municipio": "MP. LA CA\u00d1ADA DE U."
    },
    {
      "id_municipio": 261,
      "id_estado": 24,
      "municipio": "MP. MARA"
    },
    {
      "id_municipio": 262,
      "id_estado": 24,
      "municipio": "MP. JESUS M SEMPRUN"
    },
    {
      "id_municipio": 263,
      "id_estado": 24,
      "municipio": "MP. COLON"
    },
    {
      "id_municipio": 264,
      "id_estado": 24,
      "municipio": "MP. FRANCISCO J PULG"
    },
    {
      "id_municipio": 265,
      "id_estado": 24,
      "municipio": "MP. CABIMAS"
    },
    {
      "id_municipio": 266,
      "id_estado": 24,
      "municipio": "MP. ROSARIO DE PERIJA"
    },
    {
      "id_municipio": 267,
      "id_estado": 24,
      "municipio": "MP. VALMORE RODRIGUEZ"
    },
    {
      "id_municipio": 268,
      "id_estado": 24,
      "municipio": "MP. MACHIQUES DE P"
    },
    {
      "id_municipio": 269,
      "id_estado": 24,
      "municipio": "MP. BARALT"
    },
    {
      "id_municipio": 270,
      "id_estado": 24,
      "municipio": "MP. INDIGENA BOLIVARIANO GUAJIRA"
    },
    {
      "id_municipio": 271,
      "id_estado": 24,
      "municipio": "MP. SANTA RITA"
    },
    {
      "id_municipio": 272,
      "id_estado": 24,
      "municipio": "MP. ALMIRANTE P"
    },
    {
      "id_municipio": 273,
      "id_estado": 2,
      "municipio": "MP. ALTO ORINOCO"
    },
    {
      "id_municipio": 274,
      "id_estado": 20,
      "municipio": "MP. ANDRES MATA"
    }
  ]
export default municipios;