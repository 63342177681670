

export default function State({
	img_1,
	img_2,
	img_3,
	img_4,
	img_5,
	img_6,
	img_7,
	title,
	description
}) {
	return (
		<div className="relative overflow-hidden bg-gray-900">
			<div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
				<div className="relative grid grid-cols-2 mx-auto max-w-full px-4 sm:static sm:px-6 lg:px-8">
					<div className="sm:max-w-3xl">
						<h1 className="font text-4xl font-bold tracking-tight text-white sm:text-6xl">
							{title}
						</h1>
						<p className="mt-12 text-2xl leading-10 text-white">
							{description}
						</p>
					</div>
					<div>
						<div className="mt-10">
							{/* Decorative image grid */}
							<div
								aria-hidden="true"
								className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-full"
							>
								<div className="absolute transform">
									<div className="flex items-center space-x-6 lg:space-x-8">
										<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
											<div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
												<img
													src={img_1}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_2}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										</div>
										<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_3}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_4}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_5}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										</div>
										<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_6}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
											<div className="h-64 w-44 overflow-hidden rounded-lg">
												<img
													src={img_7}
													className="h-full w-full object-cover object-center"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
