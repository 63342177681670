import { GET_PROFILE_SUCCESS, GET_PROFILE_FAIL} from "../actions/profile/types"

const initialState = {
		profile: null,
}

export default function profile(state = initialState, action){
		const {type, payload} = action;

		switch(type){
				case GET_PROFILE_SUCCESS:
						return {
								...state,
								profile: payload.perfil
						}
				case GET_PROFILE_FAIL:
						return {
								...state,
								profile: null
						}
				default:
						return state;
		}
}
