import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import State from "components/state/State";
import Layout from "hocs/layouts/Layout";
import  { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import img_1 from '../../../assets/img/state/caracas/1.jpg'
import img_2 from '../../../assets/img/state/caracas/2.jpeg'
import img_3 from '../../../assets/img/state/caracas/3.jpeg'
import img_4 from '../../../assets/img/state/caracas/4.jpeg'
import img_5 from '../../../assets/img/state/caracas/5.jpeg'
import img_6 from '../../../assets/img/state/caracas/6.png'
import img_7 from '../../../assets/img/state/caracas/7.jpeg'

function Caracas() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const title = "Cojedes";
	const description = "lorem ipsum dolor sit amet consectetur adipisicing elit.";
	return (
		<Layout>
			<Helmet>
				<title>+Paz+Vida+ | Nosotros</title>
				<meta
					name="description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta
					name="keywords"
					content="agencia de software, agencia de marketing, creacion de pagina web"
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://mpazyvida.org/" />
				<meta name="author" content="Fundación Arduvi" />
				<meta name="publisher" content="Fundación Arduvi" />

				{/* Social Media Tags */}
				<meta
					property="og:title"
					content="Fundación Arduvi | Inclusión con Amor"
				/>
				<meta
					property="og:description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta property="og:url" content="https://mpazyvida.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta
					name="twitter:title"
					content="Fundación Arduvi | Inclusión con Amor"
				/>
				<meta
					name="twitter:description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<div className="bg-gray-900">
			<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<State img_1={img_1} img_2={img_2} img_3={img_3} img_4={img_4} img_5={img_5} img_6={img_6} img_7={img_7} title={title} description={description}/>

				</div>
			</div>
			<Footer />
		</Layout>
	);
}
export default Caracas;
