import { Typewriter } from "react-simple-typewriter";
// import { Link } from 'react-router-dom'
import hero_bg from "../../assets/img/hero-bg.png";

function Header() {
	return (
		<main>
			<div
				className="relative px-6 lg:px-8 bg-hero-pattern bg-contain bg-no-repeat bg-center"
				id="seccion-1"
			>
				<div className="mx-auto max-w-full xl:mx-12 xl:pt-10 lg:pt-10 lg:pb-48 pb-10">
					<div className="block lg:flex lg:items-center lg:justify-center">
						<div className="transform-gpu flex justify-center order-1 lg:order-2">
							<img
								src={hero_bg}
								alt="hero background"
								className="w-10/12 md:w-3/12 lg:w-8/12"
							/>
						</div>
						<div className="order-2 lg:order-1">
							<h1 className="text-lg text-gray-200 font-semibold tracking-tight pb-3  sm:text-4xl">
								Personal de la Fundación Movimiento por la Paz y la Vida
							</h1>
							{/* <p className="text-xl font-semibold tracking-tight pb-4 text-gray-200 sm:text-5xl">
								2024 +<span> </span>
								<Typewriter
									words={[" PAZ.", " VIDA.", " BARRIO.", " DEPORTE."]}
									loop={0}
									cursor
									cursorStyle="_"
									typeSpeed={120}
									deleteSpeed={50}
									delaySpeed={1000}
									// onLoopDone={handleDone}
									// onType={handleType}
								/>
							</p>
							<p className="my-6 text-2xl max-w-3xl leading-8 text-gray-200">
								"Primero el barrio, las comunidades, los sectores vulnerables,
								los nuevos urbanismos, los centros penitenciarios y luego el
								discurso". <strong>ALEXANDER VARGAS</strong>
							</p> */}

						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Header;
