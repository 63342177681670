
import Header from "components/directive/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import CTA from "components/home/CTA";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Features from "components/directive/Features";
import hero_bg from "../../../assets/img/item-2.png";

function Mujeres() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const features = [
		{
			description: 'Sensibilizar y concienciar a la sociedad sobre la problemática de la violencia de género y sus consecuencias para las mujeres, adolescentes y niñas.'
		},{
			description: 'Ofrecer servicios de asesoramiento, orientación, acompañamiento y apoyo psicosocial, jurídico y económico a las mujeres y las niñas que sufren o han sufrido violencia de género.'
		},{
			description: 'Coordinar, brindar o gestionar espacios de acogida, refugio y protección a las mujeres, las jóvenes y las niñas que necesitan salir de situaciones de riesgo o amenaza por violencia de género.'
		},{
			description: 'Promover la autonomía, el empoderamiento y la participación de las mujeres y las niñas en todos los ámbitos de la vida, especialmente en el deportivo, el cultural, el educativo, el laboral, el político y el comunitario.'
		},{
			description: 'Impulsar la formación, la capacitación y el acceso al empleo digno y de calidad para las mujeres, especialmente las que tienen mayores dificultades por su situación de vulnerabilidad.'
		},{
			description: 'Fomentar el desarrollo de proyectos productivos, cooperativos y solidarios que generen ingresos y oportunidades para las mujeres, especialmente las que viven en zonas rurales o marginales.'
		},{
			description: 'Facilitar el acceso a la salud integral, la planificación familiar, la salud sexual y reproductiva, y la prevención y el tratamiento del VIH/SIDA y otras enfermedades de transmisión sexual para las mujeres las jóvenes y las niñas.'
		},{
			description: 'Apoyar la educación formal e informal de las mujeres y las niñas, especialmente las que tienen menor nivel de escolarización o han abandonado el sistema educativo por razones de violencia, embarazo, matrimonio o trabajo infantil.'
		},{
			description: 'Estimular la expresión artística, cultural, deportiva y recreativa de las mujeres y las niñas, como medio de liberación, comunicación, autoestima y bienestar.'
		}
	]
	const mision = "Mujeres por la Paz y la Vida es luchar por la igualdad de género, los derechos humanos y la justicia social, mediante la denuncia, la prevención y la atención de la violencia contra las mujeres, adolescentes y niñas, especialmente las que se encuentran en situación de vulnerabilidad por razones de pobreza, discriminación, exclusión o conflicto."
	return (
		<Layout>
			<Helmet>
				<title>+Paz+Vida+ | Mujeres</title>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://mpazyvida.org/" />
				<meta name="author" content="Fundacion por la paz y la vida" />
				<meta name="publisher" content="Fundacion por la paz y la vida" />

				{/* Social Media Tags */}
				<meta
					property="og:title"
					content="M. Paz y Vida"
				/>
				<meta
					property="og:description"
					content="M. Paz y Vida"
				/>
				<meta property="og:url" content="https://mpazyvida.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta
					name="twitter:title"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:description"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<Header hero_bg={hero_bg} title={"Mujeres por la paz y la vida"} mission={mision}/>
				</div>
				<CTA />
				<Features title={"Mujeres por la Paz y la Vida"} features={features}/>
			</div>
			<Footer />
		</Layout>
	);
}
export default Mujeres;
