import AffiliatedCompaniesSlider from '../slider/Slider'
import img_1 from '../../assets/img/slider/1.png';
import img_2 from '../../assets/img/slider/2.png';
import img_3 from '../../assets/img/slider/3.png';
import img_4 from '../../assets/img/slider/4.png';
import img_5 from '../../assets/img/slider/5.png';
import img_6 from '../../assets/img/slider/6.png';
import img_7 from '../../assets/img/slider/7.png';
import img_8 from '../../assets/img/slider/8.png';
import img_9 from '../../assets/img/slider/9.png';
import img_10 from '../../assets/img/slider/10.png';
import img_11 from '../../assets/img/slider/11.png';
import img_12 from '../../assets/img/slider/12.png';
import img_13 from '../../assets/img/slider/13.png';
import img_14 from '../../assets/img/slider/14.png';
import img_15 from '../../assets/img/slider/15.png';
import img_16 from '../../assets/img/slider/16.png';
import img_17 from '../../assets/img/slider/17.png';
import img_18 from '../../assets/img/slider/18.png';
import img_19 from '../../assets/img/slider/19.png';
import img_20 from '../../assets/img/slider/20.png';
import img_21 from '../../assets/img/slider/21.png';
import img_22 from '../../assets/img/slider/22.png';
import img_23 from '../../assets/img/slider/23.png';
import img_24 from '../../assets/img/slider/24.png';
import img_25 from '../../assets/img/slider/25.png';
import img_26 from '../../assets/img/slider/26.png';
import img_27 from '../../assets/img/slider/27.png';
import img_28 from '../../assets/img/slider/28.png';
import img_29 from '../../assets/img/slider/29.png';
import img_30 from '../../assets/img/slider/30.png';
import img_31 from '../../assets/img/slider/31.png';
import img_32 from '../../assets/img/slider/32.png';
import img_33 from '../../assets/img/slider/33.png';
import img_34 from '../../assets/img/slider/34.png';
import img_35 from '../../assets/img/slider/35.png';
import img_36 from '../../assets/img/slider/36.png';
import React, { useState, useEffect } from 'react';

const lines = [
	{ name: "img_1", img: img_1, alt: "img_1" },
	{ name: "img_2", img: img_2, alt: "img_2" },
	{ name: "img_3", img: img_3, alt: "img_3" },
	{ name: "img_4", img: img_4, alt: "img_4" },
	{ name: "img_5", img: img_5, alt: "img_5" },
	{ name: "img_6", img: img_6, alt: "img_6" },
	{ name: "img_7", img: img_7, alt: "img_7" },
	{ name: "img_8", img: img_8, alt: "img_8" },
	{ name: "img_9", img: img_9, alt: "img_9" },
	{ name: "img_10", img: img_10, alt: "img_10" },
	{ name: "img_11", img: img_11, alt: "img_11" },
	{ name: "img_12", img: img_12, alt: "img_12" },
	{ name: "img_13", img: img_13, alt: "img_13" },
	{ name: "img_14", img: img_14, alt: "img_14" },
	{ name: "img_15", img: img_15, alt: "img_15" },
	{ name: "img_16", img: img_16, alt: "img_16" },
	{ name: "img_17", img: img_17, alt: "img_17" },
	{ name: "img_18", img: img_18, alt: "img_18" },
	{ name: "img_19", img: img_19, alt: "img_19" },
	{ name: "img_20", img: img_20, alt: "img_20" },
	{ name: "img_21", img: img_21, alt: "img_21" },
	{ name: "img_22", img: img_22, alt: "img_22" },
	{ name: "img_23", img: img_23, alt: "img_23" },
	{ name: "img_24", img: img_24, alt: "img_24" },
	{ name: "img_25", img: img_25, alt: "img_25" },
	{ name: "img_26", img: img_26, alt: "img_26" },
	{ name: "img_27", img: img_27, alt: "img_27" },
	{ name: "img_28", img: img_28, alt: "img_28" },
	{ name: "img_29", img: img_29, alt: "img_29" },
	{ name: "img_30", img: img_30, alt: "img_30" },
	{ name: "img_31", img: img_31, alt: "img_31" },
	{ name: "img_32", img: img_32, alt: "img_32" },
	{ name: "img_33", img: img_33, alt: "img_33" },
	{ name: "img_34", img: img_34, alt: "img_34" },
	{ name: "img_35", img: img_35, alt: "img_35" },
	{ name: "img_36", img: img_36, alt: "img_36" },
];



export default function SliderPeacemaker() { // Ensure lines is passed as a prop
	const [selectedImage, setSelectedImage] = useState(null);
	const [isEnlarged, setIsEnlarged] = useState(false);
	const [shuffledLines, setShuffledLines] = useState([]);

	useEffect(() => {
			setShuffledLines(shuffleArray(lines)); // Use a spread to copy the lines array
	}, [lines]);

	const handleImageClick = (index) => {
			setSelectedImage(index);
			setIsEnlarged(true); // Set the enlargement state to true
	};

	// Close the enlarged image
	const handleCloseEnlargedImage = () => {
			setIsEnlarged(false);
	};

	// Function to shuffle an array
	function shuffleArray(array) {
			return array.sort(() => Math.random() - 0.5);
	}

	return (
			<div className="bg-gray-600" id='empresas-afiliadas'>
					<div className='container mx-auto max-w-7xl py-12'>
							<h2 className="text-3xl mb-6 text-green-corpo text-center text-white font-extrabold tracking-tight sm:text-4xl">
									NUESTRAS LINEAS
							</h2>
							<AffiliatedCompaniesSlider>
									{shuffledLines.map((line, index) => (
											<div key={index}>
													<div className='flex justify-center'>
															<img
																	src={line.img}
																	alt={line.alt}
																	className={`h-20 cursor-pointer ${selectedImage === index ? 'expanded' : ''}`}
																	onClick={() => handleImageClick(index)}
															/>
													</div>
											</div>
									))}
							</AffiliatedCompaniesSlider>
							{/* Overlay for enlarged image */}
							{isEnlarged && (
									<div
											className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
											onClick={handleCloseEnlargedImage}
									>
											<img
													src={shuffledLines[selectedImage]?.img}
													alt="Enlarged"
													className="max-w-1/2 max-h-full p-4"
											/>
									</div>
							)}
					</div>
			</div>
	);
}
