import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";
const navigation = {
	social: [
		{
			name: "Facebook",
			href: "#",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "Instagram",
			href: "https://www.instagram.com/funmovpazyvida1/",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "X",
			href: "#",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
				</svg>
			),
		},
	],
};
function Header() {
	return (
		<main>
			<div
				className="relative px-6 lg:px-8 bg-hero-pattern bg-contain bg-no-repeat bg-center"
				id="seccion-1"
			>
				<div className="mx-auto max-w-full xl:mx-12 xl:pt-32 xl:pb-40 lg:pt-40 lg:pb-40 pt-24 pb-12 ">
					<div>
						<div>
							<h1 className="text-4xl text-gray-200 font-semibold tracking-tight pb-8  sm:text-7xl">
								Sobre Nosotros
							</h1>
							<p className="mt-6 text-2xl leading-10 text-gray-200 max-w-5xl">
								En la Fundación Movimiento por la Paz y la Vida, estamos
								profundamente comprometidos con la promoción de una cultura de
								convivencia pacífica y solidaria. Nuestro corazón late con los
								valores y principios revolucionarios, bolivarianos y cristianos,
								y nos dedicamos a apoyar e impulsar proyectos que los
								fortalezcan. Nos enfocamos en la financiación, evaluación y
								control administrativo de iniciativas que buscan crear
								territorios libres de violencia. Trabajamos mano a mano con una
								amplia gama de órganos, entidades, misiones, comunidades
								organizadas y voluntarios apasionados, todos unidos por la
								misión de hacer una diferencia significativa. Nuestra visión es
								clara: queremos ser una institución revolucionaria y socialista
								de referencia mundial. Aspiramos a ser un modelo ejemplar en la
								implementación eficiente y de alta calidad de talento humano y
								recursos tecnológicos, pedagógicos, culturales, religiosos,
								deportivos, recreativos, ecológicos, y en la promoción de la
								salud mental y física. En la Fundación Movimiento por la Paz y
								la Vida, estamos comprometidos con la creación de espacios
								autóctonos de paz. Aplicamos métodos basados en valores
								cristianos, bolivarianos y socialistas, buscando siempre
								garantizar la organización, la productividad y una convivencia
								pacífica y solidaria que conduzca a la paz y al bienestar en
								todo el territorio nacional.
							</p>
							<div className="absolute bottom-0 left-0 flex space-x-6 xl:mx-20 mx-6">
								{navigation.social.map((item) => (
									<a
										key={item.name}
										href={item.href}
										className="text-gray-900 hover:text-gray-800"
									>
										<span className="sr-only">{item.name}</span>
										<item.icon className="h-8 w-8" aria-hidden="true" />
									</a>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Header;
