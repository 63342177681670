const PLAN_CHOICES = [
	["ESTRUCTURA TERRITORIAL", "ESTRUCTURA TERRITORIAL"],
	["PROTECCIÓN DE DERECHOS FUNDAMENTALES", "PROTECCIÓN DE DERECHOS FUNDAMENTALES"],
	["GRANDES BASES DE PAZ", "GRANDES BASES DE PAZ"],
	["COMEDORES POR LA PAZ Y LA VIDA", "COMEDORES POR LA PAZ Y LA VIDA"],
	["INTERINSTITUCIONAL", "INTERINSTITUCIONAL"],
	["ATENCIÓN AL PACIFICADOR", "ATENCIÓN AL PACIFICADOR"],
	["PERSONAS EN VULNERABILIDAD", "PERSONAS EN VULNERABILIDAD"],
	["DEFENSORES ACTIVOS", "DEFENSORES ACTIVOS"],
	["JUVENTUD", "JUVENTUD"],
	["MUCHACHADA", "MUCHACHADA"],
	["MINI MUCHACHADA", "MINI MUCHACHADA"],
	["MUJERES", "MUJERES"],
	["ADULTO MAYOR", "ADULTO MAYOR"],
	["PENITENCIARIO", "PENITENCIARIO"],
	["FORMACIÓN", "FORMACIÓN"],
	["COMUNICACIÓN Y DIFUSIÓN MULTIPLATAFORMA", "COMUNICACIÓN Y DIFUSIÓN MULTIPLATAFORMA"],
	["MOVILIZACIÓN", "MOVILIZACIÓN"],
	["COMUNAS", "COMUNAS"],
	["GRAN NÚCLEO DE PAZ Y SANA CONVIVENCIA", "GRAN NÚCLEO DE PAZ Y SANA CONVIVENCIA"],
	["POLÍTICA", "POLÍTICA"],
	["CULTURA", "CULTURA"],
	["DEPORTE", "DEPORTE"],
	["RECREACIÓN", "RECREACIÓN"],
	["SALUD", "SALUD"],
	["EDUCACIÓN", "EDUCACIÓN"],
	["AFRODESCENDIENTES", "AFRODESCENDIENTES"],
	["ASUNTOS INTERRELIGIOSOS", "ASUNTOS INTERRELIGIOSOS"],
	["DIVERSIDAD SEXUAL", "DIVERSIDAD SEXUAL"],
	["DISCAPACIDAD", "DISCAPACIDAD"],
	["TERRITORIOS ESPECIALES", "TERRITORIOS ESPECIALES"],
	["TRABAJADORES", "TRABAJADORES"],
	["PUEBLOS Y COMUNIDADES INDÍGENAS", "PUEBLOS Y COMUNIDADES INDÍGENAS"],
	["CORAZÓN URBANO", "CORAZÓN URBANO"],
	["CORAZÓN URBANO KIDS", "CORAZÓN URBANO KIDS"],
	["MUÉVETE", "MUÉVETE"],
	["MOTOPIRUETA", "MOTOPIRUETA"],
	["MILICIA", "MILICIA"],
	["CUADRILLAS", "CUADRILLAS"],
	["ECONOMÍA PRODUCTIVA", "ECONOMÍA PRODUCTIVA"],
	["ECOSOCIALISMO", "ECOSOCIALISMO"],
	["AGROALIMENTARIA", "AGROALIMENTARIA"]
];
export default PLAN_CHOICES;
