import curveTop from '../../assets/img/curve-top.svg';
import item_1 from '../../assets/img/item-1.png';
import item_2 from '../../assets/img/item-2.png';
import item_3 from '../../assets/img/item-3.png';
import item_4 from '../../assets/img/item-4.png';

const incentives = [
	{
		name: 'JUVENTUD',
		imageUrl: item_1,
		description: "POR LA PAZ Y LA VIDA.",
		href: '/jovenes',
	},
	{
		name: 'MUJERES',
		imageUrl: item_2,
		description: "POR LA PAZ Y LA VIDA.",
		href: '/mujeres',
	},
	{
		name: 'ADULTO MAYOR',
		imageUrl: item_3,
		description: "POR LA PAZ Y LA VIDA.",
		href: '/adulto-mayor',
	},
	{
		name: 'PENITENCIARIO',
		imageUrl: item_4,
		description: "POR LA PAZ Y LA VIDA.",
		href: '/penitenciario',
	},
	// Agrega más elementos según sea necesario
];

export default function Incentives() {
	return (
		<div className="bg-section-pattern bg-fixed bg-cover relative" id='seccion-2'>
			<img src={curveTop} className="h-20 absolute top-0 w-full drop-shadow-[0_50px_25px_rgba(0,0,0,0.45)]" alt="" />
			<div className="mx-auto lg:mx-12 max-w-full py-24 px-4 sm:px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:max-w-none">
					<div className="max-w-3xl mx-auto text-center backdrop-blur-md bg-white/20 p-4 rounded-xl">
						<h2 className="text-4xl font-bold tracking-tight text-white">
							PÚBLICO OBJETIVO.
						</h2>
					</div>
					<div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-4">
						{incentives.map((incentive) => (
							<a key={incentive.name} href={incentive.href} className="flex flex-col items-center justify-center backdrop-blur-md bg-white/20 p-4 rounded-xl">
								<img className="h-32" src={incentive.imageUrl} alt={incentive.name} />
								<h3 className="mt-4 text-lg font-bold text-white">{incentive.name}</h3>
								<p className="text-gray-300">{incentive.description}</p>
							</a>
						))}
					</div>
				</div>
			</div>
			<img src={curveTop} className="h-20 w-full transform scale-y-[-1] drop-shadow-[0_50px_25px_rgba(0,0,0,0.45)]" alt="" />
		</div>
	);
}
