import { GET_PROFILE_SUCCESS, GET_PROFILE_FAIL} from './types'
import axios from 'axios'

export const get_profile = (id) => async dispatch => {
		const config = {
				headers: {
						'Accept': 'application/json'
				}
		};

		try{

				const res = await axios.get(`${process.env.REACT_APP_API_URL}/perfil/${id}`, config)

				if(res.status === 200){
						dispatch({
								type: GET_PROFILE_SUCCESS,
								payload: res.data
						});
				}else{
						dispatch({
								type: GET_PROFILE_FAIL
						});
				}

		}catch(err){
				dispatch({
						type: GET_PROFILE_FAIL
				});
		}
}
