import { Link } from "react-router-dom";
import imgCaracas from "../../assets/img/flags/caracas.png";
import imgAmazonas from "../../assets/img/flags/amazonas.png";
import imgAnzoategui from "../../assets/img/flags/anzoategui.png";
import imgApure from "../../assets/img/flags/apure.png";
import imgAragua from "../../assets/img/flags/aragua.png";
import imgBarinas from "../../assets/img/flags/barinas.png";
import imgBolivar from "../../assets/img/flags/bolivar.png";
import imgCarabobo from "../../assets/img/flags/carabobo.png";
import imgCojedes from "../../assets/img/flags/cojedes.png";
import imgDeltaAmacuro from "../../assets/img/flags/delta-amacuro.png";
import imgFalcon from "../../assets/img/flags/falcon.png";
import imgGuarico from "../../assets/img/flags/guarico.png";
import imgLaGuaira from "../../assets/img/flags/la-guaira.png";
import imgLara from "../../assets/img/flags/lara.png";
import imgMerida from "../../assets/img/flags/merida.png";
import imgMiranda from "../../assets/img/flags/miranda.png";
import imgMonagas from "../../assets/img/flags/monagas.png";
import imgNuevaEsparta from "../../assets/img/flags/nueva-esparta.png";
import imgPortuguesa from "../../assets/img/flags/portuguesa.png";
import imgSucre from "../../assets/img/flags/sucre.png";
import imgTachira from "../../assets/img/flags/tachira.png";
import imgTrujillo from "../../assets/img/flags/trujillo.png";
import imgYaracuy from "../../assets/img/flags/yaracuy.png";
import imgZulia from "../../assets/img/flags/zulia.png";
export default function LogoCloud() {
	
	return (
		<div className="bg-gray-900">
			<div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:px-8">
				<h2 className="mb-8 text-3xl font-bold tracking-tight text-yellow-button text-center">
					NOS ENCONTRAMOS
				</h2>
				<div className="grid grid-cols-2 gap-4 sm:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
					<Link to={'/caracas'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgCaracas} alt="Caracas" />
							<h3 className="font-bold text-gray-200">Caracas</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Distrito Capital:
							</div>
							<div className="text-xs">
								Contiene a Caracas, la capital del país, y es el centro político y económico de Venezuela.
							</div>
						</div>
					</Link>
					<Link to={'/amazonas'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgAmazonas} alt="Amazonas" />
							<h3 className="font-bold text-gray-200">Amazonas</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Amazonas:
							</div>
							<div className="text-xs">
								Famoso por su rica biodiversidad y parte de la selva amazónica, así como por el río Orinoco.
							</div>
						</div>
					</Link>
					<Link to={'/anzoategui'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgAnzoategui} alt="Anzoategui" />
							<h3 className="font-bold text-gray-200">Anzoategui</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Anzoátegui:
							</div>
							<div className="text-xs">
								Destacado por su industria petrolera y hermosas playas.
							</div>
						</div>
					</Link>
					<Link to={'/apure'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgApure} alt="Apure" />
							<h3 className="font-bold text-gray-200">Apure</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Apure:
							</div>
							<div className="text-xs">
								Conocido por su extensa región de los Llanos y la ganadería.
							</div>
						</div>
					</Link>
					<Link to={'/aragua'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgAragua} alt="Aragua" />
							<h3 className="font-bold text-gray-200">Aragua</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Aragua:
							</div>
							<div className="text-xs">
								Importante por su actividad industrial y agrícola, además de contar con atractivos turísticos como la Colonia Tovar.
							</div>
						</div>
					</Link>
					<Link to={'/barinas'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgBarinas} alt="Barinas" />
							<h3 className="font-bold text-gray-200">Barinas</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Barinas:
							</div>
							<div className="text-xs">
								Reconocido por sus llanuras y la producción agropecuaria.
							</div>
						</div>
					</Link>
					<Link to={'/bolivar'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgBolivar} alt="Bolívar" />
							<h3 className="font-bold text-gray-200">Bolívar</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Bolívar:
							</div>
							<div className="text-xs">
								Hogar del Parque Nacional Canaima y el Salto Ángel, el salto de agua más alto del mundo.
							</div>
						</div>
					</Link>
					<Link to={'/carabobo'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgCarabobo} alt="Carabobo" />
							<h3 className="font-bold text-gray-200">Carabobo</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Carabobo:
							</div>
							<div className="text-xs">
								Famoso por su importancia histórica en la independencia y su desarrollo industrial.
							</div>
						</div>
					</Link>
					<Link to={'/cojedes'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgCojedes} alt="Cojedes" />
							<h3 className="font-bold text-gray-200">Cojedes</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Cojedes:
							</div>
							<div className="text-xs">
								Conocida por sus llanuras y la agricultura.
							</div>
						</div>
					</Link>
					<Link to={'/delta-amacuro'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgDeltaAmacuro} alt="Delta Amacuro" />
							<h3 className="font-bold text-gray-200">Delta Amacuro</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Delta Amacuro:
							</div>
							<div className="text-xs">
								Distintivo por su gran delta del río Orinoco y comunidades indígenas.
							</div>
						</div>
					</Link>
					<Link to={'/falcon'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgFalcon} alt="Falcón" />
							<h3 className="font-bold text-gray-200">Falcón</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Falcón:
							</div>
							<div className="text-xs">
								Con la península de Paraguaná, es conocido por sus playas y el Parque Nacional Morrocoy.
							</div>
						</div>
					</Link>
					<Link to={'/guarico'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgGuarico} alt="Guárico" />
							<h3 className="font-bold text-gray-200">Guárico</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Guárico:
							</div>
							<div className="text-xs">
								Caracterizado por sus llanos y la agricultura, especialmente el arroz.
							</div>
						</div>
					</Link>
					<Link to={'/la-guaira'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgLaGuaira} alt="La Guaira" />
							<h3 className="font-bold text-gray-200">La Guaira</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								La Guaira:
							</div>
							<div className="text-xs">
								Destacado por su puerto principal y proximidad a Caracas.
							</div>
						</div>
					</Link>
					<Link to={'/lara'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgLara} alt="Lara" />
							<h3 className="font-bold text-gray-200">Lara</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Lara:
							</div>
							<div className="text-xs">
								Famoso por su cultura, música folclórica y el Parque Nacional Sierra de Lara.
							</div>
						</div>
					</Link>
					<Link to={'/merida'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgMerida} alt="Merida" />
							<h3 className="font-bold text-gray-200">Mérida</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Mérida:
							</div>
							<div className="text-xs">
								Conocido por los Andes venezolanos, el teleférico de Mérida y el turismo de montaña.
							</div>
						</div>
					</Link>
					<Link to={'/miranda'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgMiranda} alt="Miranda" />
							<h3 className="font-bold text-gray-200">Miranda</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Miranda:
							</div>
							<div className="text-xs">
								Destacado por su diversidad económica y proximidad a Caracas.
							</div>
						</div>
					</Link>
					<Link to={'/monagas'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgMonagas} alt="Monagas" />
							<h3 className="font-bold text-gray-200">Monagas</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Monagas:
							</div>
							<div className="text-xs">
								Conocido por la producción petrolera y el Parque Nacional Mochima.
							</div>
						</div>
					</Link>
					<Link to={'/nueva-esparta'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgNuevaEsparta} alt="Nueva Esparta" />
							<h3 className="font-bold text-gray-200">Nueva Esparta</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Nueva Esparta:
							</div>
							<div className="text-xs">
								Compuesto por islas, incluyendo Margarita, famoso por sus playas y turismo.
							</div>
						</div>
					</Link>
					<Link to={'/portuguesa'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgPortuguesa} alt="Portuguesa" />
							<h3 className="font-bold text-gray-200">Portuguesa</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Portuguesa:
							</div>
							<div className="text-xs">
								Reconocido por su producción agrícola, especialmente cereales.
							</div>
						</div>
					</Link>
					<Link to={'/sucre'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgSucre} alt="Sucre" />
							<h3 className="font-bold text-gray-200">Sucre</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Sucre:
							</div>
							<div className="text-xs">
								Famoso por sus costas en el Caribe y la pesca.
							</div>
						</div>
					</Link>
					<Link to={'/tachira'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgTachira} alt="Táchira" />
							<h3 className="font-bold text-gray-200">Táchira</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Táchira:
							</div>
							<div className="text-xs">
								Conocido por su cultura andina y el comercio con Colombia.
							</div>
						</div>
					</Link>
					<Link to={'/trujillo'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgTrujillo} alt="Trujillo" />
							<h3 className="font-bold text-gray-200">Trujillo</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Trujillo:
							</div>
							<div className="text-xs">
								Caracterizado por su geografía andina y la arquitectura colonial.
							</div>
						</div>
					</Link>
					<Link to={'/yaracuy'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgYaracuy} alt="Yaracuy" />
							<h3 className="font-bold text-gray-200">Yaracuy</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Yaracuy:
							</div>
							<div className="text-xs">
								Conocido por sus valles y la producción agrícola.
							</div>
						</div>
					</Link>
					<Link to={'/zulia'} className="bg-gray-800 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-20" src={imgZulia} alt="Zulia" />
							<h3 className="font-bold text-gray-200">Zulia</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">
								Zulia:
							</div>
							<div className="text-xs">
								Famoso por el Lago de Maracaibo y la industria petrolera.
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}
