import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Error404 from 'containers/errors/Error404';
import Home from 'containers/pages/Home';
import Cases from 'containers/pages/Cases';

import Services from 'containers/pages/Services';
import About from 'containers/pages/About';
import Careers from 'containers/pages/Careers';
import Blog from 'containers/pages/Blog';
import Contact from 'containers/pages/Contact';
import GrandesBases from 'containers/pages/GrandesBases';

import { AnimatePresence } from 'framer-motion'
import Category from 'containers/pages/Category';
import Search from 'containers/pages/Search';
import PostDetail from 'containers/pages/PostDetail';
import Caracas from 'containers/pages/state/Caracas';
import Amazonas from 'containers/pages/state/Amazonas';
import Anzoategui from 'containers/pages/state/Anzoategui';
import Apure from 'containers/pages/state/Apure';
import Aragua from 'containers/pages/state/Aragua';
import Barinas from 'containers/pages/state/Barinas';
import Bolivar from 'containers/pages/state/Bolivar';
import Carabobo from 'containers/pages/state/Carabobo';
import Cojedes from 'containers/pages/state/Cojedes';
import DeltaAmacuro from 'containers/pages/state/DeltaAmacuro';
import Falcon from 'containers/pages/state/Falcon';
import Guarico from 'containers/pages/state/Guarico';
import LaGuaira from 'containers/pages/state/LaGuaira';
import Lara from 'containers/pages/state/Lara';
import Merida from 'containers/pages/state/Merida';
import Miranda from 'containers/pages/state/Miranda';
import Monagas from 'containers/pages/state/Monagas';
import NuevaEsparta from 'containers/pages/state/NuevaEsparta';
import Portuguesa from 'containers/pages/state/Portuguesa';
import Sucre from 'containers/pages/state/Sucre';
import Tachira from 'containers/pages/state/Tachira';
import Trujillo from 'containers/pages/state/Trujillo';
import Yaracuy from 'containers/pages/state/Yaracuy';
import Zulia from 'containers/pages/state/Zulia';
import Register from 'containers/pages/Register';
import Jovenes from 'containers/pages/directive/Jovenes';
import Mujeres from 'containers/pages/directive/Mujeres';
import AdultoMayor from 'containers/pages/directive/AdultoMayor';
import Penitenciario from 'containers/pages/directive/Penitenciario';
import Profile from 'containers/pages/Profile';
import Personal from 'containers/pages/Personal';
function AnimatedRoutes(){

    const location = useLocation()

    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                    {/* Error Display */}
                    <Route path="*" element={<Error404 />} />

                    {/* Home Display */}
                    <Route path="/" element={<Home />} />
                    <Route path="/casos" element={<Cases />} />
                    <Route path="/servicios" element={<Services />} />
                    <Route path="/nosotros" element={<About />} />
                    <Route path="/carreras" element={<Careers />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<PostDetail />} />
                    <Route path="/s=:term" element={<Search />} />
                    <Route path="/category/:slug" element={<Category />} />
                    <Route path="/contacto" element={<Contact />} />
                    <Route path="/grandes-bases" element={<GrandesBases />} />
										{/* direcciones */}
										<Route path='/jovenes' element={<Jovenes />} />
										<Route path='/mujeres' element={<Mujeres />} />
										<Route path='/adulto-mayor' element={<AdultoMayor />} />
										<Route path='/penitenciario' element={<Penitenciario />} />
										{/* estados */}
                    <Route path='/caracas' element={<Caracas />} />
                    <Route path='/amazonas' element={<Amazonas />} />
                    <Route path='/anzoategui' element={<Anzoategui />} />
                    <Route path='/apure' element={<Apure />} />
                    <Route path='/aragua' element={<Aragua />} />
                    <Route path='/barinas' element={<Barinas />} />
                    <Route path='/bolivar' element={<Bolivar />} />
                    <Route path='/carabobo' element={<Carabobo />} />
                    <Route path='/cojedes' element={<Cojedes />} />
                    <Route path='/delta-amacuro' element={<DeltaAmacuro />} />
                    <Route path='/falcon' element={<Falcon />} />
                    <Route path='/guarico' element={<Guarico />} />
                    <Route path='/la-guaira' element={<LaGuaira />} />
                    <Route path='/lara' element={<Lara />} />
                    <Route path='/merida' element={<Merida />} />
                    <Route path='/miranda' element={<Miranda />} />
                    <Route path='/monagas' element={<Monagas />} />
                    <Route path='/nueva-esparta' element={<NuevaEsparta />} />
                    <Route path='/portuguesa' element={<Portuguesa />} />
                    <Route path='/sucre' element={<Sucre />} />
                    <Route path='/tachira' element={<Tachira />} />
                    <Route path='/trujillo' element={<  Trujillo />} />
                    <Route path='/yaracuy' element={<Yaracuy />} />
                    <Route path='/zulia' element={<Zulia />} />

										<Route path="/registro" element={<Register />} />
										<Route path='/pacificador/:nuip' element={<Profile />} />
										<Route path='/personal' element={<Personal />} />
								</Routes>
        </AnimatePresence>
    )
}
export default AnimatedRoutes
