
import Header from "components/directive/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import CTA from "components/home/CTA";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Features from "components/directive/Features";
import hero_bg from "../../../assets/img/item-4.png";

function Penitenciario() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const features = [
		{
			description: 'Informar y educar a los privados de libertad sobre sus derechos, deberes y beneficios penitenciarios, así como sobre las normas, procedimientos y recursos que rigen su situación jurídica y procesal.'
		},{
			description: 'Representar y defender a los privados de libertad que demuestren pertenecer al programa de pacificación, ante las autoridades, los medios de comunicación, la opinión pública y otros actores relevantes, en relación con sus demandas, necesidades y problemas penitenciarios.'
		},{
			description: 'Denunciar y combatir las situaciones de vulneración, abuso, maltrato, tortura o negligencia que sufran los privados de libertad, tanto por parte de los funcionarios, los agentes de seguridad, los otros privados de libertad o los terceros.'
		},{
			description: 'Promover y apoyar la participación activa y democrática de los privados de libertad en la gestión y el control de los centros penitenciarios, así como en la elección y el seguimiento de sus representantes y portavoces.'
		},{
			description: 'Impulsar y facilitar la comunicación, la colaboración y la solidaridad entre los privados de libertad, tanto a nivel interno como externo, para compartir información, experiencias, recursos y apoyo mutuo.'
		},{
			description: 'Fomentar y fortalecer la organización, la unidad y la cohesión de los privados de libertad, mediante la creación y el mantenimiento de asociaciones, comités, redes y otras formas de agrupación y coordinación.'
		},{
			description: 'Estimular y potenciar las capacidades, habilidades y proyectos de vida de los privados de libertad, para que puedan aprovechar las oportunidades de formación, trabajo, cultura y deporte que se les ofrecen en los centros penitenciarios.'
		},{
			description: 'Facilitar el acceso a la salud integral, la prevención y el tratamiento de las enfermedades físicas y mentales, las adicciones, el VIH/SIDA y otras enfermedades de transmisión sexual para los privados de libertad.'
		},{
			description: 'Apoyar y acompañar a los privados de libertad en el proceso de preparación para la libertad, brindándoles orientación, asesoramiento, asistencia y protección, y facilitándoles el acceso a los programas y servicios de reinserción social.'
		}
		]
	const mision = "Penitenciario por la Paz y la Vida es defender y promover los derechos, la dignidad y el bienestar de las personas mayores, especialmente de aquellas que se encuentran en situación de vulnerabilidad por razones de pobreza, discriminación, violencia o abandono."
	return (
		<Layout>
			<Helmet>
				<title>+Paz+Vida+ | Penitenciario</title>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://mpazyvida.org/" />
				<meta name="author" content="Fundacion por la paz y la vida" />
				<meta name="publisher" content="Fundacion por la paz y la vida" />

				{/* Social Media Tags */}
				<meta
					property="og:title"
					content="M. Paz y Vida"
				/>
				<meta
					property="og:description"
					content="M. Paz y Vida"
				/>
				<meta property="og:url" content="https://mpazyvida.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta
					name="twitter:title"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:description"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<Header hero_bg={hero_bg} title={"Penitenciario por la paz y la vida"} mission={mision}/>
				</div>
				<CTA />
				<Features title={"Penitenciario por la Paz y la Vida"} features={features}/>
			</div>
			<Footer />
		</Layout>
	);
}
export default Penitenciario;
