import { CheckIcon } from '@heroicons/react/24/outline'


export default function Features() {
  // Assuming `missionText` and `visionText` contain the respective statements
  const missionText = `La Misión del Movimiento Por La Paz y La Vida, es impulsar una cultura a favor de la convivencia pacífica y solidaria, mediante la articulación con Órganos, Entes, Misiones, Comunidades, y Voluntariado, fortaleciendo los valores y principios Revolucionarios, Bolivarianos, Éticos y Cristianos para organizar territorios libres de violencia, a través del desarrollo de actividades deportivas, culturales, educativas, sociales, religiosas, productivas, preventivas, e iniciativas populares que contribuyan a alcanzar la convivencia solidaria y los objetivos trazados en la consolidación de la Paz por el derecho a la Vida en Venezuela.`;
  const visionText = `La Visión del Movimiento Por La Paz y La Vida, es fortalecer el enfoque en los valores Revolucionarios, Bolivarianos y Cristianos, implementando un modelo eficiente para la constitución de Núcleos de paz, aplicando métodos que garanticen una organización productiva, que conlleve al vivir bien, en todo el territorio nacional, y así lograr la declaración de Venezuela como Territorio de sana convivencia por la Paz y la Vida, con referencia mundial.`;

  return (
    <div className="bg-gray-800">
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-yellow-button">Misión y Visión</h2>

        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          {/* Mission Statement */}
          <div className="mb-10">
            <p className="text-xl text-gray-200 mt-4">{missionText}</p>
          </div>
          {/* Vision Statement */}
					<div className='mb-10'>
						<p className="text-xl text-gray-200 mt-4">{visionText}</p>
					</div>
				</div>
			</div>
			</div>
	);
}

