
const parroquias = [
    {
      "id_parroquia": 1,
      "id_municipio": 1,
      "parroquia": "PQ. CANDELARIA"
    },
    {
      "id_parroquia": 2,
      "id_municipio": 1,
      "parroquia": "PQ. CATEDRAL"
    },
    {
      "id_parroquia": 3,
      "id_municipio": 1,
      "parroquia": "PQ. EL RECREO"
    },
    {
      "id_parroquia": 4,
      "id_municipio": 1,
      "parroquia": "PQ. SAN AGUSTIN"
    },
    {
      "id_parroquia": 5,
      "id_municipio": 1,
      "parroquia": "PQ. SANTA ROSALIA"
    },
    {
      "id_parroquia": 6,
      "id_municipio": 1,
      "parroquia": "PQ. EL VALLE"
    },
    {
      "id_parroquia": 7,
      "id_municipio": 1,
      "parroquia": "PQ. LA VEGA"
    },
    {
      "id_parroquia": 8,
      "id_municipio": 1,
      "parroquia": "PQ. COCHE"
    },
    {
      "id_parroquia": 9,
      "id_municipio": 1,
      "parroquia": "PQ. SAN PEDRO"
    },
    {
      "id_parroquia": 10,
      "id_municipio": 1,
      "parroquia": "PQ. ALTAGRACIA"
    },
    {
      "id_parroquia": 11,
      "id_municipio": 1,
      "parroquia": "PQ. MACARAO"
    },
    {
      "id_parroquia": 12,
      "id_municipio": 1,
      "parroquia": "PQ. SANTA TERESA"
    },
    {
      "id_parroquia": 13,
      "id_municipio": 1,
      "parroquia": "PQ. EL JUNQUITO"
    },
    {
      "id_parroquia": 14,
      "id_municipio": 1,
      "parroquia": "PQ. CARICUAO"
    },
    {
      "id_parroquia": 15,
      "id_municipio": 1,
      "parroquia": "PQ. SAN JOSE"
    },
    {
      "id_parroquia": 16,
      "id_municipio": 1,
      "parroquia": "PQ. SAN JUAN"
    },
    {
      "id_parroquia": 17,
      "id_municipio": 1,
      "parroquia": "PQ. SUCRE"
    },
    {
      "id_parroquia": 18,
      "id_municipio": 1,
      "parroquia": "PQ. 23 DE ENERO"
    },
    {
      "id_parroquia": 19,
      "id_municipio": 1,
      "parroquia": "PQ. SAN BERNARDINO"
    },
    {
      "id_parroquia": 20,
      "id_municipio": 1,
      "parroquia": "PQ. EL PARAISO"
    },
    {
      "id_parroquia": 21,
      "id_municipio": 1,
      "parroquia": "PQ. ANTIMANO"
    },
    {
      "id_parroquia": 22,
      "id_municipio": 1,
      "parroquia": "PQ. LA PASTORA"
    },
    {
      "id_parroquia": 23,
      "id_municipio": 2,
      "parroquia": "PQ. LUIS ALBERTO GOMEZ"
    },
    {
      "id_parroquia": 24,
      "id_municipio": 2,
      "parroquia": "PQ. FERNANDO GIRON TOVAR"
    },
    {
      "id_parroquia": 25,
      "id_municipio": 3,
      "parroquia": "CM. SAN FERNANDO DE ATABA"
    },
    {
      "id_parroquia": 26,
      "id_municipio": 4,
      "parroquia": "CM. SAN JUAN DE MANAPIARE"
    },
    {
      "id_parroquia": 27,
      "id_municipio": 5,
      "parroquia": "CM. SAN CARLOS DE RIO NEG"
    },
    {
      "id_parroquia": 28,
      "id_municipio": 6,
      "parroquia": "CM. MAROA"
    },
    {
      "id_parroquia": 29,
      "id_municipio": 7,
      "parroquia": "PQ. SAMARIAPO"
    },
    {
      "id_parroquia": 30,
      "id_municipio": 8,
      "parroquia": "PQ. SAN CRISTOBAL"
    },
    {
      "id_parroquia": 31,
      "id_municipio": 8,
      "parroquia": "PQ. EL CARMEN"
    },
    {
      "id_parroquia": 32,
      "id_municipio": 9,
      "parroquia": "PQ. LECHERIAS"
    },
    {
      "id_parroquia": 33,
      "id_municipio": 10,
      "parroquia": "CM. EL TIGRE"
    },
    {
      "id_parroquia": 34,
      "id_municipio": 11,
      "parroquia": "PQ. POZUELOS"
    },
    {
      "id_parroquia": 35,
      "id_municipio": 12,
      "parroquia": "PQ. PIRITU"
    },
    {
      "id_parroquia": 36,
      "id_municipio": 13,
      "parroquia": "PQ. ANACO"
    },
    {
      "id_parroquia": 37,
      "id_municipio": 14,
      "parroquia": "CM. CANTAURA"
    },
    {
      "id_parroquia": 38,
      "id_municipio": 15,
      "parroquia": "CM. PARIAGUAN"
    },
    {
      "id_parroquia": 39,
      "id_municipio": 11,
      "parroquia": "CM. PUERTO LA CRUZ"
    },
    {
      "id_parroquia": 40,
      "id_municipio": 16,
      "parroquia": "CM. CLARINES"
    },
    {
      "id_parroquia": 41,
      "id_municipio": 17,
      "parroquia": "CM. SAN JOSE DE GUANIPA"
    },
    {
      "id_parroquia": 42,
      "id_municipio": 18,
      "parroquia": "PQ. BOCA DE UCHIRE"
    },
    {
      "id_parroquia": 43,
      "id_municipio": 19,
      "parroquia": "CM. SOLEDAD"
    },
    {
      "id_parroquia": 44,
      "id_municipio": 20,
      "parroquia": "CM. SAN MATEO"
    },
    {
      "id_parroquia": 45,
      "id_municipio": 21,
      "parroquia": "CM. PUERTO PIRITU"
    },
    {
      "id_parroquia": 46,
      "id_municipio": 22,
      "parroquia": "PQ. VALLE GUANAPE"
    },
    {
      "id_parroquia": 47,
      "id_municipio": 23,
      "parroquia": "PQ. GUANTA"
    },
    {
      "id_parroquia": 48,
      "id_municipio": 16,
      "parroquia": "PQ. GUANAPE"
    },
    {
      "id_parroquia": 49,
      "id_municipio": 24,
      "parroquia": "PQ. SN DIEGO DE CABRUTICA"
    },
    {
      "id_parroquia": 50,
      "id_municipio": 25,
      "parroquia": "PQ. SANTA ANA"
    },
    {
      "id_parroquia": 51,
      "id_municipio": 9,
      "parroquia": "PQ. EL MORRO"
    },
    {
      "id_parroquia": 52,
      "id_municipio": 26,
      "parroquia": "PQ. EL CHAPARRO"
    },
    {
      "id_parroquia": 53,
      "id_municipio": 8,
      "parroquia": "PQ. NARICUAL"
    },
    {
      "id_parroquia": 54,
      "id_municipio": 8,
      "parroquia": "PQ. CAIGUA"
    },
    {
      "id_parroquia": 55,
      "id_municipio": 23,
      "parroquia": "PQ. CHORRERON"
    },
    {
      "id_parroquia": 56,
      "id_municipio": 15,
      "parroquia": "PQ. ATAPIRIRE"
    },
    {
      "id_parroquia": 57,
      "id_municipio": 27,
      "parroquia": "CM. ARAGUA DE BARCELONA"
    },
    {
      "id_parroquia": 58,
      "id_municipio": 13,
      "parroquia": "PQ. SAN JOAQUIN"
    },
    {
      "id_parroquia": 59,
      "id_municipio": 20,
      "parroquia": "PQ. SANTA INES"
    },
    {
      "id_parroquia": 60,
      "id_municipio": 21,
      "parroquia": "PQ. SAN MIGUEL"
    },
    {
      "id_parroquia": 61,
      "id_municipio": 24,
      "parroquia": "PQ. ZUATA"
    },
    {
      "id_parroquia": 62,
      "id_municipio": 15,
      "parroquia": "PQ. EL PAO"
    },
    {
      "id_parroquia": 63,
      "id_municipio": 14,
      "parroquia": "PQ. SANTA ROSA"
    },
    {
      "id_parroquia": 64,
      "id_municipio": 16,
      "parroquia": "PQ. SABANA DE UCHIRE"
    },
    {
      "id_parroquia": 65,
      "id_municipio": 8,
      "parroquia": "PQ. BERGANTIN"
    },
    {
      "id_parroquia": 66,
      "id_municipio": 28,
      "parroquia": "CM. ONOTO"
    },
    {
      "id_parroquia": 67,
      "id_municipio": 15,
      "parroquia": "PQ. BOCA DEL PAO"
    },
    {
      "id_parroquia": 68,
      "id_municipio": 24,
      "parroquia": "PQ. UVERITO"
    },
    {
      "id_parroquia": 69,
      "id_municipio": 24,
      "parroquia": "CM. MAPIRE"
    },
    {
      "id_parroquia": 70,
      "id_municipio": 20,
      "parroquia": "PQ. EL CARITO"
    },
    {
      "id_parroquia": 71,
      "id_municipio": 24,
      "parroquia": "PQ. SANTA CLARA"
    },
    {
      "id_parroquia": 72,
      "id_municipio": 14,
      "parroquia": "PQ. URICA"
    },
    {
      "id_parroquia": 73,
      "id_municipio": 8,
      "parroquia": "PQ. EL PILAR"
    },
    {
      "id_parroquia": 74,
      "id_municipio": 28,
      "parroquia": "PQ. SAN PABLO"
    },
    {
      "id_parroquia": 75,
      "id_municipio": 19,
      "parroquia": "PQ. MAMO"
    },
    {
      "id_parroquia": 76,
      "id_municipio": 14,
      "parroquia": "PQ. LIBERTADOR"
    },
    {
      "id_parroquia": 77,
      "id_municipio": 29,
      "parroquia": "PQ. SAN FERNANDO"
    },
    {
      "id_parroquia": 78,
      "id_municipio": 30,
      "parroquia": "PQ. ELORZA"
    },
    {
      "id_parroquia": 79,
      "id_municipio": 31,
      "parroquia": "PQ. EL AMPARO"
    },
    {
      "id_parroquia": 80,
      "id_municipio": 31,
      "parroquia": "PQ. GUASDUALITO"
    },
    {
      "id_parroquia": 81,
      "id_municipio": 32,
      "parroquia": "PQ. ACHAGUAS"
    },
    {
      "id_parroquia": 82,
      "id_municipio": 31,
      "parroquia": "PQ. SAN CAMILO"
    },
    {
      "id_parroquia": 83,
      "id_municipio": 33,
      "parroquia": "PQ. MANTECAL"
    },
    {
      "id_parroquia": 84,
      "id_municipio": 34,
      "parroquia": "PQ. SAN JUAN DE PAYARA"
    },
    {
      "id_parroquia": 85,
      "id_municipio": 31,
      "parroquia": "PQ. URDANETA"
    },
    {
      "id_parroquia": 86,
      "id_municipio": 29,
      "parroquia": "PQ. SN RAFAEL DE ATAMAICA"
    },
    {
      "id_parroquia": 87,
      "id_municipio": 35,
      "parroquia": "PQ. BIRUACA"
    },
    {
      "id_parroquia": 88,
      "id_municipio": 33,
      "parroquia": "PQ. BRUZUAL"
    },
    {
      "id_parroquia": 89,
      "id_municipio": 29,
      "parroquia": "PQ. PE\u00d1ALVER"
    },
    {
      "id_parroquia": 90,
      "id_municipio": 34,
      "parroquia": "PQ. CODAZZI"
    },
    {
      "id_parroquia": 91,
      "id_municipio": 30,
      "parroquia": "PQ. LA TRINIDAD"
    },
    {
      "id_parroquia": 92,
      "id_municipio": 34,
      "parroquia": "PQ. CUNAVICHE"
    },
    {
      "id_parroquia": 93,
      "id_municipio": 32,
      "parroquia": "PQ. GUACHARA"
    },
    {
      "id_parroquia": 94,
      "id_municipio": 33,
      "parroquia": "PQ. RINCON HONDO"
    },
    {
      "id_parroquia": 95,
      "id_municipio": 32,
      "parroquia": "PQ. MUCURITAS"
    },
    {
      "id_parroquia": 96,
      "id_municipio": 32,
      "parroquia": "PQ. QUESERAS DEL MEDIO"
    },
    {
      "id_parroquia": 97,
      "id_municipio": 32,
      "parroquia": "PQ. EL YAGUAL"
    },
    {
      "id_parroquia": 98,
      "id_municipio": 32,
      "parroquia": "PQ. APURITO"
    },
    {
      "id_parroquia": 99,
      "id_municipio": 33,
      "parroquia": "PQ. SAN VICENTE"
    },
    {
      "id_parroquia": 100,
      "id_municipio": 36,
      "parroquia": "PQ. MADRE MA DE SAN JOSE"
    },
    {
      "id_parroquia": 101,
      "id_municipio": 37,
      "parroquia": "CM. EL LIMON"
    },
    {
      "id_parroquia": 102,
      "id_municipio": 38,
      "parroquia": "CM. TURMERO"
    },
    {
      "id_parroquia": 103,
      "id_municipio": 38,
      "parroquia": "PQ. SAMAN DE GUERE"
    },
    {
      "id_parroquia": 104,
      "id_municipio": 39,
      "parroquia": "CM. SANTA RITA"
    },
    {
      "id_parroquia": 105,
      "id_municipio": 36,
      "parroquia": "PQ. JOAQUIN CRESPO"
    },
    {
      "id_parroquia": 106,
      "id_municipio": 40,
      "parroquia": "CM. JUAN VICENTE BOLIVAR"
    },
    {
      "id_parroquia": 107,
      "id_municipio": 39,
      "parroquia": "PQ. MONS FELICIANO G"
    },
    {
      "id_parroquia": 108,
      "id_municipio": 36,
      "parroquia": "PQ. LAS DELICIAS"
    },
    {
      "id_parroquia": 109,
      "id_municipio": 41,
      "parroquia": "PQ. SAN MARTIN DE PORRES"
    },
    {
      "id_parroquia": 110,
      "id_municipio": 40,
      "parroquia": "PQ. CASTOR NIEVES RIOS"
    },
    {
      "id_parroquia": 111,
      "id_municipio": 38,
      "parroquia": "PQ. ALFREDO PACHECO M"
    },
    {
      "id_parroquia": 112,
      "id_municipio": 37,
      "parroquia": "PQ. CA\u00d1A DE AZUCAR"
    },
    {
      "id_parroquia": 113,
      "id_municipio": 42,
      "parroquia": "CM. CAGUA"
    },
    {
      "id_parroquia": 114,
      "id_municipio": 43,
      "parroquia": "CM. CAMATAGUA"
    },
    {
      "id_parroquia": 115,
      "id_municipio": 44,
      "parroquia": "CM. EL CONSEJO"
    },
    {
      "id_parroquia": 116,
      "id_municipio": 36,
      "parroquia": "PQ. JOSE CASANOVA GODOY"
    },
    {
      "id_parroquia": 117,
      "id_municipio": 36,
      "parroquia": "PQ. LOS TACARIGUAS"
    },
    {
      "id_parroquia": 118,
      "id_municipio": 40,
      "parroquia": "PQ. LAS GUACAMAYAS"
    },
    {
      "id_parroquia": 119,
      "id_municipio": 38,
      "parroquia": "PQ. AREVALO APONTE"
    },
    {
      "id_parroquia": 120,
      "id_municipio": 45,
      "parroquia": "CM. VILLA DE CURA"
    },
    {
      "id_parroquia": 121,
      "id_municipio": 46,
      "parroquia": "CM. LAS TEJERIAS"
    },
    {
      "id_parroquia": 122,
      "id_municipio": 36,
      "parroquia": "PQ. ANDRES ELOY BLANCO"
    },
    {
      "id_parroquia": 123,
      "id_municipio": 47,
      "parroquia": "CM. SANTA CRUZ"
    },
    {
      "id_parroquia": 124,
      "id_municipio": 45,
      "parroquia": "PQ. MAGDALENO"
    },
    {
      "id_parroquia": 125,
      "id_municipio": 48,
      "parroquia": "CM. SAN SEBASTIAN"
    },
    {
      "id_parroquia": 126,
      "id_municipio": 36,
      "parroquia": "PQ. PEDRO JOSE OVALLES"
    },
    {
      "id_parroquia": 127,
      "id_municipio": 41,
      "parroquia": "CM. PALO NEGRO"
    },
    {
      "id_parroquia": 128,
      "id_municipio": 39,
      "parroquia": "PQ. FRANCISCO DE MIRANDA"
    },
    {
      "id_parroquia": 129,
      "id_municipio": 45,
      "parroquia": "PQ. SAN FRANCISCO DE ASIS"
    },
    {
      "id_parroquia": 130,
      "id_municipio": 45,
      "parroquia": "PQ. VALLES DE TUCUTUNEMO"
    },
    {
      "id_parroquia": 131,
      "id_municipio": 49,
      "parroquia": "CM. SAN CASIMIRO"
    },
    {
      "id_parroquia": 132,
      "id_municipio": 50,
      "parroquia": "PQ. OCUMARE DE LA COSTA"
    },
    {
      "id_parroquia": 133,
      "id_municipio": 43,
      "parroquia": "PQ. CARMEN DE CURA"
    },
    {
      "id_parroquia": 134,
      "id_municipio": 40,
      "parroquia": "PQ. PAO DE ZARATE"
    },
    {
      "id_parroquia": 135,
      "id_municipio": 45,
      "parroquia": "PQ. PQ AUGUSTO MIJARES"
    },
    {
      "id_parroquia": 136,
      "id_municipio": 42,
      "parroquia": "PQ. BELLA VISTA"
    },
    {
      "id_parroquia": 137,
      "id_municipio": 36,
      "parroquia": "PQ. CHORONI"
    },
    {
      "id_parroquia": 138,
      "id_municipio": 51,
      "parroquia": "CM. BARBACOAS"
    },
    {
      "id_parroquia": 139,
      "id_municipio": 51,
      "parroquia": "PQ. TAGUAY"
    },
    {
      "id_parroquia": 140,
      "id_municipio": 52,
      "parroquia": "CM. COLONIA TOVAR"
    },
    {
      "id_parroquia": 141,
      "id_municipio": 51,
      "parroquia": "PQ. SAN FRANCISCO DE CARA"
    },
    {
      "id_parroquia": 142,
      "id_municipio": 53,
      "parroquia": "PQ. TICOPORO"
    },
    {
      "id_parroquia": 143,
      "id_municipio": 54,
      "parroquia": "PQ. RAMON I MENDEZ"
    },
    {
      "id_parroquia": 144,
      "id_municipio": 54,
      "parroquia": "PQ. MANUEL P FAJARDO"
    },
    {
      "id_parroquia": 145,
      "id_municipio": 54,
      "parroquia": "PQ. ALTO BARINAS"
    },
    {
      "id_parroquia": 146,
      "id_municipio": 55,
      "parroquia": "PQ. SANTA BARBARA"
    },
    {
      "id_parroquia": 147,
      "id_municipio": 56,
      "parroquia": "PQ. LOS GUASIMITOS"
    },
    {
      "id_parroquia": 148,
      "id_municipio": 54,
      "parroquia": "PQ. ROMULO BETANCOURT"
    },
    {
      "id_parroquia": 149,
      "id_municipio": 57,
      "parroquia": "PQ. PAEZ"
    },
    {
      "id_parroquia": 150,
      "id_municipio": 8,
      "parroquia": "PQ. BARINITAS"
    },
    {
      "id_parroquia": 151,
      "id_municipio": 57,
      "parroquia": "PQ. CIUDAD BOLIVIA"
    },
    {
      "id_parroquia": 152,
      "id_municipio": 53,
      "parroquia": "PQ. ANDRES BELLO"
    },
    {
      "id_parroquia": 153,
      "id_municipio": 58,
      "parroquia": "PQ. LIBERTAD"
    },
    {
      "id_parroquia": 154,
      "id_municipio": 54,
      "parroquia": "PQ. CORAZON DE JESUS"
    },
    {
      "id_parroquia": 155,
      "id_municipio": 54,
      "parroquia": "PQ. ALFREDO A LARRIVA"
    },
    {
      "id_parroquia": 156,
      "id_municipio": 56,
      "parroquia": "PQ. OBISPOS"
    },
    {
      "id_parroquia": 157,
      "id_municipio": 58,
      "parroquia": "PQ. DOLORES"
    },
    {
      "id_parroquia": 158,
      "id_municipio": 59,
      "parroquia": "PQ. EL CANTON"
    },
    {
      "id_parroquia": 159,
      "id_municipio": 55,
      "parroquia": "PQ. PEDRO BRICE\u00d1O MENDEZ"
    },
    {
      "id_parroquia": 160,
      "id_municipio": 59,
      "parroquia": "PQ. SANTA CRUZ DE GUACAS"
    },
    {
      "id_parroquia": 161,
      "id_municipio": 54,
      "parroquia": "PQ. SANTA LUCIA"
    },
    {
      "id_parroquia": 162,
      "id_municipio": 54,
      "parroquia": "PQ. TORUNOS"
    },
    {
      "id_parroquia": 163,
      "id_municipio": 54,
      "parroquia": "PQ. BARINAS"
    },
    {
      "id_parroquia": 164,
      "id_municipio": 53,
      "parroquia": "PQ. NICOLAS PULIDO"
    },
    {
      "id_parroquia": 165,
      "id_municipio": 60,
      "parroquia": "PQ. BARRANCAS"
    },
    {
      "id_parroquia": 166,
      "id_municipio": 54,
      "parroquia": "PQ. DOMINGA ORTIZ P"
    },
    {
      "id_parroquia": 167,
      "id_municipio": 57,
      "parroquia": "PQ. JOSE FELIX RIBAS"
    },
    {
      "id_parroquia": 168,
      "id_municipio": 58,
      "parroquia": "PQ. SIMON RODRIGUEZ"
    },
    {
      "id_parroquia": 169,
      "id_municipio": 61,
      "parroquia": "PQ. SABANETA"
    },
    {
      "id_parroquia": 170,
      "id_municipio": 54,
      "parroquia": "PQ. JUAN A RODRIGUEZ D"
    },
    {
      "id_parroquia": 171,
      "id_municipio": 62,
      "parroquia": "PQ. CIUDAD DE NUTRIAS"
    },
    {
      "id_parroquia": 172,
      "id_municipio": 55,
      "parroquia": "PQ. JOSE IGNACIO DEL PUMAR"
    },
    {
      "id_parroquia": 173,
      "id_municipio": 58,
      "parroquia": "PQ. PALACIOS FAJARDO"
    },
    {
      "id_parroquia": 174,
      "id_municipio": 55,
      "parroquia": "PQ. RAMON IGNACIO MENDEZ"
    },
    {
      "id_parroquia": 175,
      "id_municipio": 54,
      "parroquia": "PQ. SAN SILVESTRE"
    },
    {
      "id_parroquia": 176,
      "id_municipio": 56,
      "parroquia": "PQ. LA LUZ"
    },
    {
      "id_parroquia": 177,
      "id_municipio": 61,
      "parroquia": "PQ. RODRIGUEZ DOMINGUEZ"
    },
    {
      "id_parroquia": 178,
      "id_municipio": 62,
      "parroquia": "PQ. PUERTO DE NUTRIAS"
    },
    {
      "id_parroquia": 179,
      "id_municipio": 57,
      "parroquia": "PQ. IGNACIO BRICE\u00d1O"
    },
    {
      "id_parroquia": 180,
      "id_municipio": 63,
      "parroquia": "PQ. ARISMENDI"
    },
    {
      "id_parroquia": 181,
      "id_municipio": 56,
      "parroquia": "PQ. EL REAL"
    },
    {
      "id_parroquia": 182,
      "id_municipio": 62,
      "parroquia": "PQ. EL REGALO"
    },
    {
      "id_parroquia": 183,
      "id_municipio": 63,
      "parroquia": "PQ. LA UNION"
    },
    {
      "id_parroquia": 184,
      "id_municipio": 62,
      "parroquia": "PQ. SIMON BOLIVAR"
    },
    {
      "id_parroquia": 185,
      "id_municipio": 64,
      "parroquia": "PQ. UNARE"
    },
    {
      "id_parroquia": 186,
      "id_municipio": 65,
      "parroquia": "PQ. SAN ISIDRO"
    },
    {
      "id_parroquia": 187,
      "id_municipio": 64,
      "parroquia": "PQ. ONCE DE ABRIL"
    },
    {
      "id_parroquia": 188,
      "id_municipio": 64,
      "parroquia": "PQ. VISTA AL SOL"
    },
    {
      "id_parroquia": 189,
      "id_municipio": 64,
      "parroquia": "PQ. UNIVERSIDAD"
    },
    {
      "id_parroquia": 190,
      "id_municipio": 66,
      "parroquia": "CM. EL CALLAO"
    },
    {
      "id_parroquia": 191,
      "id_municipio": 67,
      "parroquia": "CM. SANTA ELENA DE UAIREN"
    },
    {
      "id_parroquia": 192,
      "id_municipio": 64,
      "parroquia": "PQ. DALLA COSTA"
    },
    {
      "id_parroquia": 193,
      "id_municipio": 68,
      "parroquia": "CM. UPATA"
    },
    {
      "id_parroquia": 194,
      "id_municipio": 64,
      "parroquia": "PQ. CACHAMAY"
    },
    {
      "id_parroquia": 195,
      "id_municipio": 69,
      "parroquia": "PQ. AGUA SALADA"
    },
    {
      "id_parroquia": 196,
      "id_municipio": 70,
      "parroquia": "CM. CIUDAD PIAR"
    },
    {
      "id_parroquia": 197,
      "id_municipio": 68,
      "parroquia": "PQ. PEDRO COVA"
    },
    {
      "id_parroquia": 198,
      "id_municipio": 65,
      "parroquia": "CM. TUMEREMO"
    },
    {
      "id_parroquia": 199,
      "id_municipio": 64,
      "parroquia": "PQ. CHIRICA"
    },
    {
      "id_parroquia": 200,
      "id_municipio": 69,
      "parroquia": "PQ. JOSE ANTONIO PAEZ"
    },
    {
      "id_parroquia": 201,
      "id_municipio": 69,
      "parroquia": "PQ. LA SABANITA"
    },
    {
      "id_parroquia": 202,
      "id_municipio": 71,
      "parroquia": "CM. CAICARA DEL ORINOCO"
    },
    {
      "id_parroquia": 203,
      "id_municipio": 72,
      "parroquia": "CM. EL PALMAR"
    },
    {
      "id_parroquia": 204,
      "id_municipio": 69,
      "parroquia": "PQ. VISTA HERMOSA"
    },
    {
      "id_parroquia": 205,
      "id_municipio": 73,
      "parroquia": "CM. GUASIPATI"
    },
    {
      "id_parroquia": 206,
      "id_municipio": 69,
      "parroquia": "PQ. MARHUANTA"
    },
    {
      "id_parroquia": 207,
      "id_municipio": 42,
      "parroquia": "CM. MARIPA"
    },
    {
      "id_parroquia": 208,
      "id_municipio": 70,
      "parroquia": "PQ. SAN FRANCISCO"
    },
    {
      "id_parroquia": 209,
      "id_municipio": 71,
      "parroquia": "PQ. PIJIGUAOS"
    },
    {
      "id_parroquia": 210,
      "id_municipio": 70,
      "parroquia": "PQ. BARCELONETA"
    },
    {
      "id_parroquia": 211,
      "id_municipio": 42,
      "parroquia": "PQ. GUARATARO"
    },
    {
      "id_parroquia": 212,
      "id_municipio": 64,
      "parroquia": "PQ. YOCOIMA"
    },
    {
      "id_parroquia": 213,
      "id_municipio": 64,
      "parroquia": "PQ. POZO VERDE"
    },
    {
      "id_parroquia": 214,
      "id_municipio": 71,
      "parroquia": "PQ. ASCENSION FARRERAS"
    },
    {
      "id_parroquia": 215,
      "id_municipio": 74,
      "parroquia": "PQ. URB SAN DIEGO"
    },
    {
      "id_parroquia": 216,
      "id_municipio": 75,
      "parroquia": "PQ. NAGUANAGUA"
    },
    {
      "id_parroquia": 217,
      "id_municipio": 76,
      "parroquia": "PQ. MIGUEL PE\u00d1A"
    },
    {
      "id_parroquia": 218,
      "id_municipio": 76,
      "parroquia": "PQ. RAFAEL URDANETA"
    },
    {
      "id_parroquia": 219,
      "id_municipio": 77,
      "parroquia": "PQ. GUACARA"
    },
    {
      "id_parroquia": 220,
      "id_municipio": 78,
      "parroquia": "PQ. U LOS GUAYOS"
    },
    {
      "id_parroquia": 221,
      "id_municipio": 41,
      "parroquia": "PQ. U TOCUYITO"
    },
    {
      "id_parroquia": 222,
      "id_municipio": 77,
      "parroquia": "PQ. YAGUA"
    },
    {
      "id_parroquia": 223,
      "id_municipio": 76,
      "parroquia": "PQ. SAN BLAS"
    },
    {
      "id_parroquia": 224,
      "id_municipio": 80,
      "parroquia": "PQ. BEJUMA"
    },
    {
      "id_parroquia": 225,
      "id_municipio": 76,
      "parroquia": "PQ. EL SOCORRO"
    },
    {
      "id_parroquia": 226,
      "id_municipio": 77,
      "parroquia": "PQ. CIUDAD ALIANZA"
    },
    {
      "id_parroquia": 227,
      "id_municipio": 41,
      "parroquia": "PQ. U INDEPENDENCIA"
    },
    {
      "id_parroquia": 228,
      "id_municipio": 81,
      "parroquia": "PQ. GUIGUE"
    },
    {
      "id_parroquia": 229,
      "id_municipio": 82,
      "parroquia": "PQ. AGUAS CALIENTES"
    },
    {
      "id_parroquia": 230,
      "id_municipio": 15,
      "parroquia": "PQ. MIRANDA"
    },
    {
      "id_parroquia": 231,
      "id_municipio": 83,
      "parroquia": "PQ. GOAIGOAZA"
    },
    {
      "id_parroquia": 232,
      "id_municipio": 82,
      "parroquia": "PQ. MARIARA"
    },
    {
      "id_parroquia": 233,
      "id_municipio": 83,
      "parroquia": "PQ. JUAN JOSE FLORES"
    },
    {
      "id_parroquia": 234,
      "id_municipio": 83,
      "parroquia": "PQ. BARTOLOME SALOM"
    },
    {
      "id_parroquia": 235,
      "id_municipio": 81,
      "parroquia": "PQ. TACARIGUA"
    },
    {
      "id_parroquia": 236,
      "id_municipio": 83,
      "parroquia": "PQ. BORBURATA"
    },
    {
      "id_parroquia": 237,
      "id_municipio": 84,
      "parroquia": "PQ. MONTALBAN"
    },
    {
      "id_parroquia": 238,
      "id_municipio": 81,
      "parroquia": "PQ. BELEN"
    },
    {
      "id_parroquia": 239,
      "id_municipio": 83,
      "parroquia": "PQ. FRATERNIDAD"
    },
    {
      "id_parroquia": 240,
      "id_municipio": 83,
      "parroquia": "PQ. DEMOCRACIA"
    },
    {
      "id_parroquia": 241,
      "id_municipio": 85,
      "parroquia": "PQ. MORON"
    },
    {
      "id_parroquia": 242,
      "id_municipio": 83,
      "parroquia": "PQ. UNION"
    },
    {
      "id_parroquia": 243,
      "id_municipio": 80,
      "parroquia": "PQ. CANOABO"
    },
    {
      "id_parroquia": 244,
      "id_municipio": 83,
      "parroquia": "PQ. PATANEMO"
    },
    {
      "id_parroquia": 245,
      "id_municipio": 76,
      "parroquia": "PQ. NEGRO PRIMERO"
    },
    {
      "id_parroquia": 246,
      "id_municipio": 86,
      "parroquia": "PQ. GRL/JEFE JOSE L SILVA"
    },
    {
      "id_parroquia": 247,
      "id_municipio": 87,
      "parroquia": "PQ. TINAQUILLO"
    },
    {
      "id_parroquia": 248,
      "id_municipio": 55,
      "parroquia": "PQ. SAN CARLOS DE AUSTRIA"
    },
    {
      "id_parroquia": 249,
      "id_municipio": 36,
      "parroquia": "PQ. EL BAUL"
    },
    {
      "id_parroquia": 250,
      "id_municipio": 30,
      "parroquia": "PQ. ROMULO GALLEGOS"
    },
    {
      "id_parroquia": 251,
      "id_municipio": 88,
      "parroquia": "PQ. JUAN DE MATA SUAREZ"
    },
    {
      "id_parroquia": 252,
      "id_municipio": 88,
      "parroquia": "PQ. COJEDES"
    },
    {
      "id_parroquia": 253,
      "id_municipio": 89,
      "parroquia": "PQ. LIBERTAD DE COJEDES"
    },
    {
      "id_parroquia": 254,
      "id_municipio": 55,
      "parroquia": "PQ. MANUEL MANRIQUE"
    },
    {
      "id_parroquia": 255,
      "id_municipio": 91,
      "parroquia": "PQ. LA AGUADITA"
    },
    {
      "id_parroquia": 256,
      "id_municipio": 92,
      "parroquia": "PQ. PADRE BARRAL"
    },
    {
      "id_parroquia": 257,
      "id_municipio": 93,
      "parroquia": "PQ. MANUEL PIAR"
    },
    {
      "id_parroquia": 258,
      "id_municipio": 94,
      "parroquia": "PQ. JOSE VIDAL MARCANO"
    },
    {
      "id_parroquia": 259,
      "id_municipio": 94,
      "parroquia": "PQ. MCL. ANTONIO J DE SUCRE"
    },
    {
      "id_parroquia": 260,
      "id_municipio": 95,
      "parroquia": "PQ. PEDERNALES"
    },
    {
      "id_parroquia": 261,
      "id_municipio": 94,
      "parroquia": "PQ. MONS. ARGIMIRO GARCIA"
    },
    {
      "id_parroquia": 262,
      "id_municipio": 92,
      "parroquia": "PQ. CURIAPO"
    },
    {
      "id_parroquia": 263,
      "id_municipio": 94,
      "parroquia": "PQ. LEONARDO RUIZ PINEDA"
    },
    {
      "id_parroquia": 264,
      "id_municipio": 94,
      "parroquia": "PQ. SAN RAFAEL"
    },
    {
      "id_parroquia": 265,
      "id_municipio": 93,
      "parroquia": "PQ. IMATACA"
    },
    {
      "id_parroquia": 266,
      "id_municipio": 93,
      "parroquia": "PQ. JUAN BAUTISTA ARISMEN"
    },
    {
      "id_parroquia": 267,
      "id_municipio": 15,
      "parroquia": "PQ. SAN GABRIEL"
    },
    {
      "id_parroquia": 268,
      "id_municipio": 96,
      "parroquia": "PQ. CARIRUBANA"
    },
    {
      "id_parroquia": 269,
      "id_municipio": 96,
      "parroquia": "PQ. PUNTA CARDON"
    },
    {
      "id_parroquia": 270,
      "id_municipio": 15,
      "parroquia": "PQ. SAN ANTONIO"
    },
    {
      "id_parroquia": 271,
      "id_municipio": 96,
      "parroquia": "PQ. NORTE"
    },
    {
      "id_parroquia": 272,
      "id_municipio": 97,
      "parroquia": "PQ. JUDIBANA"
    },
    {
      "id_parroquia": 273,
      "id_municipio": 45,
      "parroquia": "PQ. PUERTO CUMAREBO"
    },
    {
      "id_parroquia": 274,
      "id_municipio": 98,
      "parroquia": "PQ. LA VELA DE CORO"
    },
    {
      "id_parroquia": 275,
      "id_municipio": 99,
      "parroquia": "PQ. PUEBLO NUEVO"
    },
    {
      "id_parroquia": 276,
      "id_municipio": 100,
      "parroquia": "CM. DABAJURO"
    },
    {
      "id_parroquia": 277,
      "id_municipio": 98,
      "parroquia": "PQ. LAS CALDERAS"
    },
    {
      "id_parroquia": 278,
      "id_municipio": 97,
      "parroquia": "PQ. LOS TAQUES"
    },
    {
      "id_parroquia": 279,
      "id_municipio": 101,
      "parroquia": "PQ. CHURUGUARA"
    },
    {
      "id_parroquia": 280,
      "id_municipio": 102,
      "parroquia": "PQ. CAPATARIDA"
    },
    {
      "id_parroquia": 281,
      "id_municipio": 103,
      "parroquia": "PQ. TUCACAS"
    },
    {
      "id_parroquia": 282,
      "id_municipio": 104,
      "parroquia": "PQ. MENE DE MAUROA"
    },
    {
      "id_parroquia": 283,
      "id_municipio": 99,
      "parroquia": "PQ. MORUY"
    },
    {
      "id_parroquia": 284,
      "id_municipio": 105,
      "parroquia": "PQ. CABURE"
    },
    {
      "id_parroquia": 285,
      "id_municipio": 99,
      "parroquia": "PQ. BUENA VISTA"
    },
    {
      "id_parroquia": 286,
      "id_municipio": 106,
      "parroquia": "CM. YARACAL"
    },
    {
      "id_parroquia": 287,
      "id_municipio": 107,
      "parroquia": "PQ. SAN JUAN DE LOS CAYOS"
    },
    {
      "id_parroquia": 288,
      "id_municipio": 108,
      "parroquia": "PQ. URUMACO"
    },
    {
      "id_parroquia": 289,
      "id_municipio": 103,
      "parroquia": "PQ. BOCA DE AROA"
    },
    {
      "id_parroquia": 290,
      "id_municipio": 109,
      "parroquia": "PQ. CHICHIRIVICHE"
    },
    {
      "id_parroquia": 291,
      "id_municipio": 110,
      "parroquia": "PQ. PEDREGAL"
    },
    {
      "id_parroquia": 292,
      "id_municipio": 107,
      "parroquia": "PQ. CAPADARE"
    },
    {
      "id_parroquia": 293,
      "id_municipio": 99,
      "parroquia": "PQ. ADICORA"
    },
    {
      "id_parroquia": 294,
      "id_municipio": 102,
      "parroquia": "PQ. ZAZARIDA"
    },
    {
      "id_parroquia": 295,
      "id_municipio": 111,
      "parroquia": "CM. TOCOPERO"
    },
    {
      "id_parroquia": 296,
      "id_municipio": 109,
      "parroquia": "PQ. TOCUYO DE LA COSTA"
    },
    {
      "id_parroquia": 297,
      "id_municipio": 112,
      "parroquia": "CM. MIRIMIRE"
    },
    {
      "id_parroquia": 298,
      "id_municipio": 101,
      "parroquia": "PQ. MAPARARI"
    },
    {
      "id_parroquia": 299,
      "id_municipio": 109,
      "parroquia": "PQ. BOCA DE TOCUYO"
    },
    {
      "id_parroquia": 300,
      "id_municipio": 99,
      "parroquia": "PQ. EL HATO"
    },
    {
      "id_parroquia": 301,
      "id_municipio": 113,
      "parroquia": "PQ. STA.CRUZ DE BUCARAL"
    },
    {
      "id_parroquia": 302,
      "id_municipio": 114,
      "parroquia": "PQ. ARAURIMA"
    },
    {
      "id_parroquia": 303,
      "id_municipio": 8,
      "parroquia": "PQ. ARACUA"
    },
    {
      "id_parroquia": 304,
      "id_municipio": 8,
      "parroquia": "PQ. SAN LUIS"
    },
    {
      "id_parroquia": 305,
      "id_municipio": 115,
      "parroquia": "CM. PALMASOLA"
    },
    {
      "id_parroquia": 306,
      "id_municipio": 99,
      "parroquia": "PQ. ADAURE"
    },
    {
      "id_parroquia": 307,
      "id_municipio": 116,
      "parroquia": "PQ. VALLE DE LA PASCUA"
    },
    {
      "id_parroquia": 308,
      "id_municipio": 117,
      "parroquia": "PQ. GUAYABAL"
    },
    {
      "id_parroquia": 309,
      "id_municipio": 118,
      "parroquia": "PQ. ZARAZA"
    },
    {
      "id_parroquia": 310,
      "id_municipio": 15,
      "parroquia": "PQ. CALABOZO"
    },
    {
      "id_parroquia": 311,
      "id_municipio": 119,
      "parroquia": "PQ. SAN JUAN DE LOS MORROS"
    },
    {
      "id_parroquia": 312,
      "id_municipio": 24,
      "parroquia": "PQ. ALTAGRACIA DE ORITUCO"
    },
    {
      "id_parroquia": 313,
      "id_municipio": 120,
      "parroquia": "PQ. LAS MERCEDES"
    },
    {
      "id_parroquia": 314,
      "id_municipio": 120,
      "parroquia": "PQ. CABRUTA"
    },
    {
      "id_parroquia": 315,
      "id_municipio": 122,
      "parroquia": "PQ. CAMAGUAN"
    },
    {
      "id_parroquia": 316,
      "id_municipio": 123,
      "parroquia": "PQ. EL SOMBRERO"
    },
    {
      "id_parroquia": 317,
      "id_municipio": 122,
      "parroquia": "PQ. PUERTO MIRANDA"
    },
    {
      "id_parroquia": 318,
      "id_municipio": 124,
      "parroquia": "PQ. ORTIZ"
    },
    {
      "id_parroquia": 319,
      "id_municipio": 116,
      "parroquia": "PQ. ESPINO"
    },
    {
      "id_parroquia": 320,
      "id_municipio": 125,
      "parroquia": "PQ. CHAGUARAMAS"
    },
    {
      "id_parroquia": 321,
      "id_municipio": 126,
      "parroquia": "PQ. SAN JOSE DE GUARIBE"
    },
    {
      "id_parroquia": 322,
      "id_municipio": 117,
      "parroquia": "PQ. CAZORLA"
    },
    {
      "id_parroquia": 323,
      "id_municipio": 24,
      "parroquia": "PQ. SAN RAFAEL DE ORITUCO"
    },
    {
      "id_parroquia": 324,
      "id_municipio": 24,
      "parroquia": "PQ. LEZAMA"
    },
    {
      "id_parroquia": 325,
      "id_municipio": 124,
      "parroquia": "PQ. SAN JOSE DE TIZNADOS"
    },
    {
      "id_parroquia": 326,
      "id_municipio": 127,
      "parroquia": "PQ. TUCUPIDO"
    },
    {
      "id_parroquia": 327,
      "id_municipio": 128,
      "parroquia": "PQ. SANTA MARIA DE IPIRE"
    },
    {
      "id_parroquia": 328,
      "id_municipio": 24,
      "parroquia": "PQ. PASO REAL DE MACAIRA"
    },
    {
      "id_parroquia": 329,
      "id_municipio": 15,
      "parroquia": "PQ. GUARDATINAJAS"
    },
    {
      "id_parroquia": 330,
      "id_municipio": 15,
      "parroquia": "PQ. EL RASTRO"
    },
    {
      "id_parroquia": 331,
      "id_municipio": 127,
      "parroquia": "PQ. SAN RAFAEL DE LAYA"
    },
    {
      "id_parroquia": 332,
      "id_municipio": 118,
      "parroquia": "PQ. SAN JOSE DE UNARE"
    },
    {
      "id_parroquia": 333,
      "id_municipio": 24,
      "parroquia": "PQ. SAN FCO DE MACAIRA"
    },
    {
      "id_parroquia": 334,
      "id_municipio": 119,
      "parroquia": "PQ. CANTAGALLO"
    },
    {
      "id_parroquia": 335,
      "id_municipio": 120,
      "parroquia": "PQ. STA RITA DE MANAPIRE"
    },
    {
      "id_parroquia": 336,
      "id_municipio": 124,
      "parroquia": "PQ. SAN FCO. DE TIZNADOS"
    },
    {
      "id_parroquia": 337,
      "id_municipio": 129,
      "parroquia": "PQ. URIMARE"
    },
    {
      "id_parroquia": 338,
      "id_municipio": 129,
      "parroquia": "PQ. LA GUAIRA"
    },
    {
      "id_parroquia": 339,
      "id_municipio": 129,
      "parroquia": "PQ. CARABALLEDA"
    },
    {
      "id_parroquia": 340,
      "id_municipio": 129,
      "parroquia": "PQ. CATIA LA MAR"
    },
    {
      "id_parroquia": 341,
      "id_municipio": 129,
      "parroquia": "PQ. CARLOS SOUBLETTE"
    },
    {
      "id_parroquia": 342,
      "id_municipio": 129,
      "parroquia": "PQ. MACUTO"
    },
    {
      "id_parroquia": 343,
      "id_municipio": 129,
      "parroquia": "PQ. MAIQUETIA"
    },
    {
      "id_parroquia": 344,
      "id_municipio": 129,
      "parroquia": "PQ. NAIGUATA"
    },
    {
      "id_parroquia": 345,
      "id_municipio": 129,
      "parroquia": "PQ. CARAYACA"
    },
    {
      "id_parroquia": 346,
      "id_municipio": 129,
      "parroquia": "PQ. EL JUNKO"
    },
    {
      "id_parroquia": 347,
      "id_municipio": 130,
      "parroquia": "PQ. TAMACA"
    },
    {
      "id_parroquia": 348,
      "id_municipio": 131,
      "parroquia": "PQ. TRINIDAD SAMUEL"
    },
    {
      "id_parroquia": 349,
      "id_municipio": 130,
      "parroquia": "PQ. GUERRERA ANA SOTO"
    },
    {
      "id_parroquia": 350,
      "id_municipio": 132,
      "parroquia": "PQ. AGUA VIVA"
    },
    {
      "id_parroquia": 351,
      "id_municipio": 130,
      "parroquia": "PQ. LA CONCEPCION"
    },
    {
      "id_parroquia": 352,
      "id_municipio": 132,
      "parroquia": "PQ. JOSE G. BASTIDAS"
    },
    {
      "id_parroquia": 353,
      "id_municipio": 133,
      "parroquia": "PQ. JUAN B RODRIGUEZ"
    },
    {
      "id_parroquia": 354,
      "id_municipio": 131,
      "parroquia": "PQ. EL BLANCO"
    },
    {
      "id_parroquia": 355,
      "id_municipio": 130,
      "parroquia": "PQ. EL CUJI"
    },
    {
      "id_parroquia": 356,
      "id_municipio": 134,
      "parroquia": "PQ. FREITEZ"
    },
    {
      "id_parroquia": 357,
      "id_municipio": 132,
      "parroquia": "PQ. CABUDARE"
    },
    {
      "id_parroquia": 358,
      "id_municipio": 51,
      "parroquia": "PQ. SIQUISIQUE"
    },
    {
      "id_parroquia": 359,
      "id_municipio": 135,
      "parroquia": "PQ. BOLIVAR"
    },
    {
      "id_parroquia": 360,
      "id_municipio": 51,
      "parroquia": "PQ. MOROTURO"
    },
    {
      "id_parroquia": 361,
      "id_municipio": 130,
      "parroquia": "PQ. JUAREZ"
    },
    {
      "id_parroquia": 362,
      "id_municipio": 136,
      "parroquia": "PQ. GUSTAVO VEGAS LEON"
    },
    {
      "id_parroquia": 363,
      "id_municipio": 130,
      "parroquia": "PQ. AGUEDO F. ALVARADO"
    },
    {
      "id_parroquia": 364,
      "id_municipio": 137,
      "parroquia": "PQ. PIO TAMAYO"
    },
    {
      "id_parroquia": 365,
      "id_municipio": 133,
      "parroquia": "PQ. CRNEL. MARIANO PERAZA"
    },
    {
      "id_parroquia": 366,
      "id_municipio": 131,
      "parroquia": "PQ. CHIQUINQUIRA"
    },
    {
      "id_parroquia": 367,
      "id_municipio": 134,
      "parroquia": "PQ. JOSE MARIA BLANCO"
    },
    {
      "id_parroquia": 368,
      "id_municipio": 135,
      "parroquia": "PQ. HUMOCARO BAJO"
    },
    {
      "id_parroquia": 369,
      "id_municipio": 133,
      "parroquia": "PQ. DIEGO DE LOZADA"
    },
    {
      "id_parroquia": 370,
      "id_municipio": 136,
      "parroquia": "PQ. BURIA"
    },
    {
      "id_parroquia": 371,
      "id_municipio": 135,
      "parroquia": "PQ. HUMOCARO ALTO"
    },
    {
      "id_parroquia": 372,
      "id_municipio": 131,
      "parroquia": "PQ. CECILIO ZUBILLAGA"
    },
    {
      "id_parroquia": 373,
      "id_municipio": 131,
      "parroquia": "PQ. MONTA\u00d1A VERDE"
    },
    {
      "id_parroquia": 374,
      "id_municipio": 131,
      "parroquia": "PQ. ESPINOZA LOS MONTEROS"
    },
    {
      "id_parroquia": 375,
      "id_municipio": 135,
      "parroquia": "PQ. GUARICO"
    },
    {
      "id_parroquia": 376,
      "id_municipio": 131,
      "parroquia": "PQ. CAMACARO"
    },
    {
      "id_parroquia": 377,
      "id_municipio": 136,
      "parroquia": "PQ. SARARE"
    },
    {
      "id_parroquia": 378,
      "id_municipio": 135,
      "parroquia": "PQ. HILARIO LUNA Y LUNA"
    },
    {
      "id_parroquia": 379,
      "id_municipio": 131,
      "parroquia": "PQ. ANTONIO DIAZ"
    },
    {
      "id_parroquia": 380,
      "id_municipio": 133,
      "parroquia": "PQ. TINTORERO"
    },
    {
      "id_parroquia": 381,
      "id_municipio": 133,
      "parroquia": "PQ. JOSE BERNARDO DORANTE"
    },
    {
      "id_parroquia": 382,
      "id_municipio": 133,
      "parroquia": "PQ. CUARA"
    },
    {
      "id_parroquia": 383,
      "id_municipio": 131,
      "parroquia": "PQ. MONTES DE OCA"
    },
    {
      "id_parroquia": 384,
      "id_municipio": 131,
      "parroquia": "PQ. CASTA\u00d1EDA"
    },
    {
      "id_parroquia": 385,
      "id_municipio": 51,
      "parroquia": "PQ. XAGUAS"
    },
    {
      "id_parroquia": 386,
      "id_municipio": 41,
      "parroquia": "PQ. JACINTO PLAZA"
    },
    {
      "id_parroquia": 387,
      "id_municipio": 138,
      "parroquia": "PQ. PRESIDENTE PAEZ"
    },
    {
      "id_parroquia": 388,
      "id_municipio": 41,
      "parroquia": "PQ. OSUNA RODRIGUEZ"
    },
    {
      "id_parroquia": 389,
      "id_municipio": 52,
      "parroquia": "PQ. EL LLANO"
    },
    {
      "id_parroquia": 390,
      "id_municipio": 139,
      "parroquia": "CM. NUEVA BOLIVIA"
    },
    {
      "id_parroquia": 391,
      "id_municipio": 138,
      "parroquia": "PQ. PTE. ROMULO GALLEGOS"
    },
    {
      "id_parroquia": 392,
      "id_municipio": 140,
      "parroquia": "PQ. MATRIZ"
    },
    {
      "id_parroquia": 393,
      "id_municipio": 42,
      "parroquia": "CM. LAGUNILLAS"
    },
    {
      "id_parroquia": 394,
      "id_municipio": 41,
      "parroquia": "PQ. LASSO DE LA VEGA"
    },
    {
      "id_parroquia": 395,
      "id_municipio": 52,
      "parroquia": "PQ. TOVAR"
    },
    {
      "id_parroquia": 396,
      "id_municipio": 138,
      "parroquia": "PQ. PRESIDENTE BETANCOURT"
    },
    {
      "id_parroquia": 397,
      "id_municipio": 41,
      "parroquia": "PQ. ANTONIO SPINETTI DINI"
    },
    {
      "id_parroquia": 398,
      "id_municipio": 41,
      "parroquia": "PQ. DOMINGO PE\u00d1A"
    },
    {
      "id_parroquia": 399,
      "id_municipio": 141,
      "parroquia": "CM. TABAY"
    },
    {
      "id_parroquia": 400,
      "id_municipio": 142,
      "parroquia": "CM. TUCANI"
    },
    {
      "id_parroquia": 401,
      "id_municipio": 41,
      "parroquia": "PQ. MARIANO PICON SALAS"
    },
    {
      "id_parroquia": 402,
      "id_municipio": 41,
      "parroquia": "PQ. ARIAS"
    },
    {
      "id_parroquia": 403,
      "id_municipio": 138,
      "parroquia": "PQ. PULIDO MENDEZ"
    },
    {
      "id_parroquia": 404,
      "id_municipio": 140,
      "parroquia": "PQ. FERNANDEZ PE\u00d1A"
    },
    {
      "id_parroquia": 405,
      "id_municipio": 41,
      "parroquia": "PQ. MILLA"
    },
    {
      "id_parroquia": 406,
      "id_municipio": 143,
      "parroquia": "CM. BAILADORES"
    },
    {
      "id_parroquia": 407,
      "id_municipio": 41,
      "parroquia": "PQ. JUAN RODRIGUEZ SUAREZ"
    },
    {
      "id_parroquia": 408,
      "id_municipio": 138,
      "parroquia": "PQ. HECTOR AMABLE MORA"
    },
    {
      "id_parroquia": 409,
      "id_municipio": 144,
      "parroquia": "CM. SANTO DOMINGO"
    },
    {
      "id_parroquia": 410,
      "id_municipio": 15,
      "parroquia": "CM. TIMOTES"
    },
    {
      "id_parroquia": 411,
      "id_municipio": 143,
      "parroquia": "PQ. GERONIMO MALDONADO"
    },
    {
      "id_parroquia": 412,
      "id_municipio": 41,
      "parroquia": "PQ. CARACCIOLO PARRA P"
    },
    {
      "id_parroquia": 413,
      "id_municipio": 145,
      "parroquia": "CM. PUEBLO LLANO"
    },
    {
      "id_parroquia": 414,
      "id_municipio": 41,
      "parroquia": "PQ. SAGRARIO"
    },
    {
      "id_parroquia": 415,
      "id_municipio": 42,
      "parroquia": "PQ. CHIGUARA"
    },
    {
      "id_parroquia": 416,
      "id_municipio": 146,
      "parroquia": "CM. LA AZULITA"
    },
    {
      "id_parroquia": 417,
      "id_municipio": 147,
      "parroquia": "CM. MUCUCHIES"
    },
    {
      "id_parroquia": 418,
      "id_municipio": 148,
      "parroquia": "PQ. ELOY PAREDES"
    },
    {
      "id_parroquia": 419,
      "id_municipio": 41,
      "parroquia": "PQ. GONZALO PICON FEBRES"
    },
    {
      "id_parroquia": 420,
      "id_municipio": 148,
      "parroquia": "CM. STA ELENA DE ARENALES"
    },
    {
      "id_parroquia": 421,
      "id_municipio": 139,
      "parroquia": "PQ. INDEPENDENCIA"
    },
    {
      "id_parroquia": 422,
      "id_municipio": 149,
      "parroquia": "CM. STA CRUZ DE MORA"
    },
    {
      "id_parroquia": 423,
      "id_municipio": 142,
      "parroquia": "PQ. FLORENCIO RAMIREZ"
    },
    {
      "id_parroquia": 424,
      "id_municipio": 150,
      "parroquia": "CM. STA MARIA DE CAPARO"
    },
    {
      "id_parroquia": 425,
      "id_municipio": 151,
      "parroquia": "CM. ARICAGUA"
    },
    {
      "id_parroquia": 426,
      "id_municipio": 152,
      "parroquia": "CM. ARAPUEY"
    },
    {
      "id_parroquia": 427,
      "id_municipio": 153,
      "parroquia": "CM. TORONDOY"
    },
    {
      "id_parroquia": 428,
      "id_municipio": 138,
      "parroquia": "PQ. GABRIEL PICON G."
    },
    {
      "id_parroquia": 429,
      "id_municipio": 149,
      "parroquia": "PQ. MESA BOLIVAR"
    },
    {
      "id_parroquia": 430,
      "id_municipio": 147,
      "parroquia": "PQ. MUCURUBA"
    },
    {
      "id_parroquia": 431,
      "id_municipio": 148,
      "parroquia": "PQ. SAN RAFAEL DE A."
    },
    {
      "id_parroquia": 432,
      "id_municipio": 154,
      "parroquia": "CM. CANAGUA"
    },
    {
      "id_parroquia": 433,
      "id_municipio": 155,
      "parroquia": "CM. ZEA"
    },
    {
      "id_parroquia": 434,
      "id_municipio": 155,
      "parroquia": "PQ. CA\u00d1O EL TIGRE"
    },
    {
      "id_parroquia": 435,
      "id_municipio": 140,
      "parroquia": "PQ. LA MESA"
    },
    {
      "id_parroquia": 436,
      "id_municipio": 147,
      "parroquia": "PQ. LA TOMA"
    },
    {
      "id_parroquia": 437,
      "id_municipio": 138,
      "parroquia": "PQ. JOSE NUCETE SARDI"
    },
    {
      "id_parroquia": 438,
      "id_municipio": 144,
      "parroquia": "PQ. LAS PIEDRAS"
    },
    {
      "id_parroquia": 439,
      "id_municipio": 154,
      "parroquia": "PQ. EL MOLINO"
    },
    {
      "id_parroquia": 440,
      "id_municipio": 139,
      "parroquia": "PQ. MARIA C PALACIOS"
    },
    {
      "id_parroquia": 441,
      "id_municipio": 156,
      "parroquia": "CM. GUARAQUE"
    },
    {
      "id_parroquia": 442,
      "id_municipio": 157,
      "parroquia": "PQ. GUARENAS"
    },
    {
      "id_parroquia": 443,
      "id_municipio": 158,
      "parroquia": "PQ. BARUTA"
    },
    {
      "id_parroquia": 444,
      "id_municipio": 42,
      "parroquia": "PQ. PETARE"
    },
    {
      "id_parroquia": 445,
      "id_municipio": 42,
      "parroquia": "PQ. LEONCIO MARTINEZ"
    },
    {
      "id_parroquia": 446,
      "id_municipio": 159,
      "parroquia": "PQ. EL HATILLO"
    },
    {
      "id_parroquia": 447,
      "id_municipio": 158,
      "parroquia": "PQ. LAS MINAS DE BARUTA"
    },
    {
      "id_parroquia": 448,
      "id_municipio": 160,
      "parroquia": "PQ. CHARALLAVE"
    },
    {
      "id_parroquia": 449,
      "id_municipio": 161,
      "parroquia": "PQ. CHACAO"
    },
    {
      "id_parroquia": 450,
      "id_municipio": 45,
      "parroquia": "PQ. GUATIRE"
    },
    {
      "id_parroquia": 451,
      "id_municipio": 158,
      "parroquia": "PQ. EL CAFETAL"
    },
    {
      "id_parroquia": 452,
      "id_municipio": 19,
      "parroquia": "PQ. EL CARTANAL"
    },
    {
      "id_parroquia": 453,
      "id_municipio": 163,
      "parroquia": "PQ. SAN ANTONIO LOS ALTOS"
    },
    {
      "id_parroquia": 454,
      "id_municipio": 162,
      "parroquia": "PQ. OCUMARE DEL TUY"
    },
    {
      "id_parroquia": 455,
      "id_municipio": 165,
      "parroquia": "PQ. LOS TEQUES"
    },
    {
      "id_parroquia": 456,
      "id_municipio": 42,
      "parroquia": "PQ. CAUCAGUITA"
    },
    {
      "id_parroquia": 457,
      "id_municipio": 51,
      "parroquia": "PQ. CUA"
    },
    {
      "id_parroquia": 458,
      "id_municipio": 166,
      "parroquia": "PQ. HIGUEROTE"
    },
    {
      "id_parroquia": 459,
      "id_municipio": 42,
      "parroquia": "PQ. LA DOLORITA"
    },
    {
      "id_parroquia": 460,
      "id_municipio": 42,
      "parroquia": "PQ. FILAS DE MARICHE"
    },
    {
      "id_parroquia": 461,
      "id_municipio": 167,
      "parroquia": "PQ. MAMPORAL"
    },
    {
      "id_parroquia": 462,
      "id_municipio": 19,
      "parroquia": "PQ. STA TERESA DEL TUY"
    },
    {
      "id_parroquia": 463,
      "id_municipio": 168,
      "parroquia": "PQ. CARRIZAL"
    },
    {
      "id_parroquia": 464,
      "id_municipio": 169,
      "parroquia": "PQ. CAUCAGUA"
    },
    {
      "id_parroquia": 465,
      "id_municipio": 165,
      "parroquia": "PQ. CECILIO ACOSTA"
    },
    {
      "id_parroquia": 466,
      "id_municipio": 51,
      "parroquia": "PQ. NUEVA CUA"
    },
    {
      "id_parroquia": 467,
      "id_municipio": 170,
      "parroquia": "PQ. SAN FCO DE YARE"
    },
    {
      "id_parroquia": 468,
      "id_municipio": 169,
      "parroquia": "PQ. MARIZAPA"
    },
    {
      "id_parroquia": 469,
      "id_municipio": 160,
      "parroquia": "PQ. LAS BRISAS"
    },
    {
      "id_parroquia": 470,
      "id_municipio": 31,
      "parroquia": "PQ. RIO CHICO"
    },
    {
      "id_parroquia": 471,
      "id_municipio": 146,
      "parroquia": "PQ. SAN JOSE DE BARLOVENTO"
    },
    {
      "id_parroquia": 472,
      "id_municipio": 166,
      "parroquia": "PQ. CURIEPE"
    },
    {
      "id_parroquia": 473,
      "id_municipio": 165,
      "parroquia": "PQ. PARACOTOS"
    },
    {
      "id_parroquia": 474,
      "id_municipio": 171,
      "parroquia": "PQ. CUPIRA"
    },
    {
      "id_parroquia": 475,
      "id_municipio": 31,
      "parroquia": "PQ. EL GUAPO"
    },
    {
      "id_parroquia": 476,
      "id_municipio": 165,
      "parroquia": "PQ. TACATA"
    },
    {
      "id_parroquia": 477,
      "id_municipio": 165,
      "parroquia": "PQ. EL JARILLO"
    },
    {
      "id_parroquia": 478,
      "id_municipio": 169,
      "parroquia": "PQ. PANAQUIRE"
    },
    {
      "id_parroquia": 479,
      "id_municipio": 31,
      "parroquia": "PQ. SN FERNANDO DEL GUAPO"
    },
    {
      "id_parroquia": 480,
      "id_municipio": 170,
      "parroquia": "PQ. S ANTONIO DE YARE"
    },
    {
      "id_parroquia": 481,
      "id_municipio": 169,
      "parroquia": "PQ. ARAGUITA"
    },
    {
      "id_parroquia": 482,
      "id_municipio": 165,
      "parroquia": "PQ. ALTAGRACIA DE LA M"
    },
    {
      "id_parroquia": 483,
      "id_municipio": 31,
      "parroquia": "PQ. TACARIGUA DE LA LAGUNA"
    },
    {
      "id_parroquia": 484,
      "id_municipio": 169,
      "parroquia": "PQ. CAPAYA"
    },
    {
      "id_parroquia": 485,
      "id_municipio": 169,
      "parroquia": "PQ. EL CAFE"
    },
    {
      "id_parroquia": 486,
      "id_municipio": 172,
      "parroquia": "PQ. LAS COCUIZAS"
    },
    {
      "id_parroquia": 487,
      "id_municipio": 172,
      "parroquia": "PQ. BOQUERON"
    },
    {
      "id_parroquia": 488,
      "id_municipio": 41,
      "parroquia": "CM. TEMBLADOR"
    },
    {
      "id_parroquia": 489,
      "id_municipio": 172,
      "parroquia": "PQ. SANTA CRUZ"
    },
    {
      "id_parroquia": 490,
      "id_municipio": 172,
      "parroquia": "PQ. ALTOS DE LOS GODOS"
    },
    {
      "id_parroquia": 491,
      "id_municipio": 71,
      "parroquia": "CM. CAICARA"
    },
    {
      "id_parroquia": 492,
      "id_municipio": 172,
      "parroquia": "PQ. SAN SIMON"
    },
    {
      "id_parroquia": 493,
      "id_municipio": 68,
      "parroquia": "PQ. LA TOSCANA"
    },
    {
      "id_parroquia": 494,
      "id_municipio": 173,
      "parroquia": "CM. URACOA"
    },
    {
      "id_parroquia": 495,
      "id_municipio": 11,
      "parroquia": "CM. BARRANCAS"
    },
    {
      "id_parroquia": 496,
      "id_municipio": 172,
      "parroquia": "PQ. JUSEPIN"
    },
    {
      "id_parroquia": 497,
      "id_municipio": 174,
      "parroquia": "CM. CARIPE"
    },
    {
      "id_parroquia": 498,
      "id_municipio": 55,
      "parroquia": "CM. PUNTA DE MATA"
    },
    {
      "id_parroquia": 499,
      "id_municipio": 11,
      "parroquia": "PQ. LOS BARRANCOS DE FAJARDO"
    },
    {
      "id_parroquia": 500,
      "id_municipio": 8,
      "parroquia": "CM. CARIPITO"
    },
    {
      "id_parroquia": 501,
      "id_municipio": 68,
      "parroquia": "PQ. CHAGUARAMAL"
    },
    {
      "id_parroquia": 502,
      "id_municipio": 71,
      "parroquia": "PQ. VIENTO FRESCO"
    },
    {
      "id_parroquia": 503,
      "id_municipio": 172,
      "parroquia": "PQ. EL FURRIAL"
    },
    {
      "id_parroquia": 504,
      "id_municipio": 175,
      "parroquia": "PQ. CACHIPO"
    },
    {
      "id_parroquia": 505,
      "id_municipio": 174,
      "parroquia": "PQ. EL GUACHARO"
    },
    {
      "id_parroquia": 506,
      "id_municipio": 55,
      "parroquia": "PQ. EL TEJERO"
    },
    {
      "id_parroquia": 507,
      "id_municipio": 172,
      "parroquia": "PQ. EL COROZO"
    },
    {
      "id_parroquia": 508,
      "id_municipio": 68,
      "parroquia": "CM. ARAGUA"
    },
    {
      "id_parroquia": 509,
      "id_municipio": 176,
      "parroquia": "CM. SANTA BARBARA"
    },
    {
      "id_parroquia": 510,
      "id_municipio": 172,
      "parroquia": "PQ. LA PICA"
    },
    {
      "id_parroquia": 511,
      "id_municipio": 107,
      "parroquia": "CM. SAN ANTONIO"
    },
    {
      "id_parroquia": 512,
      "id_municipio": 177,
      "parroquia": "CM. AGUASAY"
    },
    {
      "id_parroquia": 513,
      "id_municipio": 71,
      "parroquia": "PQ. SAN FELIX"
    },
    {
      "id_parroquia": 514,
      "id_municipio": 175,
      "parroquia": "CM. QUIRIQUIRE"
    },
    {
      "id_parroquia": 515,
      "id_municipio": 172,
      "parroquia": "CM. MATURIN"
    },
    {
      "id_parroquia": 516,
      "id_municipio": 174,
      "parroquia": "PQ. TERESEN"
    },
    {
      "id_parroquia": 517,
      "id_municipio": 174,
      "parroquia": "PQ. SABANA DE PIEDRA"
    },
    {
      "id_parroquia": 518,
      "id_municipio": 68,
      "parroquia": "PQ. TAGUAYA"
    },
    {
      "id_parroquia": 519,
      "id_municipio": 68,
      "parroquia": "PQ. EL PINTO"
    },
    {
      "id_parroquia": 520,
      "id_municipio": 178,
      "parroquia": "PQ. AGUIRRE"
    },
    {
      "id_parroquia": 521,
      "id_municipio": 179,
      "parroquia": "CM. PORLAMAR"
    },
    {
      "id_parroquia": 522,
      "id_municipio": 178,
      "parroquia": "CM. PAMPATAR"
    },
    {
      "id_parroquia": 523,
      "id_municipio": 180,
      "parroquia": "CM. VALLE ESP SANTO"
    },
    {
      "id_parroquia": 524,
      "id_municipio": 181,
      "parroquia": "CM. LA PLAZA DE PARAGUACHI"
    },
    {
      "id_parroquia": 525,
      "id_municipio": 180,
      "parroquia": "PQ. FRANCISCO FAJARDO"
    },
    {
      "id_parroquia": 526,
      "id_municipio": 182,
      "parroquia": "CM. SAN JUAN BAUTISTA"
    },
    {
      "id_parroquia": 527,
      "id_municipio": 183,
      "parroquia": "CM. BOCA DEL RIO"
    },
    {
      "id_parroquia": 528,
      "id_municipio": 63,
      "parroquia": "CM. LA ASUNCION"
    },
    {
      "id_parroquia": 529,
      "id_municipio": 184,
      "parroquia": "CM. PUNTA DE PIEDRAS"
    },
    {
      "id_parroquia": 530,
      "id_municipio": 185,
      "parroquia": "CM. SANTA ANA"
    },
    {
      "id_parroquia": 531,
      "id_municipio": 186,
      "parroquia": "PQ. ADRIAN"
    },
    {
      "id_parroquia": 532,
      "id_municipio": 186,
      "parroquia": "CM. JUAN GRIEGO"
    },
    {
      "id_parroquia": 533,
      "id_municipio": 182,
      "parroquia": "PQ. ZABALA"
    },
    {
      "id_parroquia": 534,
      "id_municipio": 184,
      "parroquia": "PQ. LOS BARALES"
    },
    {
      "id_parroquia": 535,
      "id_municipio": 185,
      "parroquia": "PQ. MATASIETE"
    },
    {
      "id_parroquia": 536,
      "id_municipio": 185,
      "parroquia": "PQ. GUEVARA"
    },
    {
      "id_parroquia": 537,
      "id_municipio": 187,
      "parroquia": "CM. SAN PEDRO DE COCHE"
    },
    {
      "id_parroquia": 538,
      "id_municipio": 188,
      "parroquia": "CM. ARAURE"
    },
    {
      "id_parroquia": 539,
      "id_municipio": 31,
      "parroquia": "CM. ACARIGUA"
    },
    {
      "id_parroquia": 540,
      "id_municipio": 189,
      "parroquia": "CM. VILLA BRUZUAL"
    },
    {
      "id_parroquia": 541,
      "id_municipio": 31,
      "parroquia": "PQ. PAYARA"
    },
    {
      "id_parroquia": 542,
      "id_municipio": 190,
      "parroquia": "CM. GUANARE"
    },
    {
      "id_parroquia": 543,
      "id_municipio": 42,
      "parroquia": "CM. BISCUCUY"
    },
    {
      "id_parroquia": 544,
      "id_municipio": 188,
      "parroquia": "PQ. RIO ACARIGUA"
    },
    {
      "id_parroquia": 545,
      "id_municipio": 191,
      "parroquia": "CM. BOCONOITO"
    },
    {
      "id_parroquia": 546,
      "id_municipio": 192,
      "parroquia": "CM. PIRITU"
    },
    {
      "id_parroquia": 547,
      "id_municipio": 189,
      "parroquia": "PQ. CANELONES"
    },
    {
      "id_parroquia": 548,
      "id_municipio": 193,
      "parroquia": "CM. GUANARITO"
    },
    {
      "id_parroquia": 549,
      "id_municipio": 194,
      "parroquia": "CM. AGUA BLANCA"
    },
    {
      "id_parroquia": 550,
      "id_municipio": 195,
      "parroquia": "CM. CHABASQUEN"
    },
    {
      "id_parroquia": 551,
      "id_municipio": 42,
      "parroquia": "PQ. UVENCIO A VELASQUEZ"
    },
    {
      "id_parroquia": 552,
      "id_municipio": 196,
      "parroquia": "CM. OSPINO"
    },
    {
      "id_parroquia": 553,
      "id_municipio": 197,
      "parroquia": "CM. SAN RAFAEL DE ONOTO"
    },
    {
      "id_parroquia": 554,
      "id_municipio": 190,
      "parroquia": "PQ. SAN JUAN GUANAGUANARE"
    },
    {
      "id_parroquia": 555,
      "id_municipio": 198,
      "parroquia": "CM. EL PLAYON"
    },
    {
      "id_parroquia": 556,
      "id_municipio": 190,
      "parroquia": "PQ. VIRGEN DE LA COROMOTO"
    },
    {
      "id_parroquia": 557,
      "id_municipio": 31,
      "parroquia": "PQ. PIMPINELA"
    },
    {
      "id_parroquia": 558,
      "id_municipio": 196,
      "parroquia": "PQ. LA APARICION"
    },
    {
      "id_parroquia": 559,
      "id_municipio": 191,
      "parroquia": "PQ. ANTOLIN TOVAR AQUINO"
    },
    {
      "id_parroquia": 560,
      "id_municipio": 199,
      "parroquia": "CM. PAPELON"
    },
    {
      "id_parroquia": 561,
      "id_municipio": 198,
      "parroquia": "PQ. FLORIDA"
    },
    {
      "id_parroquia": 562,
      "id_municipio": 31,
      "parroquia": "PQ. RAMON PERAZA"
    },
    {
      "id_parroquia": 563,
      "id_municipio": 196,
      "parroquia": "PQ. LA ESTACION"
    },
    {
      "id_parroquia": 564,
      "id_municipio": 199,
      "parroquia": "PQ. CA\u00d1O DELGADITO"
    },
    {
      "id_parroquia": 565,
      "id_municipio": 197,
      "parroquia": "PQ. THERMO MORALES"
    },
    {
      "id_parroquia": 566,
      "id_municipio": 42,
      "parroquia": "PQ. VALENTIN VALIENTE"
    },
    {
      "id_parroquia": 567,
      "id_municipio": 200,
      "parroquia": "PQ. SAN LORENZO"
    },
    {
      "id_parroquia": 568,
      "id_municipio": 201,
      "parroquia": "PQ. SANTA CATALINA"
    },
    {
      "id_parroquia": 569,
      "id_municipio": 42,
      "parroquia": "PQ. RAUL LEONI"
    },
    {
      "id_parroquia": 570,
      "id_municipio": 200,
      "parroquia": "PQ. CUMANACOA"
    },
    {
      "id_parroquia": 571,
      "id_municipio": 42,
      "parroquia": "PQ. AYACUCHO"
    },
    {
      "id_parroquia": 572,
      "id_municipio": 202,
      "parroquia": "PQ. ARAYA"
    },
    {
      "id_parroquia": 573,
      "id_municipio": 203,
      "parroquia": "PQ. GUIRIA"
    },
    {
      "id_parroquia": 574,
      "id_municipio": 201,
      "parroquia": "PQ. MACARAPANA"
    },
    {
      "id_parroquia": 575,
      "id_municipio": 179,
      "parroquia": "PQ. IRAPA"
    },
    {
      "id_parroquia": 576,
      "id_municipio": 137,
      "parroquia": "PQ. MARI\u00d1O"
    },
    {
      "id_parroquia": 577,
      "id_municipio": 202,
      "parroquia": "PQ. MANICUARE"
    },
    {
      "id_parroquia": 578,
      "id_municipio": 202,
      "parroquia": "PQ. CHACOPATA"
    },
    {
      "id_parroquia": 579,
      "id_municipio": 63,
      "parroquia": "PQ. EL MORRO DE PTO SANTO"
    },
    {
      "id_parroquia": 580,
      "id_municipio": 200,
      "parroquia": "PQ. ARENAS"
    },
    {
      "id_parroquia": 581,
      "id_municipio": 42,
      "parroquia": "PQ. GRAN MARISCAL"
    },
    {
      "id_parroquia": 582,
      "id_municipio": 8,
      "parroquia": "CM. MARIGUITAR"
    },
    {
      "id_parroquia": 583,
      "id_municipio": 179,
      "parroquia": "PQ. CAMPO CLARO"
    },
    {
      "id_parroquia": 584,
      "id_municipio": 63,
      "parroquia": "PQ. RIO CARIBE"
    },
    {
      "id_parroquia": 585,
      "id_municipio": 41,
      "parroquia": "PQ. TUNAPUY"
    },
    {
      "id_parroquia": 586,
      "id_municipio": 200,
      "parroquia": "PQ. COCOLLAR"
    },
    {
      "id_parroquia": 587,
      "id_municipio": 204,
      "parroquia": "PQ. CARIACO"
    },
    {
      "id_parroquia": 588,
      "id_municipio": 200,
      "parroquia": "PQ. ARICAGUA"
    },
    {
      "id_parroquia": 589,
      "id_municipio": 205,
      "parroquia": "CM. SAN ANT DEL GOLFO"
    },
    {
      "id_parroquia": 590,
      "id_municipio": 204,
      "parroquia": "PQ. CATUARO"
    },
    {
      "id_parroquia": 591,
      "id_municipio": 28,
      "parroquia": "PQ. YAGUARAPARO"
    },
    {
      "id_parroquia": 592,
      "id_municipio": 206,
      "parroquia": "PQ. GRAL FCO. A VASQUEZ"
    },
    {
      "id_parroquia": 593,
      "id_municipio": 206,
      "parroquia": "PQ. GUARAUNOS"
    },
    {
      "id_parroquia": 594,
      "id_municipio": 206,
      "parroquia": "PQ. EL RINCON"
    },
    {
      "id_parroquia": 595,
      "id_municipio": 204,
      "parroquia": "PQ. RENDON"
    },
    {
      "id_parroquia": 596,
      "id_municipio": 28,
      "parroquia": "PQ. PAUJIL"
    },
    {
      "id_parroquia": 597,
      "id_municipio": 63,
      "parroquia": "PQ. ANTONIO JOSE DE SUCRE"
    },
    {
      "id_parroquia": 598,
      "id_municipio": 63,
      "parroquia": "PQ. SAN JUAN DE LAS GALDONAS"
    },
    {
      "id_parroquia": 599,
      "id_municipio": 207,
      "parroquia": "PQ. AMENODORO RANGEL LAMUS"
    },
    {
      "id_parroquia": 600,
      "id_municipio": 8,
      "parroquia": "CM. SAN ANT DEL TACHIRA"
    },
    {
      "id_parroquia": 601,
      "id_municipio": 208,
      "parroquia": "PQ. PEDRO MARIA MORANTES"
    },
    {
      "id_parroquia": 602,
      "id_municipio": 209,
      "parroquia": "CM. URE\u00d1A"
    },
    {
      "id_parroquia": 603,
      "id_municipio": 210,
      "parroquia": "CM. SAN JOSECITO"
    },
    {
      "id_parroquia": 604,
      "id_municipio": 208,
      "parroquia": "PQ. SN JUAN BAUTISTA"
    },
    {
      "id_parroquia": 605,
      "id_municipio": 207,
      "parroquia": "CM. TARIBA"
    },
    {
      "id_parroquia": 606,
      "id_municipio": 211,
      "parroquia": "PQ. BRAMON"
    },
    {
      "id_parroquia": 607,
      "id_municipio": 212,
      "parroquia": "CM. LA FRIA"
    },
    {
      "id_parroquia": 608,
      "id_municipio": 208,
      "parroquia": "PQ. LA CONCORDIA"
    },
    {
      "id_parroquia": 609,
      "id_municipio": 211,
      "parroquia": "CM. RUBIO"
    },
    {
      "id_parroquia": 610,
      "id_municipio": 213,
      "parroquia": "CM. PALMIRA"
    },
    {
      "id_parroquia": 611,
      "id_municipio": 214,
      "parroquia": "CM. STA. ANA  DEL TACHIRA"
    },
    {
      "id_parroquia": 612,
      "id_municipio": 209,
      "parroquia": "PQ. NUEVA ARCADIA"
    },
    {
      "id_parroquia": 613,
      "id_municipio": 8,
      "parroquia": "PQ. PALOTAL"
    },
    {
      "id_parroquia": 614,
      "id_municipio": 215,
      "parroquia": "CM. MICHELENA"
    },
    {
      "id_parroquia": 615,
      "id_municipio": 216,
      "parroquia": "CM. COLON"
    },
    {
      "id_parroquia": 616,
      "id_municipio": 217,
      "parroquia": "CM. SAN RAFAEL DEL PI\u00d1AL"
    },
    {
      "id_parroquia": 617,
      "id_municipio": 218,
      "parroquia": "CM. LA GRITA"
    },
    {
      "id_parroquia": 618,
      "id_municipio": 219,
      "parroquia": "CM. COLONCITO"
    },
    {
      "id_parroquia": 619,
      "id_municipio": 220,
      "parroquia": "CM. EL COBRE"
    },
    {
      "id_parroquia": 620,
      "id_municipio": 219,
      "parroquia": "PQ. LA PALMITA"
    },
    {
      "id_parroquia": 621,
      "id_municipio": 208,
      "parroquia": "PQ. SAN SEBASTIAN"
    },
    {
      "id_parroquia": 622,
      "id_municipio": 146,
      "parroquia": "CM. CORDERO"
    },
    {
      "id_parroquia": 623,
      "id_municipio": 221,
      "parroquia": "PQ. JUAN GERMAN ROSCIO"
    },
    {
      "id_parroquia": 624,
      "id_municipio": 217,
      "parroquia": "PQ. ALBERTO ADRIANI"
    },
    {
      "id_parroquia": 625,
      "id_municipio": 222,
      "parroquia": "CM. CAPACHO VIEJO"
    },
    {
      "id_parroquia": 626,
      "id_municipio": 223,
      "parroquia": "CM. LOBATERA"
    },
    {
      "id_parroquia": 627,
      "id_municipio": 224,
      "parroquia": "CM. UMUQUENA"
    },
    {
      "id_parroquia": 628,
      "id_municipio": 221,
      "parroquia": "CM. CAPACHO NUEVO"
    },
    {
      "id_parroquia": 629,
      "id_municipio": 217,
      "parroquia": "PQ. SANTO DOMINGO"
    },
    {
      "id_parroquia": 630,
      "id_municipio": 225,
      "parroquia": "CM. SEBORUCO"
    },
    {
      "id_parroquia": 631,
      "id_municipio": 41,
      "parroquia": "PQ. DORADAS"
    },
    {
      "id_parroquia": 632,
      "id_municipio": 212,
      "parroquia": "PQ. BOCA DE GRITA"
    },
    {
      "id_parroquia": 633,
      "id_municipio": 222,
      "parroquia": "PQ. MANUEL FELIPE RUGELES"
    },
    {
      "id_parroquia": 634,
      "id_municipio": 41,
      "parroquia": "CM. ABEJALES"
    },
    {
      "id_parroquia": 635,
      "id_municipio": 42,
      "parroquia": "CM. QUENIQUEA"
    },
    {
      "id_parroquia": 636,
      "id_municipio": 226,
      "parroquia": "CM. LAS MESAS"
    },
    {
      "id_parroquia": 637,
      "id_municipio": 10,
      "parroquia": "CM. SAN SIMON"
    },
    {
      "id_parroquia": 638,
      "id_municipio": 221,
      "parroquia": "PQ. ROMAN CARDENAS"
    },
    {
      "id_parroquia": 639,
      "id_municipio": 227,
      "parroquia": "CM. PREGONERO"
    },
    {
      "id_parroquia": 640,
      "id_municipio": 228,
      "parroquia": "CM. SAN JOSE DE BOLIVAR"
    },
    {
      "id_parroquia": 641,
      "id_municipio": 229,
      "parroquia": "PQ. HERNANDEZ"
    },
    {
      "id_parroquia": 642,
      "id_municipio": 216,
      "parroquia": "PQ. RIVAS BERTI"
    },
    {
      "id_parroquia": 643,
      "id_municipio": 229,
      "parroquia": "PQ. BOCONO"
    },
    {
      "id_parroquia": 644,
      "id_municipio": 229,
      "parroquia": "CM. LA TENDIDA"
    },
    {
      "id_parroquia": 645,
      "id_municipio": 230,
      "parroquia": "CM. DELICIAS"
    },
    {
      "id_parroquia": 646,
      "id_municipio": 231,
      "parroquia": "PQ. LA PAZ"
    },
    {
      "id_parroquia": 647,
      "id_municipio": 231,
      "parroquia": "PQ. FLOR DE PATRIA"
    },
    {
      "id_parroquia": 648,
      "id_municipio": 231,
      "parroquia": "PQ. PAMPAN"
    },
    {
      "id_parroquia": 649,
      "id_municipio": 233,
      "parroquia": "PQ. MERCEDES DIAZ"
    },
    {
      "id_parroquia": 650,
      "id_municipio": 235,
      "parroquia": "PQ. CAMPO ALEGRE"
    },
    {
      "id_parroquia": 651,
      "id_municipio": 233,
      "parroquia": "PQ. MENDOZA"
    },
    {
      "id_parroquia": 652,
      "id_municipio": 232,
      "parroquia": "PQ. CRISTOBAL MENDOZA"
    },
    {
      "id_parroquia": 653,
      "id_municipio": 233,
      "parroquia": "PQ. JUAN IGNACIO MONTILLA"
    },
    {
      "id_parroquia": 654,
      "id_municipio": 234,
      "parroquia": "PQ. MOSQUEY"
    },
    {
      "id_parroquia": 655,
      "id_municipio": 232,
      "parroquia": "PQ. TRES ESQUINAS"
    },
    {
      "id_parroquia": 656,
      "id_municipio": 146,
      "parroquia": "PQ. SANTA ISABEL"
    },
    {
      "id_parroquia": 657,
      "id_municipio": 233,
      "parroquia": "PQ. LA PUERTA"
    },
    {
      "id_parroquia": 658,
      "id_municipio": 233,
      "parroquia": "PQ. LA BEATRIZ"
    },
    {
      "id_parroquia": 659,
      "id_municipio": 235,
      "parroquia": "PQ. CARVAJAL"
    },
    {
      "id_parroquia": 660,
      "id_municipio": 235,
      "parroquia": "PQ. ANTONIO N BRICE\u00d1O"
    },
    {
      "id_parroquia": 661,
      "id_municipio": 15,
      "parroquia": "PQ. EL DIVIDIVE"
    },
    {
      "id_parroquia": 662,
      "id_municipio": 236,
      "parroquia": "PQ. PAMPANITO II"
    },
    {
      "id_parroquia": 663,
      "id_municipio": 235,
      "parroquia": "PQ. JOSE LEONARDO SUAREZ"
    },
    {
      "id_parroquia": 664,
      "id_municipio": 42,
      "parroquia": "PQ. VALMORE RODRIGUEZ"
    },
    {
      "id_parroquia": 665,
      "id_municipio": 232,
      "parroquia": "PQ. MONSE\u00d1OR CARRILLO"
    },
    {
      "id_parroquia": 666,
      "id_municipio": 42,
      "parroquia": "PQ. SABANA DE MENDOZA"
    },
    {
      "id_parroquia": 667,
      "id_municipio": 238,
      "parroquia": "PQ. SABANA LIBRE"
    },
    {
      "id_parroquia": 668,
      "id_municipio": 236,
      "parroquia": "PQ. PAMPANITO"
    },
    {
      "id_parroquia": 669,
      "id_municipio": 42,
      "parroquia": "PQ. JUNIN"
    },
    {
      "id_parroquia": 670,
      "id_municipio": 8,
      "parroquia": "PQ. SABANA GRANDE"
    },
    {
      "id_parroquia": 671,
      "id_municipio": 239,
      "parroquia": "PQ. LA PUEBLITA"
    },
    {
      "id_parroquia": 672,
      "id_municipio": 241,
      "parroquia": "PQ. MOTATAN"
    },
    {
      "id_parroquia": 673,
      "id_municipio": 242,
      "parroquia": "PQ. SANTA APOLONIA"
    },
    {
      "id_parroquia": 674,
      "id_municipio": 243,
      "parroquia": "PQ. CAMPO ELIAS"
    },
    {
      "id_parroquia": 675,
      "id_municipio": 239,
      "parroquia": "PQ. JOSE G HERNANDEZ"
    },
    {
      "id_parroquia": 676,
      "id_municipio": 239,
      "parroquia": "PQ. EL CEDRO"
    },
    {
      "id_parroquia": 677,
      "id_municipio": 51,
      "parroquia": "PQ. TU\u00d1AME"
    },
    {
      "id_parroquia": 678,
      "id_municipio": 51,
      "parroquia": "PQ. JAJO"
    },
    {
      "id_parroquia": 679,
      "id_municipio": 239,
      "parroquia": "PQ. BETIJOQUE"
    },
    {
      "id_parroquia": 680,
      "id_municipio": 15,
      "parroquia": "PQ. AGUA SANTA"
    },
    {
      "id_parroquia": 681,
      "id_municipio": 15,
      "parroquia": "PQ. EL CENIZO"
    },
    {
      "id_parroquia": 682,
      "id_municipio": 242,
      "parroquia": "PQ. EL PROGRESO"
    },
    {
      "id_parroquia": 683,
      "id_municipio": 238,
      "parroquia": "PQ. ESCUQUE"
    },
    {
      "id_parroquia": 684,
      "id_municipio": 242,
      "parroquia": "PQ. TRES DE FEBRERO"
    },
    {
      "id_parroquia": 685,
      "id_municipio": 8,
      "parroquia": "PQ. GRANADOS"
    },
    {
      "id_parroquia": 686,
      "id_municipio": 146,
      "parroquia": "PQ. EL JAGUITO"
    },
    {
      "id_parroquia": 687,
      "id_municipio": 241,
      "parroquia": "PQ. JALISCO"
    },
    {
      "id_parroquia": 688,
      "id_municipio": 240,
      "parroquia": "PQ. MONTE CARMELO"
    },
    {
      "id_parroquia": 689,
      "id_municipio": 245,
      "parroquia": "PQ. CARACHE"
    },
    {
      "id_parroquia": 690,
      "id_municipio": 237,
      "parroquia": "PQ. MANUEL SALVADOR ULLOA"
    },
    {
      "id_parroquia": 691,
      "id_municipio": 234,
      "parroquia": "PQ. BURBUSAY"
    },
    {
      "id_parroquia": 692,
      "id_municipio": 237,
      "parroquia": "PQ. CHEJENDE"
    },
    {
      "id_parroquia": 693,
      "id_municipio": 51,
      "parroquia": "PQ. LA QUEBRADA"
    },
    {
      "id_parroquia": 694,
      "id_municipio": 234,
      "parroquia": "PQ. MONSE\u00d1OR JAUREGUI"
    },
    {
      "id_parroquia": 695,
      "id_municipio": 51,
      "parroquia": "PQ. SANTIAGO"
    },
    {
      "id_parroquia": 696,
      "id_municipio": 246,
      "parroquia": "CM. YARITAGUA"
    },
    {
      "id_parroquia": 697,
      "id_municipio": 247,
      "parroquia": "CM. NIRGUA"
    },
    {
      "id_parroquia": 698,
      "id_municipio": 248,
      "parroquia": "CM. SAN FELIPE"
    },
    {
      "id_parroquia": 699,
      "id_municipio": 249,
      "parroquia": "CM. YUMARE"
    },
    {
      "id_parroquia": 700,
      "id_municipio": 19,
      "parroquia": "CM. INDEPENDENCIA"
    },
    {
      "id_parroquia": 701,
      "id_municipio": 250,
      "parroquia": "CM. COCOROTE"
    },
    {
      "id_parroquia": 702,
      "id_municipio": 16,
      "parroquia": "CM. CHIVACOA"
    },
    {
      "id_parroquia": 703,
      "id_municipio": 251,
      "parroquia": "CM. SABANA DE PARRA"
    },
    {
      "id_parroquia": 704,
      "id_municipio": 248,
      "parroquia": "PQ. ALBARICO"
    },
    {
      "id_parroquia": 705,
      "id_municipio": 247,
      "parroquia": "PQ. SALOM"
    },
    {
      "id_parroquia": 706,
      "id_municipio": 252,
      "parroquia": "CM. URACHICHE"
    },
    {
      "id_parroquia": 707,
      "id_municipio": 8,
      "parroquia": "CM. AROA"
    },
    {
      "id_parroquia": 708,
      "id_municipio": 246,
      "parroquia": "PQ. SAN ANDRES"
    },
    {
      "id_parroquia": 709,
      "id_municipio": 248,
      "parroquia": "PQ. SAN JAVIER"
    },
    {
      "id_parroquia": 710,
      "id_municipio": 253,
      "parroquia": "CM. BORAURE"
    },
    {
      "id_parroquia": 711,
      "id_municipio": 254,
      "parroquia": "PQ. EL GUAYABO"
    },
    {
      "id_parroquia": 712,
      "id_municipio": 255,
      "parroquia": "CM. SAN PABLO"
    },
    {
      "id_parroquia": 713,
      "id_municipio": 42,
      "parroquia": "CM. GUAMA"
    },
    {
      "id_parroquia": 714,
      "id_municipio": 254,
      "parroquia": "CM. FARRIAR"
    },
    {
      "id_parroquia": 715,
      "id_municipio": 256,
      "parroquia": "PQ. FRANCISCO EUGENIO B"
    },
    {
      "id_parroquia": 716,
      "id_municipio": 256,
      "parroquia": "PQ. OLEGARIO VILLALOBOS"
    },
    {
      "id_parroquia": 717,
      "id_municipio": 258,
      "parroquia": "PQ. EL DANTO"
    },
    {
      "id_parroquia": 718,
      "id_municipio": 256,
      "parroquia": "PQ. LUIS HURTADO HIGUERA"
    },
    {
      "id_parroquia": 719,
      "id_municipio": 259,
      "parroquia": "PQ. UDON PEREZ"
    },
    {
      "id_parroquia": 720,
      "id_municipio": 260,
      "parroquia": "PQ. EL CARMELO"
    },
    {
      "id_parroquia": 721,
      "id_municipio": 261,
      "parroquia": "PQ. RICAURTE"
    },
    {
      "id_parroquia": 722,
      "id_municipio": 258,
      "parroquia": "PQ. PARAUTE"
    },
    {
      "id_parroquia": 723,
      "id_municipio": 112,
      "parroquia": "PQ. JOSE DOMINGO RUS"
    },
    {
      "id_parroquia": 724,
      "id_municipio": 256,
      "parroquia": "PQ. CARACCIOLO PARRA PEREZ"
    },
    {
      "id_parroquia": 725,
      "id_municipio": 262,
      "parroquia": "PQ. BARI"
    },
    {
      "id_parroquia": 726,
      "id_municipio": 256,
      "parroquia": "PQ. MANUEL DAGNINO"
    },
    {
      "id_parroquia": 727,
      "id_municipio": 256,
      "parroquia": "PQ. ANTONIO BORJAS ROMERO"
    },
    {
      "id_parroquia": 728,
      "id_municipio": 259,
      "parroquia": "PQ. ENCONTRADOS"
    },
    {
      "id_parroquia": 729,
      "id_municipio": 112,
      "parroquia": "PQ. DOMITILA FLORES"
    },
    {
      "id_parroquia": 730,
      "id_municipio": 264,
      "parroquia": "PQ. CARLOS QUEVEDO"
    },
    {
      "id_parroquia": 731,
      "id_municipio": 256,
      "parroquia": "PQ. IDELFONZO VASQUEZ"
    },
    {
      "id_parroquia": 732,
      "id_municipio": 112,
      "parroquia": "PQ. FRANCISCO OCHOA"
    },
    {
      "id_parroquia": 733,
      "id_municipio": 261,
      "parroquia": "PQ. LUIS DE VICENTE"
    },
    {
      "id_parroquia": 734,
      "id_municipio": 265,
      "parroquia": "PQ. GERMAN RIOS LINARES"
    },
    {
      "id_parroquia": 735,
      "id_municipio": 256,
      "parroquia": "PQ. CACIQUE MARA"
    },
    {
      "id_parroquia": 736,
      "id_municipio": 266,
      "parroquia": "PQ. EL ROSARIO"
    },
    {
      "id_parroquia": 737,
      "id_municipio": 112,
      "parroquia": "PQ. MARCIAL HERNANDEZ"
    },
    {
      "id_parroquia": 738,
      "id_municipio": 256,
      "parroquia": "PQ. VENANCIO PULGAR"
    },
    {
      "id_parroquia": 739,
      "id_municipio": 256,
      "parroquia": "PQ. JUANA DE AVILA"
    },
    {
      "id_parroquia": 740,
      "id_municipio": 262,
      "parroquia": "PQ. JESUS M SEMPRUN"
    },
    {
      "id_parroquia": 741,
      "id_municipio": 256,
      "parroquia": "PQ. CRISTO DE ARANZA"
    },
    {
      "id_parroquia": 742,
      "id_municipio": 257,
      "parroquia": "PQ. JOSE RAMON YEPEZ"
    },
    {
      "id_parroquia": 743,
      "id_municipio": 112,
      "parroquia": "PQ. LOS CORTIJOS"
    },
    {
      "id_parroquia": 744,
      "id_municipio": 263,
      "parroquia": "PQ. SAN CARLOS DEL ZULIA"
    },
    {
      "id_parroquia": 745,
      "id_municipio": 256,
      "parroquia": "PQ. COQUIVACOA"
    },
    {
      "id_parroquia": 746,
      "id_municipio": 260,
      "parroquia": "PQ. CONCEPCION"
    },
    {
      "id_parroquia": 747,
      "id_municipio": 112,
      "parroquia": "PQ. EL BAJO"
    },
    {
      "id_parroquia": 748,
      "id_municipio": 268,
      "parroquia": "PQ. SAN JOSE DE PERIJA"
    },
    {
      "id_parroquia": 749,
      "id_municipio": 270,
      "parroquia": "PQ. GUAJIRA"
    },
    {
      "id_parroquia": 750,
      "id_municipio": 261,
      "parroquia": "PQ. TAMARE"
    },
    {
      "id_parroquia": 751,
      "id_municipio": 265,
      "parroquia": "PQ. JORGE HERNANDEZ"
    },
    {
      "id_parroquia": 752,
      "id_municipio": 258,
      "parroquia": "PQ. VENEZUELA"
    },
    {
      "id_parroquia": 753,
      "id_municipio": 266,
      "parroquia": "PQ. SIXTO ZAMBRANO"
    },
    {
      "id_parroquia": 754,
      "id_municipio": 260,
      "parroquia": "PQ. POTRERITOS"
    },
    {
      "id_parroquia": 755,
      "id_municipio": 263,
      "parroquia": "PQ. SANTA CRUZ DEL ZULIA"
    },
    {
      "id_parroquia": 756,
      "id_municipio": 265,
      "parroquia": "PQ. CARMEN HERRERA"
    },
    {
      "id_parroquia": 757,
      "id_municipio": 267,
      "parroquia": "PQ. LA VICTORIA"
    },
    {
      "id_parroquia": 758,
      "id_municipio": 268,
      "parroquia": "PQ. BARTOLOME DE LAS CASAS"
    },
    {
      "id_parroquia": 759,
      "id_municipio": 263,
      "parroquia": "PQ. MORALITO"
    },
    {
      "id_parroquia": 760,
      "id_municipio": 265,
      "parroquia": "PQ. AMBROSIO"
    },
    {
      "id_parroquia": 761,
      "id_municipio": 267,
      "parroquia": "PQ. RAUL CUENCA"
    },
    {
      "id_parroquia": 762,
      "id_municipio": 265,
      "parroquia": "PQ. LA ROSA"
    },
    {
      "id_parroquia": 763,
      "id_municipio": 261,
      "parroquia": "PQ. LAS PARCELAS"
    },
    {
      "id_parroquia": 764,
      "id_municipio": 261,
      "parroquia": "PQ. LA SIERRITA"
    },
    {
      "id_parroquia": 765,
      "id_municipio": 266,
      "parroquia": "PQ. DONALDO GARCIA"
    },
    {
      "id_parroquia": 766,
      "id_municipio": 271,
      "parroquia": "PQ. EL MENE"
    },
    {
      "id_parroquia": 767,
      "id_municipio": 257,
      "parroquia": "PQ. MARIANO PARRA LEON"
    },
    {
      "id_parroquia": 768,
      "id_municipio": 271,
      "parroquia": "PQ. SANTA RITA"
    },
    {
      "id_parroquia": 769,
      "id_municipio": 269,
      "parroquia": "PQ. SAN TIMOTEO"
    },
    {
      "id_parroquia": 770,
      "id_municipio": 265,
      "parroquia": "PQ. PUNTA GORDA"
    },
    {
      "id_parroquia": 771,
      "id_municipio": 271,
      "parroquia": "PQ. JOSE CENOVIO URRIBARR"
    },
    {
      "id_parroquia": 772,
      "id_municipio": 42,
      "parroquia": "PQ. EL BATEY"
    },
    {
      "id_parroquia": 773,
      "id_municipio": 269,
      "parroquia": "PQ. MANUEL GUANIPA MATOS"
    },
    {
      "id_parroquia": 774,
      "id_municipio": 270,
      "parroquia": "PQ. ELIAS SANCHEZ RUBIO"
    },
    {
      "id_parroquia": 775,
      "id_municipio": 265,
      "parroquia": "PQ. SAN BENITO"
    },
    {
      "id_parroquia": 776,
      "id_municipio": 272,
      "parroquia": "PQ. MONAGAS"
    },
    {
      "id_parroquia": 777,
      "id_municipio": 270,
      "parroquia": "PQ. SINAMAICA"
    },
    {
      "id_parroquia": 778,
      "id_municipio": 42,
      "parroquia": "PQ. BOBURES"
    },
    {
      "id_parroquia": 779,
      "id_municipio": 170,
      "parroquia": "PQ. RAFAEL MARIA BARALT"
    },
    {
      "id_parroquia": 780,
      "id_municipio": 272,
      "parroquia": "PQ. ISLA DE TOAS"
    },
    {
      "id_parroquia": 781,
      "id_municipio": 15,
      "parroquia": "PQ. ANA MARIA CAMPOS"
    },
    {
      "id_parroquia": 782,
      "id_municipio": 270,
      "parroquia": "PQ. ALTA GUAJIRA"
    },
    {
      "id_parroquia": 783,
      "id_municipio": 261,
      "parroquia": "PQ. MONS.MARCOS SERGIO G"
    },
    {
      "id_parroquia": 784,
      "id_municipio": 263,
      "parroquia": "PQ. URIBARRI"
    },
    {
      "id_parroquia": 785,
      "id_municipio": 258,
      "parroquia": "PQ. CAMPO LARA"
    },
    {
      "id_parroquia": 786,
      "id_municipio": 15,
      "parroquia": "PQ. FARIA"
    },
    {
      "id_parroquia": 787,
      "id_municipio": 269,
      "parroquia": "PQ. GENERAL URDANETA"
    },
    {
      "id_parroquia": 788,
      "id_municipio": 264,
      "parroquia": "PQ. FRANCISCO J PULGAR"
    },
    {
      "id_parroquia": 789,
      "id_municipio": 271,
      "parroquia": "PQ. PEDRO LUCAS URRIBARRI"
    },
    {
      "id_parroquia": 790,
      "id_municipio": 268,
      "parroquia": "PQ. RIO NEGRO"
    },
    {
      "id_parroquia": 791,
      "id_municipio": 42,
      "parroquia": "PQ. HERAS"
    },
    {
      "id_parroquia": 792,
      "id_municipio": 7,
      "parroquia": "CM. ISLA DE RATON"
    },
    {
      "id_parroquia": 793,
      "id_municipio": 2,
      "parroquia": "PQ. PARHUE\u00d1A"
    },
    {
      "id_parroquia": 794,
      "id_municipio": 2,
      "parroquia": "PQ. PLATANILLAL"
    },
    {
      "id_parroquia": 795,
      "id_municipio": 273,
      "parroquia": "CM. LA ESMERALDA"
    },
    {
      "id_parroquia": 796,
      "id_municipio": 273,
      "parroquia": "PQ. MARAWAKA"
    },
    {
      "id_parroquia": 797,
      "id_municipio": 4,
      "parroquia": "PQ. ALTO VENTUARI"
    },
    {
      "id_parroquia": 798,
      "id_municipio": 273,
      "parroquia": "PQ. SIERRA PARIMA"
    },
    {
      "id_parroquia": 799,
      "id_municipio": 273,
      "parroquia": "PQ. MAVACA"
    },
    {
      "id_parroquia": 800,
      "id_municipio": 4,
      "parroquia": "PQ. BAJO VENTUARI"
    },
    {
      "id_parroquia": 801,
      "id_municipio": 7,
      "parroquia": "PQ. MUNDUAPO"
    },
    {
      "id_parroquia": 802,
      "id_municipio": 7,
      "parroquia": "PQ. SIPAPO"
    },
    {
      "id_parroquia": 803,
      "id_municipio": 3,
      "parroquia": "PQ. UCATA"
    },
    {
      "id_parroquia": 804,
      "id_municipio": 7,
      "parroquia": "PQ. GUAYAPO"
    },
    {
      "id_parroquia": 805,
      "id_municipio": 3,
      "parroquia": "PQ. YAPACANA"
    },
    {
      "id_parroquia": 806,
      "id_municipio": 4,
      "parroquia": "PQ. MEDIO VENTUARI"
    },
    {
      "id_parroquia": 807,
      "id_municipio": 6,
      "parroquia": "PQ. VICTORINO"
    },
    {
      "id_parroquia": 808,
      "id_municipio": 273,
      "parroquia": "PQ. HUACHAMACARE"
    },
    {
      "id_parroquia": 809,
      "id_municipio": 5,
      "parroquia": "PQ. SOLANO"
    },
    {
      "id_parroquia": 810,
      "id_municipio": 3,
      "parroquia": "PQ. CANAME"
    },
    {
      "id_parroquia": 811,
      "id_municipio": 5,
      "parroquia": "PQ. COCUY"
    },
    {
      "id_parroquia": 812,
      "id_municipio": 5,
      "parroquia": "PQ. CASIQUIARE"
    },
    {
      "id_parroquia": 813,
      "id_municipio": 6,
      "parroquia": "PQ. COMUNIDAD"
    },
    {
      "id_parroquia": 814,
      "id_municipio": 24,
      "parroquia": "PQ. PIAR"
    },
    {
      "id_parroquia": 815,
      "id_municipio": 18,
      "parroquia": "PQ. BOCA DE CHAVEZ"
    },
    {
      "id_parroquia": 816,
      "id_municipio": 26,
      "parroquia": "PQ. TOMAS ALFARO CALATRAVA"
    },
    {
      "id_parroquia": 817,
      "id_municipio": 31,
      "parroquia": "PQ. ARAMENDI"
    },
    {
      "id_parroquia": 818,
      "id_municipio": 33,
      "parroquia": "PQ. QUINTERO"
    },
    {
      "id_parroquia": 819,
      "id_municipio": 46,
      "parroquia": "PQ. TIARA"
    },
    {
      "id_parroquia": 820,
      "id_municipio": 38,
      "parroquia": "PQ. CHUAO"
    },
    {
      "id_parroquia": 821,
      "id_municipio": 49,
      "parroquia": "PQ. GUIRIPA"
    },
    {
      "id_parroquia": 822,
      "id_municipio": 51,
      "parroquia": "PQ. LAS PE\u00d1ITAS"
    },
    {
      "id_parroquia": 823,
      "id_municipio": 49,
      "parroquia": "PQ. VALLE MORIN"
    },
    {
      "id_parroquia": 824,
      "id_municipio": 49,
      "parroquia": "PQ. OLLAS DE CARAMACATE"
    },
    {
      "id_parroquia": 825,
      "id_municipio": 59,
      "parroquia": "PQ. PUERTO VIVAS"
    },
    {
      "id_parroquia": 826,
      "id_municipio": 8,
      "parroquia": "PQ. CALDERAS"
    },
    {
      "id_parroquia": 827,
      "id_municipio": 63,
      "parroquia": "PQ. GUADARRAMA"
    },
    {
      "id_parroquia": 828,
      "id_municipio": 8,
      "parroquia": "PQ. ALTAMIRA"
    },
    {
      "id_parroquia": 829,
      "id_municipio": 60,
      "parroquia": "PQ. MASPARRITO"
    },
    {
      "id_parroquia": 830,
      "id_municipio": 71,
      "parroquia": "PQ. LA URBANA"
    },
    {
      "id_parroquia": 831,
      "id_municipio": 67,
      "parroquia": "PQ. IKABARU"
    },
    {
      "id_parroquia": 832,
      "id_municipio": 64,
      "parroquia": "PQ. 5 DE JULIO"
    },
    {
      "id_parroquia": 833,
      "id_municipio": 42,
      "parroquia": "PQ. MOITACO"
    },
    {
      "id_parroquia": 834,
      "id_municipio": 69,
      "parroquia": "PQ. ORINOCO"
    },
    {
      "id_parroquia": 835,
      "id_municipio": 42,
      "parroquia": "PQ. LAS MAJADAS"
    },
    {
      "id_parroquia": 836,
      "id_municipio": 69,
      "parroquia": "PQ. PANAPANA"
    },
    {
      "id_parroquia": 837,
      "id_municipio": 42,
      "parroquia": "PQ. ARIPAO"
    },
    {
      "id_parroquia": 838,
      "id_municipio": 71,
      "parroquia": "PQ. GUANIAMO"
    },
    {
      "id_parroquia": 839,
      "id_municipio": 69,
      "parroquia": "PQ. ZEA"
    },
    {
      "id_parroquia": 840,
      "id_municipio": 85,
      "parroquia": "PQ. URAMA"
    },
    {
      "id_parroquia": 841,
      "id_municipio": 91,
      "parroquia": "PQ. MACAPO"
    },
    {
      "id_parroquia": 842,
      "id_municipio": 55,
      "parroquia": "PQ. JUAN ANGEL BRAVO"
    },
    {
      "id_parroquia": 843,
      "id_municipio": 92,
      "parroquia": "PQ. ANICETO LUGO"
    },
    {
      "id_parroquia": 844,
      "id_municipio": 92,
      "parroquia": "PQ. MANUEL RENAUD"
    },
    {
      "id_parroquia": 845,
      "id_municipio": 94,
      "parroquia": "PQ. VIRGEN DEL VALLE"
    },
    {
      "id_parroquia": 846,
      "id_municipio": 94,
      "parroquia": "PQ. JUAN MILLAN"
    },
    {
      "id_parroquia": 847,
      "id_municipio": 95,
      "parroquia": "PQ. LUIS B. PRIETO F."
    },
    {
      "id_parroquia": 848,
      "id_municipio": 92,
      "parroquia": "PQ. SANTOS DE ABELGAS"
    },
    {
      "id_parroquia": 849,
      "id_municipio": 92,
      "parroquia": "PQ. ALMIRANTE LUIS BRION"
    },
    {
      "id_parroquia": 850,
      "id_municipio": 99,
      "parroquia": "PQ. EL VINCULO"
    },
    {
      "id_parroquia": 851,
      "id_municipio": 102,
      "parroquia": "PQ. BARIRO"
    },
    {
      "id_parroquia": 852,
      "id_municipio": 98,
      "parroquia": "PQ. MACORUCA"
    },
    {
      "id_parroquia": 853,
      "id_municipio": 99,
      "parroquia": "PQ. JADACAQUIVA"
    },
    {
      "id_parroquia": 854,
      "id_municipio": 15,
      "parroquia": "PQ. MITARE"
    },
    {
      "id_parroquia": 855,
      "id_municipio": 101,
      "parroquia": "PQ. EL PAUJI"
    },
    {
      "id_parroquia": 856,
      "id_municipio": 45,
      "parroquia": "PQ. LA CIENAGA"
    },
    {
      "id_parroquia": 857,
      "id_municipio": 15,
      "parroquia": "PQ. RIO SECO"
    },
    {
      "id_parroquia": 858,
      "id_municipio": 105,
      "parroquia": "PQ. CURIMAGUA"
    },
    {
      "id_parroquia": 859,
      "id_municipio": 15,
      "parroquia": "PQ. GUZMAN GUILLERMO"
    },
    {
      "id_parroquia": 860,
      "id_municipio": 104,
      "parroquia": "PQ. CASIGUA"
    },
    {
      "id_parroquia": 861,
      "id_municipio": 102,
      "parroquia": "PQ. GUAJIRO"
    },
    {
      "id_parroquia": 862,
      "id_municipio": 105,
      "parroquia": "PQ. COLINA"
    },
    {
      "id_parroquia": 863,
      "id_municipio": 114,
      "parroquia": "PQ. JACURA"
    },
    {
      "id_parroquia": 864,
      "id_municipio": 99,
      "parroquia": "PQ. BARAIVED"
    },
    {
      "id_parroquia": 865,
      "id_municipio": 45,
      "parroquia": "PQ. LA SOLEDAD"
    },
    {
      "id_parroquia": 866,
      "id_municipio": 113,
      "parroquia": "PQ. LAS VEGAS DEL TUY"
    },
    {
      "id_parroquia": 867,
      "id_municipio": 113,
      "parroquia": "PQ. EL CHARAL"
    },
    {
      "id_parroquia": 868,
      "id_municipio": 102,
      "parroquia": "PQ. BOROJO"
    },
    {
      "id_parroquia": 869,
      "id_municipio": 101,
      "parroquia": "PQ. AGUA LARGA"
    },
    {
      "id_parroquia": 870,
      "id_municipio": 8,
      "parroquia": "PQ. LA PE\u00d1A"
    },
    {
      "id_parroquia": 871,
      "id_municipio": 42,
      "parroquia": "PQ. PECAYA"
    },
    {
      "id_parroquia": 872,
      "id_municipio": 45,
      "parroquia": "PQ. PUEBLO CUMAREBO"
    },
    {
      "id_parroquia": 873,
      "id_municipio": 98,
      "parroquia": "PQ. GUAIBACOA"
    },
    {
      "id_parroquia": 874,
      "id_municipio": 98,
      "parroquia": "PQ. ACURIGUA"
    },
    {
      "id_parroquia": 875,
      "id_municipio": 102,
      "parroquia": "PQ. SEQUE"
    },
    {
      "id_parroquia": 876,
      "id_municipio": 114,
      "parroquia": "PQ. AGUA LINDA"
    },
    {
      "id_parroquia": 877,
      "id_municipio": 12,
      "parroquia": "PQ. SAN JOSE DE LA COSTA"
    },
    {
      "id_parroquia": 878,
      "id_municipio": 110,
      "parroquia": "PQ. PURURECHE"
    },
    {
      "id_parroquia": 879,
      "id_municipio": 110,
      "parroquia": "PQ. PIEDRA GRANDE"
    },
    {
      "id_parroquia": 880,
      "id_municipio": 110,
      "parroquia": "PQ. AVARIA"
    },
    {
      "id_parroquia": 881,
      "id_municipio": 110,
      "parroquia": "PQ. AGUA CLARA"
    },
    {
      "id_parroquia": 882,
      "id_municipio": 119,
      "parroquia": "PQ. PARAPARA"
    },
    {
      "id_parroquia": 883,
      "id_municipio": 15,
      "parroquia": "PQ. EL CALVARIO"
    },
    {
      "id_parroquia": 884,
      "id_municipio": 24,
      "parroquia": "PQ. SOUBLETTE"
    },
    {
      "id_parroquia": 885,
      "id_municipio": 123,
      "parroquia": "PQ. SOSA"
    },
    {
      "id_parroquia": 886,
      "id_municipio": 124,
      "parroquia": "PQ. S LORENZO DE TIZNADOS"
    },
    {
      "id_parroquia": 887,
      "id_municipio": 24,
      "parroquia": "PQ. LIBERTAD DE ORITUCO"
    },
    {
      "id_parroquia": 888,
      "id_municipio": 129,
      "parroquia": "PQ. CARUAO"
    },
    {
      "id_parroquia": 889,
      "id_municipio": 131,
      "parroquia": "PQ. MANUEL MORILLO"
    },
    {
      "id_parroquia": 890,
      "id_municipio": 135,
      "parroquia": "PQ. ANZOATEGUI"
    },
    {
      "id_parroquia": 891,
      "id_municipio": 135,
      "parroquia": "PQ. MORAN"
    },
    {
      "id_parroquia": 892,
      "id_municipio": 131,
      "parroquia": "PQ. TORRES"
    },
    {
      "id_parroquia": 893,
      "id_municipio": 131,
      "parroquia": "PQ. REYES VARGAS"
    },
    {
      "id_parroquia": 894,
      "id_municipio": 131,
      "parroquia": "PQ. LARA"
    },
    {
      "id_parroquia": 895,
      "id_municipio": 133,
      "parroquia": "PQ. PARAISO DE SAN JOSE"
    },
    {
      "id_parroquia": 896,
      "id_municipio": 137,
      "parroquia": "PQ. QBDA. HONDA DE GUACHE"
    },
    {
      "id_parroquia": 897,
      "id_municipio": 137,
      "parroquia": "PQ. YACAMBU"
    },
    {
      "id_parroquia": 898,
      "id_municipio": 135,
      "parroquia": "PQ. LA CANDELARIA"
    },
    {
      "id_parroquia": 899,
      "id_municipio": 131,
      "parroquia": "PQ. HERIBERTO ARROYO"
    },
    {
      "id_parroquia": 900,
      "id_municipio": 153,
      "parroquia": "PQ. SAN CRISTOBAL DE T"
    },
    {
      "id_parroquia": 901,
      "id_municipio": 154,
      "parroquia": "PQ. MUCUCHACHI"
    },
    {
      "id_parroquia": 902,
      "id_municipio": 152,
      "parroquia": "PQ. PALMIRA"
    },
    {
      "id_parroquia": 903,
      "id_municipio": 42,
      "parroquia": "PQ. PUEBLO NUEVO DEL SUR"
    },
    {
      "id_parroquia": 904,
      "id_municipio": 149,
      "parroquia": "PQ. MESA DE LAS PALMAS"
    },
    {
      "id_parroquia": 905,
      "id_municipio": 147,
      "parroquia": "PQ. CACUTE"
    },
    {
      "id_parroquia": 906,
      "id_municipio": 140,
      "parroquia": "PQ. JAJI"
    },
    {
      "id_parroquia": 907,
      "id_municipio": 42,
      "parroquia": "PQ. ESTANQUES"
    },
    {
      "id_parroquia": 908,
      "id_municipio": 15,
      "parroquia": "PQ. LA VENTA"
    },
    {
      "id_parroquia": 909,
      "id_municipio": 154,
      "parroquia": "PQ. MUCUTUY"
    },
    {
      "id_parroquia": 910,
      "id_municipio": 156,
      "parroquia": "PQ. MESA DE QUINTERO"
    },
    {
      "id_parroquia": 911,
      "id_municipio": 154,
      "parroquia": "PQ. CHACANTA"
    },
    {
      "id_parroquia": 912,
      "id_municipio": 41,
      "parroquia": "PQ. LOS NEVADOS"
    },
    {
      "id_parroquia": 913,
      "id_municipio": 15,
      "parroquia": "PQ. PI\u00d1ANGO"
    },
    {
      "id_parroquia": 914,
      "id_municipio": 154,
      "parroquia": "PQ. CAPURI"
    },
    {
      "id_parroquia": 915,
      "id_municipio": 42,
      "parroquia": "PQ. LA TRAMPA"
    },
    {
      "id_parroquia": 916,
      "id_municipio": 140,
      "parroquia": "PQ. ACEQUIAS"
    },
    {
      "id_parroquia": 917,
      "id_municipio": 154,
      "parroquia": "PQ. GUAIMARAL"
    },
    {
      "id_parroquia": 918,
      "id_municipio": 169,
      "parroquia": "PQ. AREVALO GONZALEZ"
    },
    {
      "id_parroquia": 919,
      "id_municipio": 31,
      "parroquia": "PQ. PAPARO"
    },
    {
      "id_parroquia": 920,
      "id_municipio": 171,
      "parroquia": "PQ. MACHURUCUTO"
    },
    {
      "id_parroquia": 921,
      "id_municipio": 146,
      "parroquia": "PQ. CUMBO"
    },
    {
      "id_parroquia": 922,
      "id_municipio": 169,
      "parroquia": "PQ. RIBAS"
    },
    {
      "id_parroquia": 923,
      "id_municipio": 162,
      "parroquia": "PQ. LA DEMOCRACIA"
    },
    {
      "id_parroquia": 924,
      "id_municipio": 71,
      "parroquia": "PQ. AREO"
    },
    {
      "id_parroquia": 925,
      "id_municipio": 174,
      "parroquia": "PQ. LA GUANOTA"
    },
    {
      "id_parroquia": 926,
      "id_municipio": 68,
      "parroquia": "PQ. APARICIO"
    },
    {
      "id_parroquia": 927,
      "id_municipio": 68,
      "parroquia": "PQ. GUANAGUANA"
    },
    {
      "id_parroquia": 928,
      "id_municipio": 41,
      "parroquia": "PQ. LAS ALHUACAS"
    },
    {
      "id_parroquia": 929,
      "id_municipio": 41,
      "parroquia": "PQ. TABASCA"
    },
    {
      "id_parroquia": 930,
      "id_municipio": 187,
      "parroquia": "PQ. VICENTE FUENTES"
    },
    {
      "id_parroquia": 931,
      "id_municipio": 189,
      "parroquia": "PQ. SAN ISIDRO LABRADOR"
    },
    {
      "id_parroquia": 932,
      "id_municipio": 42,
      "parroquia": "PQ. VILLA ROSA"
    },
    {
      "id_parroquia": 933,
      "id_municipio": 195,
      "parroquia": "PQ. PE\u00d1A BLANCA"
    },
    {
      "id_parroquia": 934,
      "id_municipio": 197,
      "parroquia": "PQ. SANTA FE"
    },
    {
      "id_parroquia": 935,
      "id_municipio": 192,
      "parroquia": "PQ. UVERAL"
    },
    {
      "id_parroquia": 936,
      "id_municipio": 42,
      "parroquia": "PQ. SAN RAFAEL PALO ALZADO"
    },
    {
      "id_parroquia": 937,
      "id_municipio": 193,
      "parroquia": "PQ. DIVINA PASTORA"
    },
    {
      "id_parroquia": 938,
      "id_municipio": 42,
      "parroquia": "PQ. SAN JOSE DE SAGUAZ"
    },
    {
      "id_parroquia": 939,
      "id_municipio": 190,
      "parroquia": "PQ. CORDOBA"
    },
    {
      "id_parroquia": 940,
      "id_municipio": 193,
      "parroquia": "PQ. TRINIDAD DE LA CAPILLA"
    },
    {
      "id_parroquia": 941,
      "id_municipio": 190,
      "parroquia": "PQ. SAN JOSE DE LA MONTA\u00d1A"
    },
    {
      "id_parroquia": 942,
      "id_municipio": 203,
      "parroquia": "PQ. BIDEAU"
    },
    {
      "id_parroquia": 943,
      "id_municipio": 63,
      "parroquia": "PQ. PUERTO SANTO"
    },
    {
      "id_parroquia": 944,
      "id_municipio": 274,
      "parroquia": "PQ. SAN JOSE DE AEROCUAR"
    },
    {
      "id_parroquia": 945,
      "id_municipio": 179,
      "parroquia": "PQ. SAN ANTONIO DE IRAPA"
    },
    {
      "id_parroquia": 946,
      "id_municipio": 204,
      "parroquia": "PQ. SANTA MARIA"
    },
    {
      "id_parroquia": 947,
      "id_municipio": 274,
      "parroquia": "PQ. TAVERA ACOSTA"
    },
    {
      "id_parroquia": 948,
      "id_municipio": 179,
      "parroquia": "PQ. MARABAL"
    },
    {
      "id_parroquia": 949,
      "id_municipio": 179,
      "parroquia": "PQ. SORO"
    },
    {
      "id_parroquia": 950,
      "id_municipio": 203,
      "parroquia": "PQ. PUNTA DE PIEDRAS"
    },
    {
      "id_parroquia": 951,
      "id_municipio": 203,
      "parroquia": "PQ. CRISTOBAL COLON"
    },
    {
      "id_parroquia": 952,
      "id_municipio": 206,
      "parroquia": "PQ. TUNAPUICITO"
    },
    {
      "id_parroquia": 953,
      "id_municipio": 41,
      "parroquia": "PQ. SAN JOAQUIN DE NAVAY"
    },
    {
      "id_parroquia": 954,
      "id_municipio": 223,
      "parroquia": "PQ. CONSTITUCION"
    },
    {
      "id_parroquia": 955,
      "id_municipio": 41,
      "parroquia": "PQ. EMETERIO OCHOA"
    },
    {
      "id_parroquia": 956,
      "id_municipio": 218,
      "parroquia": "PQ. MONS. MIGUEL A SALAS"
    },
    {
      "id_parroquia": 957,
      "id_municipio": 8,
      "parroquia": "PQ. JUAN VICENTE GOMEZ"
    },
    {
      "id_parroquia": 958,
      "id_municipio": 211,
      "parroquia": "PQ. QUINIMARI"
    },
    {
      "id_parroquia": 959,
      "id_municipio": 211,
      "parroquia": "PQ. LA PETROLEA"
    },
    {
      "id_parroquia": 960,
      "id_municipio": 216,
      "parroquia": "PQ. SAN PEDRO DEL RIO"
    },
    {
      "id_parroquia": 961,
      "id_municipio": 8,
      "parroquia": "PQ. ISAIAS MEDINA ANGARIT"
    },
    {
      "id_parroquia": 962,
      "id_municipio": 227,
      "parroquia": "PQ. JUAN PABLO PE\u00d1ALOZA"
    },
    {
      "id_parroquia": 963,
      "id_municipio": 222,
      "parroquia": "PQ. CIPRIANO CASTRO"
    },
    {
      "id_parroquia": 964,
      "id_municipio": 227,
      "parroquia": "PQ. CARDENAS"
    },
    {
      "id_parroquia": 965,
      "id_municipio": 208,
      "parroquia": "PQ. DR. FCO. ROMERO LOBO"
    },
    {
      "id_parroquia": 966,
      "id_municipio": 218,
      "parroquia": "PQ. EMILIO C. GUERRERO"
    },
    {
      "id_parroquia": 967,
      "id_municipio": 207,
      "parroquia": "PQ. LA FLORIDA"
    },
    {
      "id_parroquia": 968,
      "id_municipio": 227,
      "parroquia": "PQ. POTOSI"
    },
    {
      "id_parroquia": 969,
      "id_municipio": 42,
      "parroquia": "PQ. ELEAZAR LOPEZ CONTRERA"
    },
    {
      "id_parroquia": 970,
      "id_municipio": 237,
      "parroquia": "PQ. ARNOLDO GABALDON"
    },
    {
      "id_parroquia": 971,
      "id_municipio": 232,
      "parroquia": "PQ. CRUZ CARRILLO"
    },
    {
      "id_parroquia": 972,
      "id_municipio": 146,
      "parroquia": "PQ. ARAGUANEY"
    },
    {
      "id_parroquia": 973,
      "id_municipio": 8,
      "parroquia": "PQ. CHEREGUE"
    },
    {
      "id_parroquia": 974,
      "id_municipio": 146,
      "parroquia": "PQ. LA ESPERANZA"
    },
    {
      "id_parroquia": 975,
      "id_municipio": 51,
      "parroquia": "PQ. CABIMBU"
    },
    {
      "id_parroquia": 976,
      "id_municipio": 241,
      "parroquia": "PQ. EL BA\u00d1O"
    },
    {
      "id_parroquia": 977,
      "id_municipio": 237,
      "parroquia": "PQ. CARRILLO"
    },
    {
      "id_parroquia": 978,
      "id_municipio": 15,
      "parroquia": "PQ. AGUA CALIENTE"
    },
    {
      "id_parroquia": 979,
      "id_municipio": 232,
      "parroquia": "PQ. ANDRES LINARES"
    },
    {
      "id_parroquia": 980,
      "id_municipio": 234,
      "parroquia": "PQ. RAFAEL RANGEL"
    },
    {
      "id_parroquia": 981,
      "id_municipio": 245,
      "parroquia": "PQ. CUICAS"
    },
    {
      "id_parroquia": 982,
      "id_municipio": 234,
      "parroquia": "PQ. GENERAL RIVAS"
    },
    {
      "id_parroquia": 983,
      "id_municipio": 245,
      "parroquia": "PQ. PANAMERICANA"
    },
    {
      "id_parroquia": 984,
      "id_municipio": 237,
      "parroquia": "PQ. CEGARRA"
    },
    {
      "id_parroquia": 985,
      "id_municipio": 242,
      "parroquia": "PQ. LA CEIBA"
    },
    {
      "id_parroquia": 986,
      "id_municipio": 234,
      "parroquia": "PQ. LA VEGA DE GUARAMACAL"
    },
    {
      "id_parroquia": 987,
      "id_municipio": 15,
      "parroquia": "PQ. VALERITA"
    },
    {
      "id_parroquia": 988,
      "id_municipio": 240,
      "parroquia": "PQ. STA MARIA DEL HORCON"
    },
    {
      "id_parroquia": 989,
      "id_municipio": 237,
      "parroquia": "PQ. BOLIVIA"
    },
    {
      "id_parroquia": 990,
      "id_municipio": 244,
      "parroquia": "PQ. LOS CAPRICHOS"
    },
    {
      "id_parroquia": 991,
      "id_municipio": 234,
      "parroquia": "PQ. GUARAMACAL"
    },
    {
      "id_parroquia": 992,
      "id_municipio": 247,
      "parroquia": "PQ. TEMERLA"
    },
    {
      "id_parroquia": 993,
      "id_municipio": 258,
      "parroquia": "PQ. ELEAZAR LOPEZ C"
    },
    {
      "id_parroquia": 994,
      "id_municipio": 269,
      "parroquia": "PQ. MARCELINO BRICE\u00d1O"
    },
    {
      "id_parroquia": 995,
      "id_municipio": 42,
      "parroquia": "PQ. M.ARTURO CELESTINO A"
    },
    {
      "id_parroquia": 996,
      "id_municipio": 42,
      "parroquia": "PQ. GIBRALTAR"
    },
    {
      "id_parroquia": 997,
      "id_municipio": 265,
      "parroquia": "PQ. ARISTIDES CALVANI"
    },
    {
      "id_parroquia": 998,
      "id_municipio": 264,
      "parroquia": "PQ. AGUSTIN CODAZZI"
    }
  ]
export default parroquias;