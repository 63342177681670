import Features from "components/about/Features";
import FeaturesLine from "components/about/FeaturesLine";
import Header from "components/about/Header";

import Footer from "components/navigation/Footer";
import Meta from "components/navigation/Meta";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";

function About() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Meta title="Nosotros" />

			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<Header />

					<Features />
					<FeaturesLine />
				</div>
			</div>
			<Footer />
		</Layout>
	);
}
export default About;
