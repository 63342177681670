import { Link, useNavigate } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Toast from "../../components/Alert";
import axios from "axios";
import moment from "moment";
import registerimg from "../../assets/img/author.png";
import titleimg from "../../assets/img/title.png";




import PLAN_CHOICES from "../../components/choice.js";
import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";
import estados from "components/territorio/estado";
import municipios from "components/territorio/municipios";
import parroquias from "components/territorio/parroquias";
import Meta from "components/navigation/Meta";

function Register() {
	const navigate = useNavigate();
	const [nuip, setNuip] = useState("");
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [showRegistrationForm, setShowRegistrationForm] = useState(false);


	const [stateSelected, setStateSelected] = useState("");
	const [municipalitySelected, setMunicipalitySelected] = useState("");
	const [parishSelected, setParishSelected] = useState("");
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [parishOptions, setParishOptions] = useState([]);


	const [formData, setFormData] = useState({
		full_name: "",
		nuip: "",
		birth_date: "",
		phone: "",
		email: "",
		state: "",
		municipality: "",
		parishes: "",
		voting_center: "",
		plans: "",
		gender: "",
		address: "",
	});

	const {
		full_name,
		phone,
		birth_date,
		email,
		state,
		municipality,
		parishes,
		voting_center,
		plans,
		gender,
		address,
	} = formData;

	const fetchUserData = async () => {
		if (!nuip) {
			Toast({
				tipo: "error",
				mensaje: "Por favor, ingrese un número de cédula",
			});
			return;
		}
		setLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const newnuip = new FormData();
		newnuip.append("nuip", nuip);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL_2}/buscar-cne/`,
				newnuip,
				config
			);
			if (res.status === 200) {
				setUserData(res.data.data);
				setFormData({
					...formData,
					full_name: res.data.data.nombre,
					nuip: res.data.data.nuip,
					state: res.data.data.estado,
					municipality: res.data.data.municipio,
					parishes: res.data.data.parroquia,
					voting_center: res.data.data.centro,
					address: res.data.data.direccion,
				});
				Toast({ tipo: "success", mensaje: "Datos obtenidos correctamente" });
				setLoading(false);
			}
		} catch (error) {
			if (error.response && error.response.status === 404) {
				setShowRegistrationForm(true);
				Toast({
					tipo: "info",
					mensaje:
						"No se encontraron datos. en el CNE Por favor, complete el formulario de registro.",
				});
			} else if (error.response && error.response.status === 400) {
				const errorMessage = error.response.data.error;
				if (errorMessage === "Ya existe un registro con esta cédula") {
					Toast({
						tipo: "error",
						mensaje: errorMessage,
					});
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al buscar el usuario en el programa pacificador",
					});
				}
			} else {
				console.log(error.response);
				Toast({
					tipo: "error",
					mensaje:
						error.response?.data?.message ||
						"Error al buscar el usuario en el programa pacificador",
				});
			}
			setLoading(false);
		}
	};

	const handleNuipChange = (e) => {
		setNuip(e.target.value);
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (nuip) {
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const formData = new FormData();
			formData.append("full_name", full_name);
			formData.append("nuip", nuip);
			let formattedBirthDate = moment(birth_date).format("YYYY-MM-DD");
			formData.append("birth_date", formattedBirthDate);
			formData.append("phone", phone);
			formData.append("email", email);
			formData.append("state", stateSelected ? stateSelected : state);
			formData.append("municipality", municipalitySelected ? municipalitySelected : municipality);
			formData.append("parish", parishSelected ? parishSelected : parishes);
			formData.append("voting_center", voting_center);
			formData.append("plans", selectedPlan);
			formData.append("gender", gender);
			formData.append("address", address);

			console.log(formData);
			const fetchData = async () => {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_API_URL_2}/peacemaker/cne/`,
						formData,
						config
					);

					if (res.status === 201) {
						setLoading(false);
						setFormData({
							full_name: "",
							nuip: "",
							birth_date: "",
							phone: "",
							email: "",
							gender: "",
							state: "",
							municipality: "",
							parishes: "",
							voting_center: "",
							plans: "",
							address: "",
						});

						Toast({
							tipo: "success",
							mensaje:
								"¡Te has Registrdo Sastifactoriamente en el Programa Pacificador!",
						});
						navigate("/");
					}
				} catch (error) {
					console.log(error.response);
					if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.nuip.includes(
							"Ya existe Pacificador con este nuip."
						)
					) {
						Toast({
							tipo: "error",
							mensaje:
								"Ya esta registrado en el programa pacificador con ese numero de cedula.",
						});
					} else {
						alert("Error al registrar los datos en el programa pacificador");
					}
					setLoading(false);
				}
			};
			fetchData();
		} else {
			alert("Debes Seleccionar El Producto");
		}
	};


	useEffect(() => {
    if (stateSelected) {
        // Buscar el ID del estado seleccionado para filtrar municipios
        const estado = estados.find(e => e.estado === stateSelected);
        if (estado) {
            const filteredMunicipios = municipios.filter(m => m.id_estado === estado.id_estado);
            setMunicipalityOptions(filteredMunicipios);
        } else {
            setMunicipalityOptions([]);
        }
    } else {
        setMunicipalityOptions([]);
    }
    setMunicipalitySelected("");
    setParishSelected("");
}, [stateSelected, estados, municipios]);


useEffect(() => {
	if (municipalitySelected) {
			// Buscar el ID del municipio seleccionado para filtrar parroquias
			const municipio = municipios.find(m => m.municipio === municipalitySelected);
			if (municipio) {
					const filteredParroquias = parroquias.filter(p => p.id_municipio === municipio.id_municipio);
					setParishOptions(filteredParroquias);
			} else {
					setParishOptions([]);
			}
	} else {
			setParishOptions([]);
	}
	setParishSelected("");
}, [municipalitySelected, municipios, parroquias]);


	return (
		<Layout>
			<Meta title="Registro Pacificador"/>
			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="overflow-y-scroll grid-cols-1 lg:grid-cols-2">
					{userData || showRegistrationForm ? (
						<>
							<div className="body pb-4 mt-16">
								<div className="container mx-auto">
									<div className="grid grid-cols-1 lg:grid-cols-2">
										<div className="hidden lg:flex pt-12 flex-col items-center">
											<img src={registerimg} className="" alt="Register img" />
											<img src={titleimg} className="h-32 md:h-56" alt="Logo" />
										</div>
										<div>
											<div className="card bg-white p-6 rounded-xl m-6 md:max-w-lg mt-12">
												<form
													onSubmit={(e) => onSubmit(e)}
													className="flex flex-col gap-y-6 mt-5 gap-5 m-auto justify-center"
												>
													<div>
														<label
															htmlFor="full_name"
															className="block text-sm font-medium text-gray-700"
														>
															Nombres y Apellidos
														</label>
														<input
															type="text"
															name="full_name"
															value={full_name}
															onChange={(e) => onChange(e)}
															required
															disabled={userData ? true : false}
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md border-gray-300 py-2 px-3  placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
															placeholder="Nombres y Apellidos"
														/>
													</div>

													<div>
														<label
															htmlFor="nuip"
															className="block text-sm font-medium text-gray-700"
														>
															Cedula de Identidad
														</label>
														<input
															type="text"
															name="nuip"
															value={nuip}
															onChange={(e) => onChange(e)}
															required
															disabled={userData ? true : false}
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md border-gray-300 py-2 px-3  placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
															placeholder="Cedula de Identidad"
														/>
													</div>

													<div>
														<label
															htmlFor="birth_date"
															className="block text-sm font-medium text-gray-700"
														>
															Fecha de Nacimiento
														</label>
														<input
															type="date"
															name="birth_date"
															value={birth_date}
															onChange={(e) => onChange(e)}
															required
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md border-gray-300 py-2 px-3  placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
															placeholder="Fecha de Nacimiento"
														/>
													</div>

													<div>
														<label
															htmlFor="gender"
															className="block mb-2 text-sm font-medium text-gray-700"
														>
															Genero
														</label>
														<div className="flex gap-4">
															<div className="flex items-center gap-2">
																<input
																	type="radio"
																	id="genderMale"
																	name="gender"
																	value="Masculino"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			gender: e.target.value,
																		})
																	} // <-- añade esto
																	className="radio"
																/>
																<label htmlFor="genderMale">Masculino</label>
															</div>
															<div className="flex items-center gap-2">
																<input
																	type="radio"
																	id="genderFemale"
																	name="gender"
																	value="Femenino"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			gender: e.target.value,
																		})
																	} // <-- añade esto
																	className="radio"
																/>
																<label htmlFor="genderFemale">Femenino</label>
															</div>
														</div>
													</div>

													<div>
														<label
															htmlFor="phone"
															className="block text-sm font-medium text-gray-700"
														>
															Numero de Telefono
														</label>
														<input
															type="text"
															name="phone"
															value={phone}
															onChange={(e) => onChange(e)}
															required
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3  placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
															placeholder="Numero de Telefono"
														/>
													</div>

													<div>
														<label
															htmlFor="email"
															className="block text-sm font-medium text-gray-700"
														>
															Correo Electronico
														</label>
														<input
															type="email"
															name="email"
															value={email}
															onChange={(e) => onChange(e)}
															required
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md 0 py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
															placeholder="Correo Electronico"
														/>
													</div>

													{userData ? (
														<>
															<div>
																<label
																	htmlFor="state"
																	class="block text-sm font-medium text-gray-700"
																>
																	Estado
																</label>
																<input
																	name="state"
																	value={state}
																	onChange={(e) => onChange(e)}
																	required
																	disabled={userData ? true : false}
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																/>
															</div>

															<div>
																<label
																	htmlFor="municipality"
																	class="block text-sm font-medium text-gray-700"
																>
																	Municipio
																</label>
																<input
																	name="municipality"
																	value={municipality}
																	onChange={(e) => onChange(e)}
																	required
																	disabled={userData ? true : false}
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																/>
															</div>

															<div>
																<label
																	htmlFor="parishes"
																	class="block text-sm font-medium text-gray-700"
																>
																	Parroquia
																</label>
																<input
																	name="parishes"
																	value={parishes}
																	onChange={(e) => onChange(e)}
																	required
																	disabled={userData ? true : false}
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																/>
															</div>
														</>
													) : (
														<>
															<div>
																<label
																	htmlFor="state"
																	className="block text-sm font-medium text-gray-700"
																>
																	Estado
																</label>
																<select
																	name="state"
																	value={stateSelected}
																	onChange={(e) =>
																		setStateSelected(e.target.value)
																	}
																	required
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																>
																	<option value="">Selecciona un estado</option>
																	{estados.map((estado) => (
																		<option
																			key={estado.id_estado}
																			value={estado.estado}
																		>
																			{estado.estado}
																		</option>
																	))}
																</select>
																{/* El resto de los select para municipio y parroquia van aquí */}
															</div>

															<div>
																<label
																	htmlFor="municipality"
																	className="block text-sm font-medium text-gray-700"
																>
																	Municipio
																</label>
																<select
																	name="municipality"
																	value={municipalitySelected}
																	onChange={(e) =>
																		setMunicipalitySelected(e.target.value)
																	}
																	required
																	disabled={userData ? true : false}
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																>
																	<option value="">
																		Selecciona un municipio
																	</option>
																	{municipalityOptions.map((municipio) => (
																		<option
																			key={municipio.id_municipio}
																			value={municipio.municipio}
																		>
																			{municipio.municipio}
																		</option>
																	))}
																</select>
															</div>

															<div>
																<label
																	htmlFor="parishes"
																	className="block text-sm font-medium text-gray-700"
																>
																	Parroquia
																</label>
																<select
																	name="parishes"
																	value={parishSelected}
																	onChange={(e) =>
																		setParishSelected(e.target.value)
																	}
																	required
																	disabled={userData ? true : false}
																	className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
																>
																	<option value="">
																		Selecciona una parroquia
																	</option>
																	{parishOptions.map((parroquia) => (
																		<option
																			key={parroquia.id_parroquia}
																			value={parroquia.parroquia}
																		>
																			{parroquia.parroquia}
																		</option>
																	))}
																</select>
															</div>
														</>
													)}

													{/* <div>
														<label
															htmlFor="voting_center"
															class="block text-sm font-medium text-gray-700"
														>
															Centro de Votacion
														</label>
														<input
															name="voting_center"
															value={voting_center}
															onChange={(e) => onChange(e)}
															required
															disabled={userData ? true : false}
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
														/>
													</div> */}

													<div>
														<label
															htmlFor="address"
															class="block text-sm font-medium text-gray-700"
														>
															Direccion
														</label>
														<input
															name="address"
															value={address}
															onChange={(e) => onChange(e)}
															required
															disabled={userData ? true : false}
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
														/>
													</div>

													<div>
														<label
															htmlFor="plans"
															class="block text-sm font-medium text-gray-700"
														>
															Programas
														</label>
														<select
															name="plans"
															value={selectedPlan}
															onChange={(e) => setSelectedPlan(e.target.value)}
															required
															className="block md:w-96 w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
														>
															<option value="">Selecciona un Programa</option>
															{PLAN_CHOICES.map((plan, index) => (
																<option key={index} value={plan[0]}>
																	{plan[1]}
																</option>
															))}
														</select>
													</div>
													<div>
														{loading ? (
															<div className="relative inline-flex items-center rounded-md border border-transparent bg-black px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
																<CircleLoader
																	loading={loading}
																	size={25}
																	color="#ffffff"
																/>
															</div>
														) : (
															<button
																type="submit"
																className="relative inline-flex items-center rounded-md border border-transparent bg-black px-8 py-3 text-lg font-medium text-white shadow-sm"
															>
																Enviar
															</button>
														)}
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="body pb-4">
								<div className="container mx-auto">
									<div className="grid grid-cols-1 lg:grid-cols-2">
										<div className="hidden lg:flex pt-12 flex-col items-center">
											<img src={registerimg} className="" alt="Register img" />
											<img src={titleimg} className="h-32 md:h-56" alt="Logo" />
										</div>
										<div className="m-auto align-middle">
											<div className="card bg-white p-6 rounded-xl m-6 md:max-w-lg mt-28">
												<div>
													<div className="card bg-white p-6">
														<form
															onSubmit={(e) => {
																if (userData) {
																	setLoading(true);
																	setUserData(null);
																	setNuip("");
																} else {
																	fetchUserData();
																}
															}}
															className="grid grid-cols-1 gap-y-6 mt-5 gap-5"
														>
															<div>
																<label
																	htmlFor="first_name"
																	className="block text-base font-medium text-gray-700"
																>
																	Cedula
																</label>
																<input
																	type="text"
																	name="nuip"
																	value={nuip}
																	onChange={handleNuipChange}
																	required
																	className={`block w-full border-b-2 border-b-blue-900 rounded-md py-2 px-3 placeholder-gray-500 shadow-sm  'border-gray-300 focus:border-red-500 focus:ring-red-500'}`}
																/>

																<div className="flex m-auto justify-end">
																	{loading ? (
																		<div className="relative inline-flex items-center rounded-md border border-transparent bg-black px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
																			<CircleLoader
																				loading={loading}
																				size={25}
																				color="#ffffff"
																			/>
																		</div>
																	) : (
																		<button
																			type="submit"
																			onClick={fetchUserData}
																			className="rounded-md border border-transparent bg-black px-8 py-3 text-lg font-medium text-white shadow-sm mt-3"
																		>
																			Enviar
																		</button>
																	)}
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				<Footer />
			</div>
		</Layout>
	);
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Register);
