const estados = [
    {
      "id_estado": 1,
      "estado": "DISTRITO CAPITAL"
    },
    {
      "id_estado": 2,
      "estado": "EDO. AMAZONAS"
    },
    {
      "id_estado": 3,
      "estado": "EDO. ANZOATEGUI"
    },
    {
      "id_estado": 4,
      "estado": "EDO. APURE"
    },
    {
      "id_estado": 5,
      "estado": "EDO. ARAGUA"
    },
    {
      "id_estado": 6,
      "estado": "EDO. BARINAS"
    },
    {
      "id_estado": 7,
      "estado": "EDO. BOLIVAR"
    },
    {
      "id_estado": 8,
      "estado": "EDO. CARABOBO"
    },
    {
      "id_estado": 9,
      "estado": "EDO. COJEDES"
    },
    {
      "id_estado": 10,
      "estado": "EDO. DELTA AMAC"
    },
    {
      "id_estado": 11,
      "estado": "EDO. FALCON"
    },
    {
      "id_estado": 12,
      "estado": "EDO. GUARICO"
    },
    {
      "id_estado": 13,
      "estado": "EDO. LA GUAIRA"
    },
    {
      "id_estado": 14,
      "estado": "EDO. LARA"
    },
    {
      "id_estado": 15,
      "estado": "EDO. MERIDA"
    },
    {
      "id_estado": 16,
      "estado": "EDO. MIRANDA"
    },
    {
      "id_estado": 17,
      "estado": "EDO. MONAGAS"
    },
    {
      "id_estado": 18,
      "estado": "EDO. NVA. ESPARTA"
    },
    {
      "id_estado": 19,
      "estado": "EDO. PORTUGUESA"
    },
    {
      "id_estado": 20,
      "estado": "EDO. SUCRE"
    },
    {
      "id_estado": 21,
      "estado": "EDO. TACHIRA"
    },
    {
      "id_estado": 22,
      "estado": "EDO. TRUJILLO"
    },
    {
      "id_estado": 23,
      "estado": "EDO. YARACUY"
    },
    {
      "id_estado": 24,
      "estado": "EDO. ZULIA"
    }
  
  ]

export default estados;