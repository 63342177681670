import { Typewriter } from "react-simple-typewriter";


function Header({ title, mission,hero_bg }) {
	return (
			<main>
			<div
				className="relative px-4 sm:px-6 lg:px-8 bg-hero-pattern bg-contain bg-no-repeat bg-center"
				id="seccion-1"
			>
				<div className="mx-auto max-w-7xl xl:pt-10 lg:pt-10 lg:pb-32 pb-10">
					<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
						<div className="lg:w-1/2 xl:w-3/5 mt-10 lg:mt-0 text-center lg:text-left">
							<h1 className="text-3xl text-gray-200 font-semibold tracking-tight pb-3 sm:text-4xl lg:text-5xl">
								{title}
							</h1>
							<div className="text-xl font-semibold tracking-tight pb-4 text-gray-200 sm:text-2xl lg:text-3xl">
								2024 +
								<Typewriter
									words={[" PAZ.", " VIDA.", " BARRIO.", " DEPORTE."]}
									loop={0}
									cursor
									cursorStyle="_"
									typeSpeed={120}
									deleteSpeed={50}
									delaySpeed={1000}
								/>
							</div>
							<p className="my-6 text-lg sm:text-xl lg:text-2xl max-w-3xl leading-8 text-gray-200">
								{mission}
							</p>
						</div>
						<div className="flex justify-center lg:w-1/2 xl:w-2/5">
							<img
								src={hero_bg}
								alt="hero background"
								className="w-full max-w-xs lg:max-w-md"
							/>
						</div>
					</div>
				</div>
			</div>
		</main>

	);
}

export default Header;
