import { CheckIcon } from '@heroicons/react/24/outline'

const features = [
  { description: 'Impulsa, alimenta y promueve la Paz y la Vida.' },
  { description: 'Incita, la unidad, el respeto y la igualdad social.' },
  { description: 'Acepta la crítica y autocrítica combatiendo las amenazas que enfrenta la Revolución Bolivariana.' },
  { description: 'Diseña estrategias para el desarrollo integral de la Paz y la Vida,con visión holística pacificadora, que cuidan los intereses de los Grandes Objetivos Históricos del Plan de la Patria.' },
  { description: 'Asume, reconoce y respeta las banderas de lucha levantadas por los excluidos y vulnerables del mundo, como una sola.' },
  { description: 'Georreferencia su territorio.' },
  { description: 'Protege con celo la inversión social del Movimiento y la revolución.' },
  { description: 'Practica la integridad, cooperación y la integración del trabajo visionando la eficacia y eficiencia en los resultados.' },
  { description: 'Se autodisciplina en el autoestudio que le permite la resolución de conflictos, en cualquier espacio que se encuentre.' },
  { description: 'Es leal a la Revolución y a todo el pensamiento libertario de los próceres venezolanos y no discute la figura y postura del líder de la Revolución Bolivariana.' },
]

export default function Features() {
  return (
    <div className="bg-gray-900" id='seccion-4'>
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-yellow-button">El MILITANTE DE PAZ Y VIDA</h2>
          <p className="mt-4 text-lg text-gray-200">
          El militante del Movimiento por la Paz y la Vida, es un guía para la acción pacificadora del territorio, un instrumento unificador de la comunidad donde vive y desarrolla su trabajo; planifica, organiza, forma y moviliza con el objetivo de defender las conquistas del pueblo en el campo de la Paz y la Vida. Asume compromiso contra la injusticia y la exclusión. Transforma realidades territoriales, agranda sus fronteras contribuyendo al equilibrio que debe existir entre hombre naturaleza, además:
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-5 sm:gap-x-6 sm:gap-y-2 sm:space-y-0 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.description} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <dd className="mt-2 ml-9 text-base text-gray-200">{feature.description}</dd>
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
