
import Header from "components/directive/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import CTA from "components/home/CTA";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Features from "components/directive/Features";
import hero_bg from "../../../assets/img/item-1.png";

function Jovenes() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const features = [
		{
			description: 'Sensibilizar y concienciar a otros jóvenes y a la sociedad en general sobre la situación de los jóvenes en vulnerabilidad y sus causas estructurales, sociales y culturales.'
		},
		{
			description: 'Ofrecer espacios de encuentro, diálogo, reflexión y acción colectiva entre jóvenes de diferentes orígenes, culturas, creencias, orientaciones e identidades, para promover la diversidad, el respeto y la solidaridad.'
		},
		{
			description: 'Formarse y formar a otros jóvenes en temas de ciudadanía, derechos humanos, democracia, participación, medio ambiente, salud, educación, empleo y desarrollo sostenible, utilizando metodologías participativas, lúdicas y educativas.'
		},
		{
			description: 'Apoyar y acompañar a los jóvenes en vulnerabilidad, brindándoles información, orientación, asistencia y protección, facilitándoles el acceso a los servicios y recursos que necesiten.'
		},
		{
			description: 'Empoderar y potenciar las capacidades, habilidades y liderazgos de los jóvenes en vulnerabilidad, para que puedan tomar decisiones sobre sus propias vidas y proyectos.'
		},
		{
			description: 'Fomentar la autonomía económica y social de los jóvenes en vulnerabilidad, impulsando su acceso a la educación, la formación, el empleo, la salud, la vivienda y otros recursos y oportunidades que mejoren su calidad de vida.'
		},
		{
			description: 'Estimular la expresión artística, cultural, deportiva y recreativa de los jóvenes en vulnerabilidad, como medio de liberación, comunicación, autoestima y bienestar, y como forma de visibilizar y valorar sus aportes a la sociedad.'
		},
		{
			description: 'Crear y difundir contenidos, mensajes y campañas de comunicación para la defensa y la promoción de los derechos de los jóvenes en vulnerabilidad, utilizando diversos medios y herramientas, especialmente las redes sociales y las tecnologías de la información y la comunicación.'
		},
		{
			description: 'Realizar acciones de incidencia política, social y legal, para exigir el cumplimiento y la ampliación de los derechos de los jóvenes en vulnerabilidad, y para influir en la elaboración, implementación y evaluación de las políticas públicas de juventud.'
		},
		{
			description: 'Participar activamente en los procesos electorales, tanto como candidatos como como electores, para aumentar la representación y el liderazgo de los jóvenes en vulnerabilidad en los espacios de toma de decisiones.'
		}
	];
	const mision = "Juventud por la Paz y la Vida es impulsar el cambio social, la inclusión y el desarrollo mediante la participación activa, crítica y solidaria de los jóvenes en la defensa de sus derechos, la mejora de sus condiciones de vida y la transformación de su entorno. Nos enfocamos especialmente en aquellos jóvenes que se encuentran en situación de vulnerabilidad, debido a razones como la pobreza, discriminación, violencia o exclusión."
	return (
		<Layout>
			<Helmet>
				<title>+Paz+Vida+ | Jovenes</title>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://mpazyvida.org/" />
				<meta name="author" content="Fundacion por la paz y la vida" />
				<meta name="publisher" content="Fundacion por la paz y la vida" />

				{/* Social Media Tags */}
				<meta
					property="og:title"
					content="M. Paz y Vida"
				/>
				<meta
					property="og:description"
					content="M. Paz y Vida"
				/>
				<meta property="og:url" content="https://mpazyvida.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta
					name="twitter:title"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:description"
					content="M. Paz y Vida"
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<div className="bg-gray-900">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<Header hero_bg={hero_bg} title={"Juventud por la paz y la vida"} mission={mision}/>
				</div>
				<CTA />
				<Features title={"Juventud por la Paz y la Vida"} features={features}/>
			</div>
			<Footer />
		</Layout>
	);
}
export default Jovenes;
